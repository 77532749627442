// Home-page
body.cms-home {
    [data-content-type="html"] {
        margin: $spacer--extra-small + 1;
    }

    .contact-bar__container {
        margin: 0;
    }

    .page-wrapper {
        margin-bottom: 0;
    }

    .mp-banner-slider {
        margin-top: 1.5rem;
    }

    .featured-cat-box h3,
    .featured-cat-box1 h3 {
        text-decoration: none;
        text-align: center;
        margin: 0;
    }

    .featured-cat-box h3 a,
    .featured-cat-box1 h3 a {
        font-size: $font-size-medium;
        color: $gray-darker;
        font-weight: $font-weight-bold;
    }

    .featured-cat-box p {
        font-size: $font-size-base;
    }

    .featured-cat-box p a {
        text-decoration: none;
    }

    .featured-cat-box p a:hover {
        text-decoration: underline;
        color: $black;
    }

    .featured-cat-box p:last-child {
        margin-bottom: 0;
        padding: 0px 15px;
    }

    .featured-cat-box {
        padding-left: 0;
        text-align: center;
    }

    .feature-cat {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        gap: 1rem;
        margin-top: $spacer--large + 3;
        margin-bottom: $spacer--large + 3;

        @media all and (max-width: ($screen-m - 1)) {
            grid-template-columns: 1fr;
            margin-bottom: 3rem;
        }

        &.featured-categories-w-images {
            @media all and (max-width: ($screen-m - 1)) {
                gap: 3rem;
            }
        }
    }

    .feature-block-title {
        text-align: center;
    }

    .feature-block-title strong {
        font-weight: $font-weight-bolder;
        font-size: $font-size-extra-large;
        line-height: 28px;
        color: $dark-orange;
        font-style: normal;
    }

    .featured-cat-box1 {
        max-width: 350px;
        float: none;
        padding-top: $spacer--medium + 2;
        padding-right: $spacer--medium + 2;
        padding-bottom: $spacer--medium + 2;
        padding-left: $spacer--medium + 2;
        margin-right: $spacer + 2;
        margin-top: $spacer + 2;
        background-color: $extra-faint-blue;
        margin: auto;
        text-align: center;
    }

    .bottom-details {
        .contact-line {
            text-align: center;
            font-size: $font-size-super-extra-large;

            @include mq(
                $max-screen: (
                    $screen-m - 1,
                )
            ) {
                font-size: $font-size-super-extra-large - 2;
            }

            a {
                font-weight: bolder;
            }

            #cont-line {
                color: $dark-orange;
                margin-right: $spacer--extra-small + 1;
                text-decoration: underline;
            }

            #tel-no {
                text-transform: capitalize;
                font-size: $font-size-super-extra-large;

                @include mq(
                    $max-screen: (
                        $screen-m - 1,
                    )
                ) {
                    font-size: $font-size-super-extra-large - 2;
                }
            }
        }
    }

    .footer__newsletter {
        display: none;
    }

    .mp-product-slider-block {
        .mp-product-slider-title {
            font-size: $font-size-large;
            font-weight: $font-weight-bold;
            border-bottom: $border-width-base $border-style-base $gray-light;
        }

        .mp-ps-info {
            margin: 0;
        }
    }

    .mp-product-slider-content {
        margin-bottom: $spacer--extra-large;

        .product-items.widget-viewed-grid {
            list-style: none;
            padding: 0;

            .slider-product-item-info {
                .slider-product-item-photo {
                    display: block;

                    .ratio-container {
                        padding-bottom: 80% !important;

                        img.ratio-image {
                            width: auto;
                            max-height: 200px;

                            @media all and (max-width: $screen-s) {
                                max-height: 150px;
                            }
                        }
                    }
                }

                .slider-product-item-details {
                    a {
                        font-size: $font-size-medium;
                        color: $gray-darkest;
                        margin-bottom: $spacer + 2;

                        &:hover {
                            color: $gray-darkest;
                        }
                    }
                }
            }
        }
    }

    .ves-widget.brand-widget {
        .block-title {
            text-align: left;
            border-bottom: $border-width-base $border-style-base $gray-light;

            > strong {
                font-size: $font-size-large;
            }
        }
    }
}

//customquote
.cms-customquote {
    .cms-page__content {
        max-width: 100%;
    }

    .heading--page {
        display: none;
    }

    .quotehead {
        background: #f2f2f280;
        padding: 0px 10px 0;

        .info-text {
            color: $charcoal-black;
            font-size: $font-size-base;
            margin: 0 0 16px;
            padding: 0;

            a {
                text-decoration: underline;
                padding: 0 0 10px 0;
                font-size: $font-size-base - 1;
            }

            p {
                font-weight: $font-weight-bold;
                font-family: Arial, Helvetica, sans-serif;
                margin-bottom: 0;
                padding: 0 0px 10px 0px;
            }
        }
    }

    .qoutecontent {
        background: #f8f8f8;
        padding: 30px 20px 20px 20px;

        h3.large-h3 {
            font-weight: $font-weight-bold;
        }

        .drawing-list {
            ul {
                padding: 0;
                display: flex;
                gap: $spacer--semi-large;
                list-style: none;
                flex-wrap: wrap;

                .plan-text {
                    font-size: $font-size-small;
                    font-weight: $font-weight-bold;
                    text-decoration: underline;
                    color: $dark-blue;
                }
            }
        }

        hr.hr-line {
            border: $border-width-base $border-style-base $dark-orange;
            border-radius: 5px;
        }

        .quote-content {
            a {
                display: flex;
                flex-direction: column;
            }
        }

        .quote-box {
            .quote-list {
                ul {
                    list-style: square;
                }
            }
        }
    }

    @media all and (min-width: 768px), print {
        .amcform-page-titles .amcform-title {
            max-width: 150px;
        }

        .amcform-title-wrap {
            flex-grow: unset;
            min-width: 125px;
            max-width: initial;
        }
    }

    .frmb.ui-sortable {
        min-height: 300px !important;
    }

    .form-builder-wrap div.form-actions.btn-group {
        position: absolute;
        bottom: 0;
        left: 0;
        margin: 0;
        padding: 0;
    }

    div#frmb-0-cb-wrap {
        min-height: 300px !important;
        position: relative;
        padding-bottom: $spacer--semi-large;
    }

    .amform-form {
        max-width: 700px;
        margin: 0 auto 10px;
    }

    .amform-form-fieldset.fieldset {
        margin-bottom: $spacer + 2;
    }

    .amform-form input[type="number"] {
        max-width: 20%;
    }

    .amform-groupv2 {
        display: inline-block;
    }

    #loading_image_container {
        position: absolute;
        top: 50%;
        left: 50%;
        z-index: 9999;
    }

    #form_submit_loading {
        height: 100%;
        width: 100%;
        position: fixed;
        top: 0;
        left: 0;
        background: -moz-linear-gradient(
                rgba(11, 11, 11, 0.1),
                rgba(11, 11, 11, 0.6)
            )
            repeat-x rgba(11, 11, 11, 0.2);
        background: -webkit-gradient(
                linear,
                0% 0%,
                0% 100%,
                from(rgba(11, 11, 11, 0.1)),
                to(rgba(11, 11, 11, 0.6))
            )
            repeat-x rgba(11, 11, 11, 0.2);
        z-index: 100000;
    }

    .amform-form .amform-hide-formload,
    .amform-form .amform-hide-formload.action.primary {
        display: none;
    }

    .amform-versiontwo-label {
        margin-right: $spacer + 2;
        padding-right: $spacer--medium + 4;
        display: inline;
        word-break: break-all;
        color: $black;
    }

    .amform-popup {
        display: none;
        position: fixed;
        z-index: 99999;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.4);
    }

    .amform-show {
        display: inline-block;
        margin: $spacer + 2;
        vertical-align: middle;
    }

    .amform-content {
        position: relative;
        padding: $spacer--medium + 4;
        height: auto;
        max-height: 60%;
        border: 1px solid #888;
        border-radius: 2%;
        box-sizing: border-box;
        overflow-y: auto;
        background-color: #fefefe;
    }

    .amform-popup-close {
        position: absolute;
        right: 20px;
        top: 5px;
        display: inline-block;
        color: #aaa;
        float: right;
        font-size: $font-size-large + 2;
        font-weight: $font-weight-bold;
        cursor: pointer;
    }

    .amform-popup-close .amform-close {
        position: fixed;
    }

    @media (min-width: 768px) {
        .amform-content {
            width: 40%;
            top: 20%;
            margin: 0 auto;
        }
    }

    @media (min-width: 1024px) {
        .amform-content {
            width: 30%;
        }
    }

    @media only screen and (max-width: 767px) {
        .amform-content {
            width: 100%;
            top: 15%;
        }
    }

    .fb-date .ui-datepicker-trigger {
        margin-left: $spacer + 2;
    }

    .fb-date input {
        cursor: pointer;
    }

    .am-picker-year .ui-datepicker-calendar td a {
        padding: $spacer;
    }

    .form-builder-wrap {
        min-height: 600px;
    }

    .amcform-page-titles {
        display: -webkit-flex;
        display: -ms-flexbox;
        display: flex;
        justify-content: center;
        -webkit-flex-wrap: wrap;
        flex-wrap: wrap;
        padding: 0;
        list-style: none;
        overflow: hidden;
        margin-bottom: $spacer + 2;
    }

    .amcform-page-titles .amcform-title {
        display: inline-block;
        margin: 0 10px;
        text-align: center;
        color: #333;
    }

    .amcform-page-titles .amcform-step {
        margin: 0 auto 20px;
        width: 35px;
        height: 35px;
        background: #f78833;
        border-radius: 50%;
        line-height: 35px;
        color: $white;
        font-weight: $font-weight-semi-medium;
        font-size: $font-size-medium;
    }

    .amcform-page-titles .amcform-title:hover {
        text-decoration: none;
    }

    .amcform-title-wrap {
        position: relative;
        min-width: 125px;
        max-width: none;
        flex-grow: 1;
        font-size: $font-size-small;
        font-style: normal;
        text-align: center;
        overflow: hidden;
        letter-spacing: normal;
        margin-bottom: $spacer + 2;
        margin-block-end: $spacer + 2;
        margin-block-start: 0px;
        -webkit-transform-style: preserve-3d;
        -moz-transform-style: preserve-3d;
        -ms-transform-style: preserve-3d;
        transform-style: preserve-3d;
    }

    .amcform-title-wrap:last-child {
        margin: 0;
    }

    .amcform-title-wrap:before {
        content: "";
        display: block;
        height: 5px;
        width: 100%;
        background: #efeff0;
        top: 15px;
        position: absolute;
        z-index: -1;
        border-radius: 10px;
    }

    .amcform-title-wrap:first-child:before,
    .amcform-title-wrap:last-child:before {
        background: $white;
    }

    .amcform-title-wrap:first-child .amcform-step:before,
    .amcform-title-wrap:last-child .amcform-step:before {
        content: "";
        position: absolute;
        top: 15px;
        z-index: -1;
        display: block;
        height: 5px;
        width: 100%;
        background: #efeff0;
        border-radius: 10px;
    }

    .amcform-title-wrap:last-child .amcform-step:before {
        right: 50%;
    }

    .amcform-title-wrap.-disabled {
        pointer-events: none;
    }

    .amcform-title-wrap.-disabled .amcform-step {
        background: #f5a161;
    }

    .amcform-title-wrap.-disabled .amcform-label {
        opacity: 0.4;
    }

    .amcform-title-wrap.-done .amcform-step {
        background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA1MTEuOTk5IDUxMS45OTkiIHdpZHRoPSI1MTIiIGhlaWdodD0iNTEyIj48cGF0aCBkPSJNNTA2LjIzMSA3NS41MDhjLTcuNjg5LTcuNjktMjAuMTU4LTcuNjktMjcuODQ5IDBsLTMxOS4yMSAzMTkuMjExTDMzLjYxNyAyNjkuMTYzYy03LjY4OS03LjY5MS0yMC4xNTgtNy42OTEtMjcuODQ5IDAtNy42OSA3LjY5LTcuNjkgMjAuMTU4IDAgMjcuODQ5bDEzOS40ODEgMTM5LjQ4MWM3LjY4NyA3LjY4NyAyMC4xNiA3LjY4OSAyNy44NDkgMGwzMzMuMTMzLTMzMy4xMzZjNy42OS03LjY5MSA3LjY5LTIwLjE1OSAwLTI3Ljg0OXoiIGZpbGw9IiNGRkYiLz48L3N2Zz4=);
        background-repeat: no-repeat;
        background-position: center;
        background-size: 16px;
        font-size: 0;
    }

    .action.primary.amcform-next,
    .action.primary.amcform-submit,
    .action.primary.amcform-prev {
        display: inline-block;
        margin: 20px auto;
        min-width: 120px;
        padding: $spacer + 2;
        background-color: #f78833;
        font-size: $font-size-medium;
        color: #fff;
        font-weight: $font-weight-semi-medium;
        cursor: pointer;
        vertical-align: middle;
        border: none;
        box-sizing: border-box;
    }

    .action.primary.amcform-prev {
        margin-right: $spacer--medium - 1;
    }

    .action.primary.amcform-submit {
        float: none;
    }

    .amcform-gdpr {
        display: none;
    }

    .amcform-toolbar {
        text-align: right;

        div {
            display: inline-block;
        }
    }

    .form-control {
        background-color: $white !important;
        box-shadow: 0px 1px 2px 0px #888888 !important;
    }

    .label {
        font-size: $font-size-small;
        color: $black;
        font-weight: $font-weight-bold;
        line-height: 1.4;
        font-style: normal;
    }

    .checkbox-group {
        margin-bottom: $spacer + 2;

        .amform-versiontwo-label {
            color: #333;
            font-size: $font-size-small;
        }
    }

    .amform-form {
        .amform-form-fieldset {
            .amform-layout-three {
                .form-control {
                    margin-top: $spacer--medium + 4;
                }

                .form-control.rowof.form-control {
                    margin-top: $spacer--large + 3;
                }

                .radio-group {
                    margin-top: $spacer--large - 2;

                    .amform-versiontwo-label {
                        color: #333;
                        font-size: $font-size-small;
                    }
                }
            }
        }
    }

    .rendered-form .amform-checkboxtwo {
        height: 15px;
        width: 15px;
        vertical-align: middle;

        @include mq($max-screen: $screen-m - 1) {
            height: 15px;
            width: 15px;
        }
    }

    .grecaptcha-badge {
        transition: right 0.3s ease 0s !important;
        display: block;
        bottom: 14px;
        right: -186px;
        visibility: visible !important;
        box-shadow: grey 0px 0px 5px;
        border-radius: 2px;
        overflow: hidden;
    }

    .grecaptcha-badge:hover {
        right: 0px;
    }
}

/*catalog-product_compare-index*/
body.catalog-product_compare-index {
    #maincontent {
        margin-top: 0;
    }

    h1.heading.heading--page {
        margin-bottom: $spacer--medium + 4;
    }

    table {
        overflow: hidden;

        td {
            width: 100%;
        }

        .remove {
            text-align: right;
        }
    }
}

/* cms-solar-system-training */
body.cms-solar-system-training {
    .cms-page__content {
        max-width: 100%;
    }

    .heading--page {
        display: none;
    }

    .header-cont {
        padding-top: 0;
        margin-top: 0;
    }

    .page-title-header {
        font-size: $font-size-super-extra-large + 8;
        margin-bottom: $spacer--medium + 4;
        line-height: $font-line-height;

        @media all and (max-width: ($screen-m - 1)) {
            font-size: $font-size-extra-large + 4;
        }
    }

    .css-bottom-details {
        h3 {
            font-size: $font-size-medium;
            font-weight: bold;
            margin-bottom: $spacer--medium - 1;
            font-family: inherit;
        }

        p {
            margin-top: $spacer + 2;
        }
    }

    .left-box1 {
        background: radial-gradient(
            ellipse at center,
            rgba(255, 255, 255, 1) 0%,
            rgba(255, 255, 255, 0.97) 4%,
            rgba(255, 255, 255, 0.73) 41%,
            rgba(30, 87, 153, 0.34) 100%
        );
        padding: 25px 10px 15px 10px;
    }

    .contact-hed {
        text-shadow: 1px 1px 1px #b1b1b2;
        color: #cc3300;
        font-size: $font-size-large;
    }

    .boxc {
        background-color: $white;
        padding: 15px 20px;
        margin: 0px 25px;
        border-radius: 25px;
    }

    .boxd {
        background: radial-gradient(
            ellipse at center,
            rgba(255, 255, 255, 0) 0%,
            rgba(255, 255, 255, 0.33) 84%,
            rgba(213, 219, 237, 1) 100%
        );
        padding: 15px 10px;
        border: $border-base;
    }

    img.drop-shadow-image {
        margin-bottom: $spacer--extra-small;
        margin-top: $spacer--extra-small + 2;
        border-radius: 12px;
        box-shadow: 3px 3px 3px #b1b1b2;
    }

    .text-decoration-none {
        text-decoration: none;
    }

    p {
        line-height: 1.4;
    }

    hr {
        border: 0;
        border-top: 1px solid #d1d1d1;
        margin-bottom: $spacer--medium + 4;
        margin-top: $spacer--medium + 4;
    }
}

/* cms-solar-contractors */
body.cms-solar-contractors {
    .dark-orange-links {
        font-weight: $font-weight-base;
    }

    .cms-page__content {
        max-width: 100%;
    }

    .heading--page {
        display: none;
    }

    .header-cont {
        padding-top: 0;
        margin-top: 0;
    }

    .final-text {
        margin-left: auto;
        margin-right: auto;
    }

    .column-with-p3 {
        padding: $spacer--large - 2;
    }
}

/* cms-residential-solar-system-financing */
body.cms-residential-solar-financing {
    .cms-page__content {
        max-width: 100%;
    }

    .heading--page {
        display: none;
    }

    .header-cont {
        padding-top: 0;
        margin-top: 0;
    }

    .home-sub-header {
        font-size: $font-size-small;
    }

    ul {
        li {
            padding-left: $spacer + 2;
        }
    }

    .admiral-bank-box {
        background-image: radial-gradient(
            ellipse at center,
            #00196e 0%,
            #00196e 63%,
            #00196e 63%,
            #454e6b 100%
        );
    }

    .right-loan-house1 {
        padding: $spacer + 2;
        border-radius: 12px;
        -webkit-box-shadow: 2px 2px 2px #ddd7d0;
        box-shadow: 2px 2px 2px #ddd7d0;

        p {
            color: #f9f5e9;
        }
    }
}

/* cms-residential-solar-system-projects */
body.cms-residential-solar-system-projects {
    .cms-page__content {
        max-width: 100%;
    }

    .heading--page {
        display: none;
    }

    .header-cont {
        padding-top: 0;
        margin-top: 0;
    }

    .roof-cont {
        border: $border-width-base $border-style-base #ddd;
        padding: 20px 0px;
        margin-bottom: $spacer--medium + 4;

        .roof-mounted {
            text-align: center;

            a {
                color: $dark-orange;
                text-decoration: none;
                font-weight: $font-weight-semi-medium;

                &:hover {
                    color: $dark-blue;
                }
            }
        }
    }

    .roof-bottom {
        padding: 0px;
        margin-bottom: $spacer--large - 2;
        margin-right: 0;
        margin-left: 0;
        border: $border-width-base $border-style-base #ddd;
        width: 100%;
        background: #f6f6f6;

        .roof-left {
            margin: 0;
            padding: 15px 0px;
            text-align: left;
        }

        .roof-right {
            padding: 20px 0px;
            margin: 0px auto;
        }
    }

    .different-font {
        font-family: Segoe, "Segoe UI", "DejaVu Sans", "Trebuchet MS", Verdana,
            Arial, sans-serif;
    }
}

/* cms-commercial-solar-system-projects */
body.cms-commercial-solar-system-projects {
    .cms-page__content {
        max-width: 100%;
    }

    .heading--page {
        display: none;
    }

    .header-cont {
        padding-top: 0;
        margin-top: 0;
    }

    .dark-black-links {
        font-weight: $font-weight-base;
        padding-top: $spacer--medium - 1;
        padding-bottom: $spacer--medium - 1;
    }

    h3.large-h3:not(.sub-title) {
        font-weight: $font-weight-bold;
        margin-bottom: $spacer--large - 2;
    }

    .review-box-container {
        @media all and (max-width: ($screen-l - 1)) {
            padding-left: $spacer--medium + 4;
            padding-right: $spacer--medium + 4;
        }
    }

    .review-box {
        background-color: #f2f2f2;
        margin-top: $spacer--large - 2;
        padding-top: $spacer--large - 2;
        padding-bottom: $spacer--medium + 4;
    }
}

/* cms-solar-contractors-create-a-siteplan */
body.cms-solar-contractors-create-a-siteplan {
    .cms-page__content {
        max-width: 100%;
    }

    .heading--page {
        display: none;
    }

    .header-cont {
        padding-top: 0;
        margin-top: 0;
    }

    hr {
        border-top: $border-width-base $border-style-base #d1d1d1;
    }

    .list-u-alpha {
        list-style: upper-alpha;
        padding: 0;
    }

    .list-u-alpha li,
    .list-num li {
        font-weight: $font-weight-bold;
        font-size: $font-size-small;
        margin-bottom: $spacer + 2;
    }

    .list-u-alpha li span,
    .list-num li span {
        font-weight: normal;
    }

    .list-style-none {
        list-style: none;
        padding: 0;
        font-size: $font-size-small;
        margin-bottom: $spacer + 2;
    }

    .list-bottom {
        padding: 0;

        h5 {
            font-weight: $font-weight-bold;
        }

        li {
            padding-left: $spacer + 2;
        }
    }
}

/* cms-solar-panel-specials */
body.cms-solar-panel-specials {
    .cms-page__content {
        max-width: 100%;
    }

    .heading--page {
        display: none;
    }

    .header-cont {
        padding-top: 0;
        margin-top: 0;

        h3 {
            font-size: $font-size-medium;
        }

        .row {
            .col-sm-12 {
                padding: 0 $spacer;
            }
        }
    }

    .box-clr-ly-wrapper {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        gap: $spacer--medium + 4;

        @media all and (max-width: ($screen-xl - 1)) {
            grid-template-columns: 1fr 1fr;
        }

        @media all and (max-width: ($screen-l - 1)) {
            grid-template-columns: 1fr;
        }
    }
}

/* cms-contacts */
body.cms-contacts {
    .cms-page__content {
        max-width: 100%;
    }

    .heading--page {
        display: none;
    }

    iframe {
        max-width: 600px;
        width: 100%;
    }

    label {
        font-weight: $font-weight-bold;
    }

    .col-md-10 {
        display: flex;
        justify-content: center;

        .rendered-form.amform-form {
            width: 100%;
            margin: 20px auto;
        }
    }

    .amcform-toolbar {
        .amcform-submit.action {
            margin: 20px auto;
            min-width: 120px;
            padding: $spacer + 2;
            background-color: $gray-light;
            font-size: $font-size-base;
            color: $charcoal-black;
            font-weight: $font-weight-semi-medium;
            cursor: pointer;
            border: none;

            &:hover {
                background-color: $dark-orange;
                color: $white;
            }
        }

        .grecaptcha-badge {
            transition: right 0.3s ease 0s !important;
            display: block;
            bottom: 14px;
            right: -186px;
            visibility: visible !important;
            box-shadow: grey 0px 0px 5px;
            border-radius: 2px;
            overflow: hidden;
        }

        .grecaptcha-badge:hover {
            right: 0px;
        }
    }

    .field.field-phone {
        /* Chrome, Safari, Edge, Opera */
        input::-webkit-outer-spin-button,
        input::-webkit-inner-spin-button {
            -webkit-appearance: none;
            margin: 0;
        }

        /* Firefox */
        input[type="number"] {
            -moz-appearance: textfield;
        }
    }
}

/* cms-contractor-application */
body.cms-contractor-application {
    .heading--page {
        display: none;
    }

    .cms-page__content {
        max-width: 100%;
    }

    #maincontent {
        margin-top: 0;

        .cms-page__content {
            .rendered-form.amform-form {
                margin: 30px auto;

                label {
                    font-size: $font-size-small;
                    font-weight: $font-weight-bold;
                }

                .checkbox-group {
                    label {
                        font-weight: $font-weight-base;
                    }
                }
            }
        }
    }

    .amcform-toolbar {
        .amcform-submit.action {
            margin: 20px auto;
            min-width: 120px;
            padding: $spacer + 2;
            background-color: $gray-light;
            font-size: $font-size-base;
            color: $charcoal-black;
            font-weight: $font-weight-semi-medium;
            cursor: pointer;
            border: none;

            &:hover {
                background-color: $dark-orange;
                color: $white;
            }
        }
    }

    .grecaptcha-badge {
        transition: right 0.3s ease 0s;
        display: block;
        bottom: 14px;
        right: -186px;
        visibility: visible !important;
        box-shadow: grey 0px 0px 5px;
        border-radius: 2px;
        overflow: hidden;
    }

    .grecaptcha-badge:hover {
        right: 0px;
    }
}

/*seositemap-index-index*/
body.seositemap-index-index {
    .mst-seo-sitemap__map {
        .search-bar {
            display: flex;

            input[type="search"] {
                position: relative;
                margin: 0 auto;
                max-width: 45rem;
            }
        }

        .provider {
            margin-bottom: 4rem;

            ul {
                list-style: none;
                margin: 2rem 0 0 2rem;
                padding: 0;

                &.cols-1 {
                    column-count: 1;
                }

                &.cols-2 {
                    column-count: 2;
                }

                &.cols-3 {
                    column-count: 3;
                }

                &.cols-4 {
                    column-count: 4;
                }

                &.cols-5 {
                    column-count: 5;
                }

                &.cols-6 {
                    column-count: 6;
                }

                &.cols-7 {
                    column-count: 7;
                }

                &.cols-8 {
                    column-count: 8;
                }

                &.cols-9 {
                    column-count: 9;
                }

                &.cols-10 {
                    column-count: 10;
                }

                li {
                    display: inline;

                    a {
                        display: flex;
                        flex-wrap: wrap;
                        margin-bottom: 0.5rem;
                        color: $dark-orange;

                        span {
                            &:after {
                                font-size: $font-size-extra-small;
                                line-height: $font-line-height;
                                content: "\e608";
                                font-family: "luma-icons";
                                margin: 0 $spacer + 2;
                                vertical-align: top;
                                display: inline-block;
                                font-weight: $font-weight-normal;
                                overflow: hidden;
                                speak: none;
                                text-align: center;
                                color: #707070;
                            }

                            &:last-child {
                                &:after {
                                    display: none;
                                }
                            }
                        }
                    }
                }
            }

            .letter {
                margin: 0 0 2rem -2rem;

                strong {
                    font-size: 2rem;
                    font-weight: $font-weight-bolder;
                    display: block;
                }

                .strong_visible {
                    display: block;
                }

                .strong_hidden {
                    display: none;
                }

                ul {
                    margin-top: 0;
                }
            }

            ._highlight {
                background: #fad764;
                font-style: normal;
            }
        }
    }

    @media (max-width: $screen-m) {
        .mst-seo-sitemap__map {
            ul {
                column-count: 1 !important;
            }
        }
    }
}

/* cms-solar-panel-mounts-roof-mounts-quick-mount-pv-qbase */
/* cms-solar-panel-mounts-roof-mounts-quick-mount-pv-classic-shake */
/* cms-solar-panel-mounts-roof-mounts-quick-mount-pv-classic-composition */
/* cms-solar-panel-mounts-roof-mounts-prosolar-tilt-up-kit */
/* cms-solar-panel-mounts-roof-mounts-prosolar-tiletrac */
/* cms-solar-inverter-xantrex-sw */
/* cms-solar-panels-solarworld-solar-panels */
/* cms-remote-industrial-solar-combiner */
/* cms-solar-panel-mounts-pole-mounts*/
/* cms-commercial-solar-systems-economics */
/* cms-solar-panels-q-cells-solar-panels */
/* cms-solar-panels-rec-solar-modules */
/* cms-solar-panels-sanyo-solar-modules */
/* cms-solar-panels-schuco-solar-modules */
/* cms-solar-panels-sharp-solar-panels */
/* cms-solar-panels-bp-solar-modules */
/* cms-solar-inverter-magnum */
/* cms-solar-inverter-outback-fx-series */
/* cms-solar-inverter-morningstar-suresine */
/* cms-solar-inverter-sunny-boy */
/* cms-solar-inverter-solaredge */
/* cms-solar-inverter-sma-sunny-island */
/* cms-solar-inverter-sma-sunny-island-multicluster */
/* cms-solar-inverter-schneider-electric-xantrex */
/* cms-solar-inverter-enphase */
/* cms-solar-inverter-solaron */
/* cms-solar-inverter-pv-powered */
/* cms-solar-inverter-enphase-envoy */
/* cms-solar-inverter-solectria-solectria-information */
/* cms-solar-charge-controllers */
/* cms-solar-links */
/* cms-solar-inverters */
/* cms-solar-panels-kyocera-solar-modules */
/* cms-solar-panels-solarworld-solar-panels */
/* cms-solar-battery-backup-systems-midnite-solar */
/* cms-remote-industrial-solar-industrial */
/* cms-solar-panels-lg-solar-modules */
/* cms-solar-panels-isofoton-solar-modules */
/* cms-solar-panels-perlight-solar-modules */
/* cms-solar-inverter-power-panels-xantrex */
/* cms-solar-inverter-outback-flexware */
/* cms-solar-inverter-sma-sunny-island-smartformer */
/* cms-solar-charge-controllers-morningstar-charge-controllers-morningstar-sunsaver-charge-controllers */
/* cms-solar-panel-mounts-prosolar */
/* cms-remote-industrial-solar-charge-controller */
/* cms-remote-industrial-solar-outback-mppt */
/* cms-solar-panels-trina-solar-modules */
/* cms-remote-industrial-solar-solar-radiation-jan */
/* cms-remote-industrial-solar-raps */
/* cms-remote-industrial-solar-raps-pole-mount */
/* cms-solar-batteries-deka-solar-batteries*/
/* cms-solar-batteries-rolls-batteries */
/* cms-solar-batteries-extreme-solar-agm-sealed-battery */
/* cms-solar-panels-vls-value-line-solar */
/* cms-solar-panels-shell-shell-solar */
/* cms-solar-batteries-batteries */
/* cms-solar-batteries-flooded-battery */
/* cms-remote-industrial-solar-infographic  */
/* cms-solar-panels-samsung-solar-modules */
/* cms-solar-panel-mounts-ground-mounts-schletter */
/* cms-solar-inverter-solaredge-optimizer */
/* cms-solar-inverter-fronius-cl-inverters */
/* cms-solar-panels-silevo-solar-panels */
/* cms-solar-inverter-kaco */
/*cms-solar-panels-canadian-solar-modules*/
/*cms-remote-industrial-solar-power-panels*/
/*cms-solar-panels-shell-systems*/
/*cms-solar-charge-controllers-solar-boost*/
/*cms-remote-industrial-solar-sunlight*/
/*cms-remote-industrial-solar-xantrex*/
/*cms-solar-panel-mounts-pole-mounts-dpw-top-of-pole*/
/*cms-solar-panel-mounts-roof-mounts-prosolar-fastjack*/
/*cms-solar-panel-mounts-roof-mounts-prosolar-foam-jack*/
/*cms-solar-panel-mounts-roof-mounts-prosolar-rail-splice-kit*/
/*cms-solar-panel-mounts-roof-mounts-prosolar-solar-wedge*/
/*cms-remote-industrial-solar-mapps-street-light-power-pole-ac-adapter*/
/* cms-solar-panel-mounts-roof-mounts-quick-mount-pv-classic-conduit-mount */
/* cms-solar-panel-mounts-roof-mounts-quick-mount-pv */
/*cms-solar-inverter-schneider-electric-conext */
/* cms-solar-batteries-concorde-sun-xtender-agm-solar-batteries */
/* cms-solar-panels-solarland-class-1-division-2-solar-panels */
/* cms-solar-inverter-sma-sunny-tripower-inverters */
/* cms-solar-battery-backup-systems-midnite-solar-sunny-island-e-panel */
/*cms-latinoamerica-sistemas-energia-solar-comercial*/
/*cms-latinoamerica-sistemas-energia-solar-hogar-residencial*/
/*cms-latinoamerica-sistemas-fuera-de-red-remotos*/
/*cms-latinoamerica-sistemas-paneles-solares-energia*/
/*cms-solar-panel-mounts-roof-mounts-ironridge-flashfoot-composition-shingle-mount*/
/*cms-solar-panel-mounts-roof-mounts-snapnrack-flashed-l-foot-kit*/
/*cms-solar-panel-mounts-roof-mounts-snapnrack-metal-roof-base*/
/*cms-solar-panel-mounts-roof-mounts-snapnrack-series-500-standing-seam-metal-system*/
/* cms-solar-panels-panasonic-hit-solar-panels */
/* cms-solar-panel-mounts-roof-mounts-s-5-versabracket */
/* cms-solar-panel-mounts-roof-mounts-quick-mount-pv-quick-rack */
/* cms-solar-panel-mounts-roof-mounts-quick-mount-pv-e-mount */
/* cms-solar-panel-mounts-roof-mounts-quick-mount-pv-conduit-penetration-flashing */
/*cms-solaria-solar-panels-powerxt-low-wholesale-price*/
/*cms-solar-panels-discount-gridtie*/
/*cms-solar-inverter-solaredge-solaredge-storedge-energy-storage-inverters*/
/*cms-solar-panel-mounts-roof-mounts-s-5*/
/*cms-solar-panel-mounts-roof-mounts-s-5-s-5-clamps-brackets*/
/*cms-solar-panel-mounts-roof-mounts-s-5-s-5-pv-kit*/
/*cms-solar-panel-mounts-roof-mounts-s-5-proteabracket*/
/*cms-solar-panel-mounts-roof-mounts-s-5-s-5-corrubracket-100t*/
/*cms-solar-charge-controllers-morningstar-charge-controllers*/
/*cms-solar-charge-controllers-morningstar-charge-controllers-morningstar-tristar-charge-controllers*/
/* cms-silfab-solar-panels */
/* cms-mission-solar-panels */
/* cms-energy-storage-systems-lg-chem-ess-lg-chem-resu-ess-energy-storage-battery-systems */
/*cms-solar-panel-mounts-mt-solar-ground-mount*/
/*cms-solar-charge-controllers-morningstar-charge-controllers-morningstar-prostar-charge-controllers*/
/*cms-solar-panels-jinko-solar-modules*/
/*cms-solar-battery-backup-systems-magnum-pae-solar-system-battery-backup-systems*/
/*cms-rec-solar-professional-program*/
/*cms-solar-batteries-discover-batteries*/
/*cms-solar-batteries-relion-lithium-batteries*/
/*cms-solar-panels-ameresco-solar-panels*/
/*cms-energy-storage-systems-lg-chem-ess*/
/*cms-rec-alpha-n-peak-twinpeak-sale*/
/*cms-rec-alpha-series-solar-panels-v1*/
/*cms-solar-panel-mounts-roof-mounts-snapnrack-series-100*/
/*cms-solar-inverter-enphase-enphase-iq-battery-energy-storage*/
/*cms-solar-batteries-narada-batteries*/
/*cms-solar-batteries-aquion-energy-aspen-ahi-saltwater-battery*/
/*cms-solar-batteries-simpliphi-batteries*/
/*cms-solar-batteries-deka-fahrenheit-batteries*/
/*cms-solar-panels-hyundai-solar-panels*/
/*cms-rec-alpha-series-solar-panels*/
/*cms-solar-inverter-hiq-solar*/
/*cms-energy-storage-systems*/
body.cms-solar-inverter-xantrex-sw,
body.cms-solar-panels-solarworld-solar-panels,
body.cms-remote-industrial-solar-combiner,
body.cms-solar-panel-mounts-pole-mounts,
body.cms-commercial-solar-systems-economics,
body.cms-solar-panels-q-cells-solar-panels,
body.cms-solar-panels-rec-solar-modules,
body.cms-solar-panels-sanyo-solar-modules,
body.cms-solar-panels-schuco-solar-modules,
body.cms-solar-panels-sharp-solar-panels,
body.cms-solar-panels-bp-solar-modules,
body.cms-solar-inverter-magnum,
body.cms-solar-inverter-outback-fx-series,
body.cms-solar-inverter-morningstar-suresine,
body.cms-solar-inverter-sunny-boy,
body.cms-solar-inverter-solaredge,
body.cms-solar-inverter-sma-sunny-island,
body.cms-solar-inverter-sma-sunny-island-multicluster,
body.cms-solar-inverter-schneider-electric-xantrex,
body.cms-solar-inverter-enphase,
body.cms-solar-inverter-solaron,
body.cms-solar-inverter-pv-powered,
body.cms-solar-inverter-enphase-envoy,
body.cms-solar-inverter-solectria-solectria-information,
body.cms-solar-charge-controllers,
body.cms-solar-links,
body.cms-solar-inverters,
body.cms-solar-panels-kyocera-solar-modules,
body.cms-solar-panels-solarworld-solar-panels,
body.cms-solar-battery-backup-systems-midnite-solar,
body.cms-remote-industrial-solar-industrial,
body.cms-solar-panels-lg-solar-modules,
body.cms-solar-panels-isofoton-solar-modules,
body.cms-solar-panels-perlight-solar-modules,
body.cms-solar-inverter-power-panels-xantrex,
body.cms-solar-inverter-outback-flexware,
body.cms-solar-inverter-sma-sunny-island-smartformer,
body.cms-solar-charge-controllers-morningstar-charge-controllers-morningstar-sunsaver-charge-controllers,
body.cms-solar-panel-mounts-prosolar,
body.cms-remote-industrial-solar-charge-controller,
body.cms-solar-panels-perlight-solar-modules,
body.cms-remote-industrial-solar-outback-mppt,
body.cms-solar-panels-trina-solar-modules,
body.cms-remote-industrial-solar-solar-radiation-jan,
body.cms-remote-industrial-solar-raps,
body.cms-remote-industrial-solar-raps-pole-mount,
body.cms-solar-batteries-deka-solar-batteries,
body.cms-solar-batteries-rolls-batteries,
body.cms-solar-batteries-extreme-solar-agm-sealed-battery,
body.cms-solar-panels-vls-value-line-solar,
body.cms-solar-panels-shell-shell-solar,
body.cms-solar-batteries-batteries,
body.cms-solar-batteries-flooded-battery,
body.cms-remote-industrial-solar-infographic,
body.cms-solar-panels-samsung-solar-modules,
body.cms-solar-panel-mounts-ground-mounts-schletter,
body.cms-solar-inverter-solaredge-optimizer,
body.cms-solar-inverter-fronius-cl-inverters,
body.cms-solar-panels-silevo-solar-panels,
body.cms-solar-inverter-kaco,
body.cms-solar-panels-canadian-solar-modules,
body.cms-remote-industrial-solar-power-panels,
body.cms-solar-panels-shell-systems,
body.cms-solar-charge-controllers-solar-boost,
body.cms-remote-industrial-solar-sunlight,
body.cms-remote-industrial-solar-xantrex,
body.cms-solar-panel-mounts-pole-mounts-dpw-top-of-pole,
body.cms-solar-panel-mounts-roof-mounts-prosolar-fastjack,
body.cms-solar-panel-mounts-roof-mounts-prosolar-foam-jack,
body.cms-solar-panel-mounts-roof-mounts-prosolar-rail-splice-kit,
body.cms-solar-panel-mounts-roof-mounts-prosolar-solar-wedge,
body.cms-remote-industrial-solar-mapps-street-light-power-pole-ac-adapter,
body.cms-solar-panel-mounts-roof-mounts-quick-mount-pv-classic-conduit-mount,
body.cms-solar-panel-mounts-roof-mounts-quick-mount-pv,
body.cms-solar-inverter-schneider-electric-conext,
body.cms-solar-batteries-concorde-sun-xtender-agm-solar-batteries,
body.cms-solar-panels-solarland-class-1-division-2-solar-panels,
body.cms-solar-inverter-sma-sunny-tripower-inverters,
body.cms-solar-battery-backup-systems-midnite-solar-sunny-island-e-panel,
body.cms-solar-panel-mounts-roof-mounts-quick-mount-pv-qbase,
body.cms-solar-panel-mounts-roof-mounts-quick-mount-pv-classic-shake,
body.cms-solar-panel-mounts-roof-mounts-quick-mount-pv-classic-composition,
body.cms-solar-panel-mounts-roof-mounts-prosolar-tilt-up-kit,
body.cms-solar-panel-mounts-roof-mounts-prosolar-tiletrac,
body.cms-remote-industrial-solar-mapps-street-light-power-pole-ac-adapter,
body.cms-latinoamerica-sistemas-energia-solar-comercial,
body.cms-latinoamerica-sistemas-energia-solar-hogar-residencial,
body.cms-latinoamerica-sistemas-fuera-de-red-remotos,
body.cms-latinoamerica-sistemas-paneles-solares-energia,
body.cms-solar-panel-mounts-roof-mounts-ironridge-flashfoot-composition-shingle-mount,
body.cms-solar-panel-mounts-roof-mounts-snapnrack-flashed-l-foot-kit,
body.cms-solar-panel-mounts-roof-mounts-snapnrack-metal-roof-base,
body.cms-solar-panel-mounts-roof-mounts-snapnrack-series-500-standing-seam-metal-system,
body.cms-solar-panels-panasonic-hit-solar-panels,
body.cms-solar-panel-mounts-roof-mounts-s-5-versabracket,
body.cms-solar-panel-mounts-roof-mounts-quick-mount-pv-e-mount,
body.cms-solar-panel-mounts-roof-mounts-quick-mount-pv-quick-rack,
body.cms-solar-panel-mounts-roof-mounts-quick-mount-pv-conduit-penetration-flashing,
body.cms-solaria-solar-panels-powerxt-low-wholesale-price,
body.cms-solar-panels-discount-gridtie,
body.cms-solar-inverter-solaredge-solaredge-storedge-energy-storage-inverters,
body.cms-solar-panel-mounts-roof-mounts-s-5,
body.cms-solar-panel-mounts-roof-mounts-s-5-s-5-clamps-brackets,
body.cms-solar-panel-mounts-roof-mounts-s-5-s-5-pv-kit,
body.cms-solar-panel-mounts-roof-mounts-s-5-proteabracket,
body.cms-solar-panel-mounts-roof-mounts-s-5-s-5-corrubracket-100t,
body.cms-solar-charge-controllers-morningstar-charge-controllers,
body.cms-solar-charge-controllers-morningstar-charge-controllers-morningstar-tristar-charge-controllers,
body.cms-silfab-solar-panels,
body.cms-mission-solar-panels,
body.cms-energy-storage-systems-lg-chem-ess-lg-chem-resu-ess-energy-storage-battery-systems,
body.cms-solar-panel-mounts-mt-solar-ground-mount,
body.cms-solar-charge-controllers-morningstar-charge-controllers-morningstar-prostar-charge-controllers,
body.cms-solar-panels-jinko-solar-modules,
body.cms-solar-battery-backup-systems-magnum-pae-solar-system-battery-backup-systems,
body.cms-rec-solar-professional-program,
body.cms-solar-batteries-discover-batteries,
body.cms-solar-batteries-relion-lithium-batteries,
body.cms-solar-panels-ameresco-solar-panels,
body.cms-energy-storage-systems-lg-chem-ess,
body.cms-rec-alpha-n-peak-twinpeak-sale,
body.cms-rec-alpha-series-solar-panels-v1,
body.cms-solar-panel-mounts-roof-mounts-snapnrack-series-100,
body.cms-solar-inverter-enphase-enphase-iq-battery-energy-storage,
body.cms-solar-batteries-narada-batteries,
body.cms-solar-batteries-aquion-energy-aspen-ahi-saltwater-battery,
body.cms-solar-batteries-simpliphi-batteries,
body.cms-solar-batteries-deka-fahrenheit-batteries,
body.cms-solar-panels-hyundai-solar-panels,
body.cms-rec-alpha-series-solar-panels,
body.cms-solar-inverter-hiq-solar,
body.cms-energy-storage-systems
{
    .cms-page__content {
        max-width: 100%;
    }

    h1.heading--page {
        display: none;
    }

    #maincontent {
        margin-top: 0;
    }
}

body.cms-solar-panels-sharp-solar-panels,
body.cms-solar-panels-bp-solar-modules,
body.cms-solar-inverter-magnum,
body.cms-solar-inverter-outback-fx-series,
body.cms-solar-inverter-morningstar-suresine,
body.cms-solar-inverter-schneider-electric-xantrex,
body.cms-solar-inverter-sma-sunny-island-multicluster,
body.cms-solar-inverter-sma-sunny-island,
body.cms-solar-inverter-solaredge,
body.cms-solar-inverter-sunny-boy {
    .black-links {
        a {
            color: $black;
            text-decoration: none;

            &:hover {
                text-decoration: underline;
            }
        }
    }

    .orange-links {
        a {
            color: $dark-orange;
            text-decoration: none;

            &:hover {
                text-decoration: underline;
            }
        }
    }

    .custom-headings {
        font-size: $font-size-small;

        h1 {
            font-size: $font-size-extra-large + 4;

            @media all and (min-width: ($screen-m)) {
                font-size: $font-size-super-extra-large + 8;
            }
        }

        h3 {
            font-size: $font-size-extra-large;
            margin-bottom: $spacer + 2;
            line-height: 1.1;
            width: 100%;

            @media all and (max-width: 425px) {
                font-size: $font-size-large + 2;
            }
        }

        p {
            margin-bottom: $spacer + 2;
        }
    }
}

body.cms-solar-inverters {
    .orange-links {
        a {
            color: $dark-orange;
            text-decoration: none;

            &:hover {
                text-decoration: underline;
            }
        }
    }

    .font-base {
        font-size: $font-size-small;
    }

    .right-sidebar {
        font-size: $font-size-extra-large;
        font-weight: bold;
        padding: 0 $spacer + 2;
        margin-bottom: $spacer + 2;

        @media all and (max-width: 425px) {
            font-size: $font-size-large + 2;
        }
    }

    .sidebar-links {
        list-style-type: none;
        margin: 0;
        padding: 0 $spacer + 2;

        li {
            margin-bottom: $spacer + 2;

            a {
                display: block;
                color: $black;
                text-decoration: none;
                font-size: $font-size-small;

                &:hover {
                    text-decoration: underline;
                }
            }
        }
    }

    .header-cont {
        padding: $spacer--medium - 1;

        .home-sub-header-x {
            font-size: $font-size-super-extra-large + 8;

            @media all and (max-width: ($screen-m - 1)) {
                font-size: $font-size-extra-large + 4;
            }
        }
    }

    .page-title {
        padding-left: 0;

        @media all and (max-width: ($screen-m - 1)) {
            padding: 15px 15px 0px 15px;
        }
    }

    h4 {
        font-size: $font-size-large + 1;
        width: 100%;
    }

    h3 {
        font-size: $font-size-extra-large;
        width: 100%;

        @media all and (max-width: 425px) {
            font-size: $font-size-large + 2;
        }
    }

    .row-ml-0 {
        .row {
            margin-left: 0;
        }
    }

    .box {
        border-right: 1px solid #f4f4f4;

        @media all and (max-width: 425px) {
            border: none;
        }
    }

    .hr-m-2 {
        hr {
            margin-top: $spacer--medium + 4;
            margin-bottom: $spacer--medium + 4;
        }
    }
}

body.cms-solar-links {
    h1 {
        font-size: $font-size-extra-large + 4;

        @media all and (min-width: ($screen-m)) {
            font-size: $font-size-super-extra-large + 8;
        }
    }

    h2 {
        font-size: $font-size-extra-large + 4;

        @media all and (max-width: 425px) {
            font-size: $font-size-extra-large + 1;
        }

        @media all and (min-width: ($screen-m)) {
            font-size: $font-size-super-extra-large - 2;
        }
    }

    h4 {
        font-size: $font-size-large + 1;
        width: 100%;
    }

    .sloped-roof {
        font-size: $font-size-small;
    }

    .black-links {
        a {
            color: $black;
            text-decoration: none;

            &:hover {
                text-decoration: underline;
            }
        }
    }
}

body.cms-solar-charge-controllers {
    h2.home-sub-header-x {
        font-weight: $font-weight-semi-medium;
        font-size: $font-size-large + 2;
        line-height: $font-size-large;
        color: $darkest-blue;
        margin-bottom: $spacer--large + 3;
    }

    h2 {
        font-size: $font-size-extra-large + 4;
        line-height: 1.1;

        @media all and (max-width: 425px) {
            font-size: $font-size-extra-large + 1;
        }

        @media all and (min-width: ($screen-m)) {
            font-size: $font-size-super-extra-large - 2;
        }
    }

    h3 {
        font-size: $font-size-extra-large;
        width: 100%;

        @media all and (max-width: 425px) {
            font-size: $font-size-large + 2;
        }
    }

    .black-links {
        a {
            color: $black;
            text-decoration: none;

            &:hover {
                text-decoration: underline;
            }
        }
    }

    .right-sidebar {
        font-weight: normal;
        margin-bottom: 0;
        background-color: #f37321;
        padding: $spacer + 2;
        color: $white;
        line-height: 1.1;
    }

    .sidebar-links {
        list-style-type: none;
        margin: 0;
        padding: 0;
        background-color: #f1f1f1;

        li a {
            display: block;
            color: $black;
            padding: 8px 16px;
            text-decoration: none;
            font-size: $font-size-base;
            border-bottom: 1px solid #ccc;

            &:hover {
                background-color: #555;
                color: white;
                text-decoration: none;
            }
        }
    }
}

body.cms-solar-inverter-solectria-solectria-information {
    h1 {
        font-size: $font-size-extra-large + 4;

        @media all and (min-width: ($screen-m)) {
            font-size: $font-size-super-extra-large + 8;
        }
    }

    h3 {
        font-size: $font-size-extra-large;
        width: 100%;

        @media all and (max-width: 425px) {
            font-size: $font-size-large + 2;
        }
    }

    h4 {
        font-size: $font-size-large + 1;
        width: 100%;
    }

    .right-sidebar {
        font-size: $font-size-extra-large;
        font-weight: normal;
        margin-bottom: 0;
        background-color: #f37321;
        padding: $spacer + 2;
        color: $white;
        line-height: 1.1;
    }

    .sidebar-links {
        list-style-type: none;
        margin: 0;
        padding: 0;
        background-color: #f1f1f1;

        li a {
            display: block;
            color: $black;
            padding: 8px 16px;
            text-decoration: none;
            font-size: $font-size-base;
            border-bottom: 1px solid #ccc;

            &:hover {
                background-color: #555;
                color: white;
            }
        }
    }

    .black-links {
        a {
            color: $black;
            text-decoration: none;

            &:hover {
                text-decoration: underline;
            }
        }
    }

    .d-inline {
        display: inline;
    }

    .font-base {
        font-size: $font-size-small;
    }
}

body.cms-solar-inverter-enphase-envoy {
    h1 {
        font-size: $font-size-extra-large + 4;

        @media all and (min-width: ($screen-m)) {
            font-size: $font-size-super-extra-large + 8;
        }
    }

    h2 {
        font-size: $font-size-extra-large + 4;

        @media all and (max-width: 425px) {
            font-size: $font-size-large + 1;
        }

        @media all and (min-width: ($screen-m)) {
            font-size: $font-size-super-extra-large - 2;
        }
    }

    h2.home-sub-header-x {
        font-weight: $font-weight-semi-medium;
        font-size: $font-size-large + 2;
        line-height: $font-size-large;
        color: $darkest-blue;
        margin-bottom: $spacer--large + 3;
    }

    h3 {
        font-size: $font-size-extra-large;
        width: 100%;

        @media all and (max-width: 425px) {
            font-size: $font-size-large + 2;
        }
    }

    h4 {
        font-size: $font-size-large + 1;
        width: 100%;
    }

    table {
        font-size: $font-size-small;

        th {
            padding: 8px 10px;
        }
    }

    .black-links {
        a {
            color: $black;
            text-decoration: none;

            &:hover {
                text-decoration: underline;
            }
        }
    }

    .img-caption {
        text-align: center;
        display: block;
        padding-bottom: $spacer + 2;
        font-size: $font-size-small + 1;
    }

    .text-left {
        text-align: left;
    }

    .review-box {
        padding-top: $spacer--semi-medium + 6;
        background-color: #f2f2f2;
        margin-top: $spacer--semi-medium + 6;
    }

    .w-320 {
        width: 320px;
    }

    img.drop-shadow-image {
        margin-right: auto;
        margin-left: auto;
    }

    .center {
        @media all and (max-width: 425px) {
            text-align: center;

            picture,
            img {
                margin-left: auto;
                margin-right: auto;
            }
        }
    }
}

body.cms-solar-panels-sharp-solar-panels,
body.cms-solar-inverter-enphase,
body.cms-solar-inverter-solaron,
body.cms-solar-inverter-pv-powered {
    h1 {
        font-size: $font-size-extra-large + 4;

        @media all and (min-width: ($screen-m)) {
            font-size: $font-size-super-extra-large + 8;
        }
    }

    h3 {
        font-size: $font-size-extra-large;
        width: 100%;

        @media all and (max-width: 425px) {
            font-size: $font-size-large + 2;
        }
    }

    .img-inline {
        img,
        picture {
            display: inline;
        }
    }

    .bg-strip {
        background-color: #f37321;
        padding: $spacer + 2;
        color: $white;
    }

    .black-links {
        a {
            color: $black;
            text-decoration: none;

            &:hover {
                text-decoration: underline;
            }
        }
    }

    .right-sidebar {
        font-weight: normal;
        margin-bottom: 0;
        background-color: #f37321;
        padding: $spacer + 2;
        color: $white;
        line-height: 1.1;
    }

    .sidebar-links {
        list-style-type: none;
        margin: 0;
        padding: 0;
        background-color: #f1f1f1;

        li a {
            display: block;
            color: $black;
            padding: 8px 16px;
            text-decoration: none;
            font-size: $font-size-base;
            border-bottom: 1px solid #ccc;

            &:hover {
                background-color: #555;
                color: white;
                text-decoration: none;
            }
        }
    }

    .font-small {
        font-size: $font-size-small;
    }

    .data-sheets {
        padding-left: 0;
    }
}

body.cms-solar-inverter-solaron {
    .text-center {
        a {
            flex-direction: column;
            align-items: center;

            img {
                margin-top: 0;
                margin-bottom: 0;
            }

            .img-caption {
                font-size: $font-size-small + 1;
                color: $black;
            }
        }
    }

    table.specifications {
        font-size: $font-size-small;

        th {
            padding-left: 10px;
            padding-right: 10px;
            text-align: left;
        }

        td {
            a {
                picture,
                img {
                    display: inline;
                }
            }
        }
    }
}

body.cms-solar-inverter-enphase {
    a.panels-model {
        flex-direction: column;
        align-items: center;
    }

    .black-links {
        a {
            color: $black;
            text-decoration: none;

            &:hover {
                text-decoration: underline;
            }
        }
    }

    .font-small {
        font-size: $font-size-small;
    }

    .ul-pl-0 {
        ul {
            padding-left: 0;
        }
    }

    .review-box {
        padding-top: $spacer--semi-medium + 6;
        background-color: #f2f2f2;
        margin-top: $spacer--semi-medium + 6;
        padding-left: $spacer--medium - 1;
    }

    .custom-styles {
        h2 {
            font-size: $font-size-extra-large + 4;

            @media all and (max-width: 425px) {
                font-size: $font-size-extra-large + 1;
            }

            @media all and (min-width: ($screen-m)) {
                font-size: $font-size-super-extra-large - 2;
            }
        }

        h3 {
            margin-bottom: $spacer + 2;
        }

        p {
            margin-bottom: $spacer + 2;
        }
    }

    .iq7-container {
        @media all and (min-width: $screen-m) and (max-width: ($screen-l - 1)) {
            display: flex;
            justify-content: center;
        }

        a.panels-model {
            padding-left: $spacer--extra-large + 2;

            @media all and (min-width: $screen-m) and (max-width: ($screen-l - 1)) {
                padding-left: 0;
            }
        }
    }
}

body.cms-solar-inverter-solaredge {
    .orange-links {
        a {
            color: $dark-orange;
            text-decoration: none;

            &:hover {
                text-decoration: underline;
            }
        }
    }

    .custom-headings {
        strong {
            a {
                font-weight: bold;
            }
        }

        .row {
            margin-left: 0;
            margin-right: 0;
        }
    }

    .roof-cont {
        display: flex;
        border: 1px solid #ddd;
        padding: 20px 1%;
        margin-bottom: $spacer--medium + 4;

        .roof-mounted {
            width: 100%;
            list-style: none;
            display: inline-block;
            text-align: center;
            margin-bottom: $spacer--medium + 4;

            p {
                margin-top: $spacer--small + 3;

                a {
                    font-weight: $font-weight-semi-medium;
                }
            }
        }

        a {
            flex-direction: column;
        }

        [class*="col-"] {
            padding: 0;
        }
    }

    .roof-bottom {
        padding: 0 1%;
        margin-bottom: $spacer--large - 2;
        border: 1px solid #ddd;
        width: 100%;
        background: #f6f6f6;

        .roof-left {
            padding: 15px 0px;
        }

        .roof-right {
            a {
                display: inline;
            }

            img {
                padding: 20px 0px;
                margin: 0px auto;
                display: block;
            }
        }

        [class*="col-"] {
            padding: 0;
        }
    }
}

body.cms-solar-inverter-magnum,
body.cms-solar-inverter-sma-sunny-island-multicluster,
body.cms-solar-inverter-sma-sunny-island {
    h3.home-sub-header-x {
        font-weight: $font-weight-semi-medium;
        font-size: $font-size-medium;
        line-height: $font-size-medium + 2;
        color: $darkest-blue;
    }

    .css-bottom-details {
        margin: $spacer--small + 3;
        margin-bottom: 0;

        h4 {
            font-size: $font-size-base;
            font-weight: bold;
            margin: 25px 0px 10px;
            color: $darkest-blue;
        }

        h3 {
            font-size: $font-size-medium;
            font-weight: bold;
            margin-bottom: $spacer--small + 3;
        }

        h5 {
            font-size: $font-size-base;
            font-weight: bold;
            margin-bottom: 0px;
            margin-top: $spacer--semi-medium + 1;
        }

        .mt-3 {
            margin-top: $spacer--large - 2;
        }

        p {
            @media all and (max-width: ($screen-m - 1)) {
                margin-top: $spacer + 2;
            }
        }
    }

    .box {
        border-right: 1px solid #f4f4f4;
    }

    .custom-headings {
        strong {
            a {
                font-weight: bold;
            }
        }

        .row {
            margin-left: 0;
            margin-right: 0;
        }

        h1.home-title-header-x {
            font-weight: $font-weight-bolder;
            font-size: $font-size-extra-large;
            color: $redish-brown;
        }

        a {
            display: inline;
        }

        ul {
            padding-left: 0;
        }

        hr {
            margin-top: $spacer--medium + 4;
            margin-bottom: $spacer--medium + 4;
        }

        .text-center {
            justify-content: center;
        }
    }
}

body.cms-solar-panels-bp-solar-modules,
body.cms-solar-inverter-outback-fx-series,
body.cms-solar-inverter-morningstar-suresine,
body.cms-solar-inverter-schneider-electric-xantrex {
    .w-500 {
        width: 500px;
    }

    .header-cont {
        padding: $spacer--small + 3;
    }

    .css-bottom-details {
        margin: $spacer--small + 3;
        margin-bottom: 0;

        @media all and (max-width: ($screen-m - 1)) {
            margin-top: $spacer--small + 3;
            margin-bottom: $spacer--semi-medium + 1;
        }

        h4 {
            font-size: $font-size-base;
            font-weight: bold;
            margin: 25px 0px 10px;
            color: $darkest-blue;
        }

        h3 {
            font-size: $font-size-medium;
            font-weight: bold;
            margin-bottom: $spacer--small + 3;
        }

        h5 {
            font-size: $font-size-base;
            font-weight: bold;
            margin-bottom: 0px;
            margin-top: $spacer--semi-medium + 1;
        }

        .mt-3 {
            margin-top: $spacer--large - 2;
        }

        p {
            @media all and (max-width: ($screen-m - 1)) {
                margin-top: $spacer + 2;
            }
        }
    }

    .custom-headings {
        h1 {
            line-height: 1.1;
        }

        strong {
            a {
                font-weight: bold;
            }
        }

        .row {
            margin-left: 0;
            margin-right: 0;
        }

        a {
            display: inline;
        }

        hr {
            margin-top: $spacer--medium + 4;
            margin-bottom: $spacer--medium + 4;
        }

        .text-center {
            justify-content: center;
        }
    }

    table.solar-table {
        margin-bottom: 35px;
    }

    .box {
        border-right: 1px solid #f4f4f4;
    }
}

body.cms-solar-panels-bp-solar-modules,
body.cms-solar-inverter-outback-fx-series,
body.cms-solar-inverter-morningstar-suresine,
body.cms-solar-panels-isofoton-solar-modules {
    .box {
        padding-right: $spacer--small + 3;
    }

    .right-sidebar {
        font-size: $font-size-extra-large;
        font-weight: bold;
        padding: 0 $spacer + 2;
        margin-bottom: $spacer + 2;

        @media all and (max-width: 425px) {
            font-size: $font-size-large + 2;
        }
    }

    .sidebar-links {
        list-style-type: none;
        margin: 0;
        padding: 0 $spacer + 2;

        li {
            margin-bottom: $spacer + 2;

            a {
                display: block;
                color: $black;
                text-decoration: none;
                font-size: $font-size-small;

                &:hover {
                    text-decoration: underline;
                }
            }
        }
    }

    .css-bottom-details {
        .mt-0 {
            margin-top: 0;
        }
    }
}

body.cms-solar-inverter-outback-fx-series {
    .header-cont {
        padding-left: 0;
    }

    .hide-md {
        @media all and (min-width: ($screen-m)) {
            display: none;
        }
    }
}

body.cms-solar-inverter-magnum {
    .custom-headings {
        ul.pl-3 {
            padding-left: $spacer--semi-medium + 6;
        }

        h4 {
            font-size: $font-size-large + 1;
            width: 100%;
        }
    }
}

body.cms-solar-panels-bp-solar-modules {
    .sloped-roof {
        width: 100%;
        margin: 15px auto 40px;

        .featured-desc {
            padding: $spacer + 2;
        }
    }

    .featured-cat-box {
        text-align: center;

        @media all and (max-width: ($screen-m - 1)) {
            margin-bottom: $spacer--semi-large + 5;
        }

        picture {
            img {
                margin-left: auto;
                margin-right: auto;
            }
        }
    }
}

body.cms-solar-panels-sharp-solar-panels {
    .custom-headings {
        table {
            td {
                width: auto;
            }
        }

        h2 {
            margin-bottom: $spacer + 2;
            font-size: $font-size-extra-large + 4;

            @media all and (max-width: 425px) {
                font-size: $font-size-extra-large + 1;
            }

            @media all and (min-width: ($screen-m)) {
                font-size: $font-size-super-extra-large - 2;
            }
        }
    }
}

body.cms-solar-panels-q-cells-solar-panels,
body.cms-solar-panels-rec-solar-modules,
body.cms-solar-panels-sanyo-solar-modules,
body.cms-solar-panels-schuco-solar-modules,
body.cms-solar-panels-lg-solar-modules,
body.cms-solar-panels-isofoton-solar-modules,
body.cms-solar-panels-perlight-solar-modules {
    .black-links {
        a {
            color: $black;
            text-decoration: none;

            &:hover {
                text-decoration: underline;
            }
        }
    }

    .orange-links {
        a {
            color: $dark-orange;
            text-decoration: none;

            &:hover {
                text-decoration: underline;
            }
        }
    }

    .box {
        border-right: 1px solid #f4f4f4;

        @media all and (max-width: 425px) {
            border: none;
        }
    }

    .custom-headings {
        font-size: $font-size-small;

        h1 {
            font-size: $font-size-extra-large + 4;

            @media all and (min-width: ($screen-m)) {
                font-size: $font-size-super-extra-large + 8;
            }
        }

        h2 {
            margin-bottom: $spacer + 2;
            font-size: $font-size-extra-large + 4;

            @media all and (max-width: 425px) {
                font-size: $font-size-extra-large + 1;
            }

            @media all and (min-width: ($screen-m)) {
                font-size: $font-size-super-extra-large - 2;
            }
        }

        h3 {
            font-size: $font-size-extra-large;
            margin-bottom: $spacer + 2;
            line-height: 1.1;
            width: 100%;

            @media all and (max-width: 425px) {
                font-size: $font-size-large + 2;
            }
        }

        h4 {
            font-size: $font-size-large + 1;
            width: 100%;
        }

        p {
            margin-bottom: $spacer + 2;
        }
    }
}

body.cms-solar-panels-rec-solar-modules {
    .solar-panel {
        img {
            margin: 0px auto;
        }

        .img-caption {
            display: block;
            margin-top: $spacer--small + 3;
            text-align: center;
            padding-bottom: 10px;
            font-size: $font-size-small + 1;

            a {
                color: $black;
            }
        }
    }

    .alpha-solar-panel {
        padding-top: $spacer--medium + 4;
        padding-bottom: $spacer + 2;

        .panels-model {
            display: block;
        }
    }

    .mx-1p {
        margin-left: 1%;
        margin-right: 1%;
    }

    .px-1p {
        padding-left: 1%;
        padding-right: 1%;
    }

    h3.mb-25-px {
        margin-bottom: 25px;
    }

    .right-box {
        background-color: #f3f3f3;
        height: min-content;
    }

    .image-inline {
        picture {
            display: inline;
        }

        img {
            display: inline;
        }
    }

    .flex {
        flex-wrap: wrap;

        @media all and (max-width: ($screen-m - 1)) {
            flex-direction: column;
        }
    }

    .twelve-link-gill {
        flex-direction: column;
        align-items: center;
    }

    .hr-m-2 {
        hr {
            margin-top: $spacer--medium + 4;
            margin-bottom: $spacer--medium + 4;
        }
    }

    .hide-md {
        @media all and (min-width: ($screen-m)) {
            display: none;
        }
    }
}

body.cms-solar-panels-kyocera-solar-modules {
    .header-cont {
        .col-sm-12 {
            padding: 0 10px;
        }

        h3 {
            font-size: $font-size-medium;
        }
    }

    .product-flexed {
        @media all and (max-width: 950px) {
            flex-wrap: wrap;
        }
    }
}

body.cms-solar-panels-solarworld-solar-panels {
    .header-cont {
        .col-sm-12 {
            padding: 0 10px;
        }

        h3 {
            font-size: $font-size-medium;
        }
    }
}

body.cms-solar-battery-backup-systems-midnite-solar {
    .magnum-dimensions-images {
        display: flex;
        justify-content: center;
        flex-wrap: wrap;

        img {
            margin-left: 0;
            margin-right: 0;
        }
    }
}

body.cms-remote-industrial-solar-industrial {
    .right-sidebar {
        font-weight: normal;
        margin-bottom: 0;
        background-color: #f37321;
        padding: $spacer + 2;
        color: $white;
        line-height: 1.1;
    }

    .sidebar-links {
        list-style-type: none;
        margin: 0;
        padding: 0;
        background-color: #f1f1f1;

        li a {
            display: block;
            color: $black;
            padding: 8px 16px;
            text-decoration: none;
            font-size: $font-size-base;
            border-bottom: 1px solid #ccc;

            &:hover {
                background-color: #555;
                color: white;
            }
        }
    }

    .small-text {
        font-size: $font-size-small;
    }

    .mobile-flex-para {
        strong {
            @media all and (max-width: ($screen-s - 1)) {
                display: flex;
                gap: 5px;
            }
        }
    }
}

body.cms-solar-panels-q-cells-solar-panels,
body.cms-solar-panels-isofoton-solar-modules,
body.cms-solar-panels-perlight-solar-modules {
    .header-cont {
        padding: $spacer--medium - 1;

        .home-sub-header-x {
            font-size: $font-size-super-extra-large + 8;

            @media all and (max-width: ($screen-m - 1)) {
                font-size: $font-size-extra-large + 4;
            }
        }
    }

    .custom-headings {
        strong {
            a {
                font-weight: bold;
            }
        }

        .row {
            margin-left: 0;
            margin-right: 0;
        }

        a {
            display: inline;

            p {
                color: $black;
            }
        }
    }

    .css-bottom-details {
        margin: $spacer--small + 3;
        margin-bottom: 0;

        @media all and (max-width: ($screen-m - 1)) {
            margin-top: $spacer--small + 3;
            margin-bottom: $spacer--semi-medium + 1;
        }

        h3 {
            font-size: $font-size-medium;
            font-weight: bold;
            margin-bottom: $spacer--small + 3;
        }

        .mt-3 {
            margin-top: $spacer--large - 2;
        }

        .mb-3 {
            margin-bottom: $spacer--large - 2;
        }

        p {
            @media all and (max-width: ($screen-m - 1)) {
                margin-top: $spacer + 2;
            }
        }

        hr {
            margin-top: $spacer--medium + 4;
            margin-bottom: $spacer--medium + 4;
        }
    }

    .d-inline {
        display: inline;
    }
}

body.cms-solar-panels-isofoton-solar-modules {
    .css-bottom-details {
        a {
            img {
                display: inline;
            }

            picture {
                display: inline;
            }
        }
    }
}

body.cms-solar-panels-q-cells-solar-panels,
body.cms-solar-panels-lg-solar-modules {
    .container.custom-headings {
        margin: 0;
    }

    .solar-panel {
        .panels-model {
            width: 100%;
            justify-content: center;
        }

        img {
            margin-left: auto;
            margin-right: auto;
            padding-top: $spacer--small + 3;
        }

        .img-caption {
            display: block;
            text-align: center;
            padding-bottom: $spacer + 2;
            padding-top: $spacer--small + 3;
            font-size: $font-size-small + 1;
        }
    }

    h1,
    h2,
    h3 {
        line-height: 1.1;
    }

    .image-inline {
        @media all and (max-width: ($screen-m - 1)) {
            display: flex;
        }

        picture {
            display: inline;
        }

        img {
            display: inline;
        }
    }

    .panel {
        margin-top: $spacer--semi-medium + 1;
    }

    .alpha-solar-panel {
        padding-top: $spacer--medium + 4;
        padding-bottom: $spacer + 2;
        padding-right: 1%;
        padding-left: 1%;
    }

    .panel-models {
        td {
            padding: $spacer--small + 3;
            border: 1px solid #ddd;
            text-align: left;
        }
    }

    .lg-solar-panel {
        background-color: #f3f3f3;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        height: max-content;
        padding: $spacer + 2;
        text-align: center;

        img {
            padding: $spacer + 2;
        }

        p {
            padding: $spacer + 2;
        }
    }

    .black-twelve-link-u {
        flex-direction: column;
    }

    .row-ml-0 {
        .row {
            margin-left: 0;
        }
    }
}

body.cms-solar-panels-q-cells-solar-panels {
    h1.home-sub-header-x {
        font-size: $font-size-super-extra-large + 8;

        @media all and (max-width: ($screen-m - 1)) {
            font-size: $font-size-extra-large + 4;
        }
    }

    h3 {
        font-size: $font-size-extra-large;
        font-weight: normal;
        margin-bottom: $spacer--small + 3;
    }

    .review-box {
        padding-top: $spacer--semi-medium + 6;
        background-color: #f2f2f2;
        margin-top: $spacer--semi-medium + 6;
        padding-left: $spacer--medium - 1;
        padding-right: $spacer--medium - 1;

        @media all and (max-width: (425px)) {
            text-align: center;
        }

        .drop-shadow-image {
            @media all and (max-width: (425px)) {
                margin-left: auto;
                margin-right: auto;
            }
        }
    }

    .hide-md {
        display: block;

        @media all and (min-width: (1280px)) {
            display: none;
        }
    }

    .show-sm {
        display: block;

        @media all and (max-width: (1279px)) {
            display: none;
        }
    }
}

body.cms-commercial-solar-systems-economics {
    .right-sidebar {
        font-weight: normal;
        margin-bottom: 0;
        background-color: #f37321;
        padding: $spacer + 2;
        color: $white;
        line-height: 1.1;
    }

    .sidebar-links {
        list-style-type: none;
        margin: 0;
        padding: 0;
        background-color: #f1f1f1;

        li a {
            display: block;
            color: $black;
            padding: 8px 16px;
            text-decoration: none;
            font-size: $font-size-base;
            border-bottom: 1px solid #ccc;

            &:hover {
                background-color: #555;
                color: white;
                text-decoration: none;
            }
        }
    }

    .black-links {
        a {
            color: $black;
            text-decoration: none;

            &:hover {
                text-decoration: underline;
            }
        }
    }

    .box {
        border-right: 1px solid #f4f4f4;

        @media all and (max-width: 425px) {
            border: none;
        }
    }

    .custom-headings {
        font-size: $font-size-small;

        p {
            margin-bottom: $spacer + 2;
            width: 100%;
        }

        .row {
            margin-left: 0;
            margin-right: 0;
        }
    }

    h1 {
        font-size: $font-size-extra-large + 4;

        @media all and (min-width: ($screen-m)) {
            font-size: $font-size-super-extra-large + 8;
        }
    }

    h2 {
        margin-bottom: $spacer + 2;
        font-size: $font-size-extra-large + 4;

        @media all and (max-width: 425px) {
            font-size: $font-size-extra-large + 1;
        }

        @media all and (min-width: ($screen-m)) {
            font-size: $font-size-super-extra-large - 2;
        }
    }

    h3 {
        font-size: $font-size-extra-large;
        margin-bottom: $spacer + 2;
        line-height: 1.1;
        width: 100%;

        @media all and (max-width: 425px) {
            font-size: $font-size-large + 2;
        }
    }

    h4 {
        font-size: $font-size-large + 1;
        width: 100%;
    }

    table {
        font-size: $font-size-small;
        text-align: left;

        th,
        td {
            padding: $spacer--small + 3;

            &:first-child {
                width: 600px;
            }
        }
    }

    h1,
    h2,
    h3 {
        line-height: 1.1;
    }

    [class*="col-"] {
        padding-left: 1%;
        padding-right: 1%;
    }
}

body.cms-solar-inverter-outback-flexware {
    .no-list-type {
        ol {
            list-style-type: none;
            margin-bottom: $spacer--semi-medium + 1;
            padding: 0;

            li {
                font-size: $font-size-small;
                margin-bottom: $spacer + 2;
            }
        }
    }

    .mobile-only {
        display: none;

        @media all and (max-width: ($screen-m - 1)) {
            display: block;
        }
    }
}

body.cms-solar-inverter-sma-sunny-island-smartformer {
    .large-category-title {
        @media all and (max-width: ($screen-m - 1)) {
            padding-left: $spacer--medium - 1;
            padding-right: $spacer--medium - 1;
        }
    }

    .right-sidebar {
        font-weight: normal;
        margin-bottom: 0;
        background-color: #f37321;
        padding: $spacer + 2;
        color: $white;
        line-height: 1.1;
    }

    .sidebar-links {
        list-style-type: none;
        margin: 0;
        padding: 0;
        background-color: #f1f1f1;

        li a {
            display: block;
            color: $black;
            padding: 8px 16px;
            text-decoration: none;
            font-size: $font-size-base;
            border-bottom: 1px solid #ccc;

            &:hover {
                background-color: #555;
                color: white;
            }
        }
    }

    .slight-larger-h4 {
        font-size: $font-size-large + 1;
    }

    .inverter-manufacturer-data {
        border-color: $gray-dark;
        font-size: $font-size-small;

        th,
        td {
            padding: $spacer--medium - 1;
            border: $border-width-base $border-style-base #ddd;
        }

        .align-text-left {
            text-align: left;
        }
    }
}

body.cms-solar-charge-controllers-morningstar-charge-controllers-morningstar-sunsaver-charge-controllers {
    .image-vertical-center {
        display: flex;
        align-items: center;
    }

    .roof-cont {
        width: 100%;
        border: $border-width-base $border-style-base #ddd;
        padding: 20px 0px;
        margin-bottom: $spacer--medium + 4;

        .roof-mounted {
            list-style: none;
            display: inline-block;
            text-align: center;
            width: 100%;
            margin-bottom: $spacer--medium + 4;

            p {
                margin: 15px 0px 0px 0px;
                width: 100%;

                a {
                    color: $light-orange;
                    text-decoration: none;
                    font-weight: $font-weight-semi-medium;
                }
            }
        }
    }

    .roof-bottom {
        padding: 0px;
        margin-bottom: $spacer--large - 2;
        border: $border-width-base $border-style-base #ddd;
        width: 100%;
        background: $gray-lightest;

        .roof-left {
            margin: 0px;
            padding: 15px 15px;
            text-align: left;
        }

        .roof-right {
            padding: 0px;
            margin: 0px auto;

            a.system-price {
                display: flex;
                flex-direction: column;
                align-items: center;
                margin-bottom: $spacer + 2;

                img {
                    padding: $spacer--medium + 4;
                }

                p {
                    color: $dark-blue;
                    text-decoration: underline;
                    font-family: Segoe, "Segoe UI", "DejaVu Sans",
                        "Trebuchet MS", Verdana, Arial, sans-serif;
                    font-size: $font-size-base;
                    font-weight: $font-weight-bold;
                }
            }
        }
    }
}

body.cms-solar-panel-mounts-prosolar {
    .header-cont {
        h3 {
            @include mq(
                $max-screen: (
                    $screen-s - 1,
                )
            ) {
                font-size: $font-size-medium + 4;
            }
        }
    }

    .featured-cat-box {
        display: flex;
        flex-direction: column;
        align-items: center;

        h3 {
            font-size: $font-size-small;
            font-weight: $font-weight-bold;
            margin-top: $spacer--medium - 1;
            margin-bottom: $spacer--medium - 1;
        }
    }

    .bottom-images {
        p {
            @include mq(
                $max-screen: (
                    $screen-m - 1,
                )
            ) {
                margin-top: $spacer + 2;
                margin-bottom: $spacer + 2;
            }
        }
    }
}

body.cms-remote-industrial-solar-charge-controller {
    .right-sidebar {
        font-weight: normal;
        margin-bottom: $spacer--medium - 1;
        background-color: #f37321;
        padding: $spacer + 2;
        color: $white;
        line-height: 1.1;
    }

    .sidebar-links {
        list-style-type: none;
        margin: 0;
        padding: 0;
        background-color: #f1f1f1;

        li a {
            display: block;
            color: $black;
            padding: 8px 16px;
            text-decoration: none;
            font-size: $font-size-base;
            border-bottom: 1px solid #ccc;

            &:hover {
                background-color: #555;
                color: white;
            }
        }
    }

    .image-with-text {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .product-images-container {
        a {
            strong {
                font-size: $font-size-small;
            }
        }
    }
}

body.cms-solar-panels-trina-solar-modules {
    .orange-links {
        a {
            color: $dark-orange;
            text-decoration: none;

            &:hover {
                text-decoration: underline;
            }
        }
    }

    h3.home-sub-header-x {
        font-weight: $font-weight-semi-medium;
        font-size: $font-size-medium;
        line-height: $font-size-medium + 2;
        color: $darkest-blue;
    }

    h3.h3-shadow {
        text-transform: capitalize;
    }

    p.h3-shadow {
        margin: 1px;
        font-size: $font-size-medium;
        font-weight: normal;
        text-shadow: 1px 1px 1px #b1b1b2;
    }

    .h3-shadow {
        margin-bottom: $spacer--small + 3;
        color: $black;
        font-size: $font-size-medium;
        font-weight: bold;
    }

    .col_right {
        img {
            margin-left: auto;
        }
    }

    .panels-model {
        flex-direction: column;
        align-items: center;
    }

    .cell_gray_grad {
        max-width: 924px;
    }

    .negative-margin {
        margin-left: -15px;
        margin-right: -15px;
    }

    .custom-headings {
        font-size: $font-size-small;

        strong {
            a {
                font-weight: bold;
            }
        }
    }

    a.ten-link {
        text-decoration: underline;
        text-transform: capitalize;
        font-size: $font-size-extra-small;
        text-shadow: 1px 1px 1px #d4d4d4;
    }

    a.system-price {
        font-family: Segoe, "Segoe UI", "DejaVu Sans", "Trebuchet MS", Verdana,
            Arial, sans-serif;
        text-decoration: underline;
        font-size: $font-size-small;
        font-weight: bold;
    }

    .about-panel-manufacturer {
        .cell_gray_grad {
            max-width: 346px;
            float: right;

            @media all and (max-width: 650px) {
                float: none;
            }
        }
    }
}

/* Common CSS with custom classes*/
body.cms-solar-panel-mounts-roof-mounts-quick-mount-pv-qbase,
body.cms-solar-panel-mounts-roof-mounts-quick-mount-pv-classic-shake,
body.cms-solar-panel-mounts-roof-mounts-quick-mount-pv-classic-composition,
body.cms-solar-panel-mounts-roof-mounts-prosolar-tilt-up-kit,
body.cms-solar-panel-mounts-roof-mounts-prosolar-tiletrac,
body.cms-solar-inverter-xantrex-sw,
body.cms-solar-panels-sharp-srs,
body.cms-solar-panels-solarworld-solar-panels,
body.cms-remote-industrial-solar-combiner,
body.cms-solar-panel-mounts-pole-mounts,
body.cms-remote-industrial-solar-raps-pole-mount,
body.cms-remote-industrial-solar-raps,
body.cms-remote-industrial-solar-solar-radiation-jan,
body.cms-remote-industrial-solar-outback-mppt {
    .font-small {
        font-size: $font-size-small;
    }

    .font-base {
        font-size: $font-size-base;
    }

    .common-styles {
        p {
            margin-bottom: $spacer + 2;
        }

        strong {
            a {
                font-weight: bold;
            }
        }
    }

    .row-mx-0 {
        .row {
            margin-left: 0;
            margin-right: 0;
        }
    }

    .col-pad-1 {
        [class*="col-"] {
            padding-left: 1%;
            padding-right: 1%;
        }
    }

    .col-pad-0 {
        [class*="col-"] {
            padding-left: 0;
            padding-right: 0;
        }
    }

    h1.font-40 {
        font-size: $font-size-extra-large + 4;

        @media all and (min-width: ($screen-m)) {
            font-size: $font-size-super-extra-large + 8;
        }
    }

    h2.font-30 {
        margin-bottom: $spacer + 2;
        font-size: $font-size-extra-large + 4;

        @media all and (max-width: 425px) {
            font-size: $font-size-extra-large + 1;
        }

        @media all and (min-width: ($screen-m)) {
            font-size: $font-size-super-extra-large - 2;
        }
    }

    h3.font-24 {
        font-size: $font-size-extra-large;

        @media all and (max-width: 425px) {
            font-size: $font-size-large + 2;
        }
    }

    .hr-m-2 {
        hr {
            margin-top: $spacer--medium + 4;
            margin-bottom: $spacer--medium + 4;
        }
    }

    .header-lh {
        h1,
        h2,
        h3 {
            line-height: 1.1;
        }
    }

    .a-inline {
        a {
            display: inline;
        }
    }

    .d-inline {
        display: inline;
    }

    .box {
        border-right: 1px solid #f4f4f4;

        @media all and (max-width: 425px) {
            border: none;
        }
    }

    .black-links {
        a {
            color: $black;
            text-decoration: none;

            &:hover {
                text-decoration: underline;
            }
        }
    }

    .orange-links {
        a {
            color: $dark-orange;
            text-decoration: none;

            &:hover {
                text-decoration: underline;
            }
        }
    }

    .bottom-details-common {
        .css-bottom-details {
            margin: 15px 15px 25px 15px;

            h3 {
                font-size: $font-size-medium;
                font-weight: bold;
                margin-bottom: $spacer--small + 3;

                @media all and (max-width: ($screen-m - 1)) {
                    margin: 15px 0;
                }
            }

            h4 {
                font-size: $font-size-base;
                font-weight: bold;
                margin: 25px 0px 10px;
                color: $darkest-blue;

                a {
                    font-weight: bold;
                }
            }
        }
    }

    .right-sidebar {
        font-weight: normal;
        margin-bottom: 0;
        background-color: #f37321;
        padding: $spacer + 2;
        color: $white;
        line-height: 1.1;
    }

    .sidebar-links {
        list-style-type: none;
        margin: 0;
        padding: 0;
        background-color: #f1f1f1;

        li a {
            display: block;
            color: $black;
            padding: 8px 16px;
            text-decoration: none;
            font-size: $font-size-base;
            border-bottom: 1px solid #ccc;

            &:hover {
                background-color: #555;
                color: white;
                text-decoration: none;
            }
        }
    }

    .blue-price-links {
        a.system-price {
            color: $dark-blue;
            text-decoration: underline;
            font-family: Segoe, "Segoe UI", "DejaVu Sans", "Trebuchet MS",
                Verdana, Arial, sans-serif;
            font-size: $font-size-small;
            font-weight: bold;
            margin-bottom: $spacer + 2;
        }
    }
}

body.cms-remote-industrial-solar-raps {
    .left-mainn {
        border: 1px solid #ddd;
        padding: $spacer--medium + 4;
        margin-bottom: $spacer--medium + 4;

        .left-contt {
            h3 {
                margin: 0;
                margin-bottom: $spacer + 2;
            }

            h4 {
                margin: 0;
                margin-bottom: $spacer + 2;

                a {
                    &:hover {
                        margin: 0;
                        text-decoration: underline;
                    }
                }
            }

            p {
                a {
                    color: #f2871d;
                    text-decoration: none;
                    font-weight: $font-weight-semi-medium;

                    &:hover {
                        color: $dark-blue;
                    }
                }
            }

            picture {
                padding: 0;

                img {
                    padding: 0;
                }
            }
        }
    }
}

body.cms-remote-industrial-solar-raps-pole-mount {
    span {
        img {
            display: inline;
        }
    }

    .col-pad-0 {
        .flex {
            @media all and (max-width: 560px) {
                display: block;
            }
        }
    }
}

body.cms-solar-batteries-deka-solar-batteries {
    .black-links {
        a {
            color: $black;
            text-decoration: none;

            &:hover {
                text-decoration: underline;
            }
        }
    }

    .review-box {
        padding-top: $spacer--semi-medium + 6;
        background-color: #f2f2f2;
        margin-top: $spacer--semi-medium + 6;
        padding-left: $spacer--medium - 1;
        padding-right: $spacer--medium - 1;

        .text-left {
            font-size: $font-size-small;
        }

        .text-right {
            font-size: $font-size-small;
        }

        @media all and (max-width: (425px)) {
            text-align: center;
        }

        .off-grid-solar {
            @media all and (max-width: (425px)) {
                margin-left: auto;
                margin-right: auto;
            }
        }
    }

    .cms-page__content {
        .container {
            .row {
                margin-left: 0;
                margin-right: 0;
            }

            .grid12-12 {
                h3 {
                    font-size: $font-size-extra-large;
                    font-weight: $font-weight-normal;
                    line-height: 1.1;

                    @media all and (max-width: (425px)) {
                        font-size: $font-size-large + 2;
                    }
                }

                a.system-price {
                    color: $dark-blue;
                    text-decoration: underline;
                    font-family: Segoe, "Segoe UI", "DejaVu Sans",
                        "Trebuchet MS", Verdana, Arial, sans-serif;
                    font-size: $font-size-small;
                    font-weight: bold;
                    margin-bottom: $spacer + 2;
                    flex-direction: column;
                    align-items: center;
                }

                a.system-price:hover {
                    color: $black;
                }

                .grid12-6 {
                    h1 {
                        font-size: $font-size-super-extra-large + 8;
                        font-weight: $font-weight-normal;
                        font-style: normal;
                        line-height: 44px;
                        margin-top: 0;
                        margin-bottom: $spacer--medium + 4;

                        @media all and (max-width: (425px)) {
                            font-size: $font-size-extra-large + 4;
                        }
                    }
                }

                .row.article-content {
                    margin: 0px;

                    .grid12-6 {
                        h4 {
                            font-size: 19px;
                            font-weight: $font-weight-normal;
                            font-style: normal;
                            line-height: 1.1;
                            margin-bottom: $spacer + 2;
                        }
                    }
                }

                &.pl-1.mt-2.mb-2.text-center {
                    .grid12-1 {
                        font-size: 12px;
                    }
                }

                .grid12-8 {
                    .ten-font {
                        font-size: 12px;

                        a.ten-link {
                            color: $darker-blue;
                            text-decoration: underline;
                            text-transform: capitalize;
                            font-size: $font-size-extra-small;
                            text-shadow: 1px 1px 1px #d4d4d4;
                        }
                    }

                    ul {
                        padding: 0;
                    }
                }

                .solar-table {
                    font-size: $font-size-small;

                    td {
                        border: 1px solid #ddd;
                    }
                }

                &.mt-3 {
                    .rpi {
                        margin-left: $spacer + 2;
                        background-color: #f3f3f3;
                        margin-top: $spacer--medium + 4;
                        display: inline-block;
                        padding: 5px;
                    }
                }

                &.review-box {
                    align-items: center;
                }

                .grid12-2 {
                    a {
                        text-decoration-line: none;
                    }
                }

                .eleven-link,
                .twelve-link {
                    text-decoration: none;
                    color: $black;
                }

                .eleven-link:hover,
                .twelve-link:hover {
                    text-decoration: underline;
                    color: $black;
                }
            }
        }
    }
}

body.cms-solar-batteries-rolls-batteries {
    .cms-page__content {
        .container {
            hr {
                border: 0;
                border-top: 1px solid #d1d1d1;
                margin-bottom: $spacer--medium + 4;
                margin-top: $spacer--medium + 4;
            }

            .header-cont {
                h1.home-sub-header-x {
                    font-size: $font-size-super-extra-large + 8;

                    @media all and (max-width: (425px)) {
                        font-size: $font-size-extra-large + 4;
                    }
                }
            }

            .css-bottom-details {
                .col-md-8.col-sm-12 {
                    padding-left: $spacer--medium - 1;
                    padding-right: $spacer--medium - 1;

                    strong a {
                        text-decoration-line: none;
                        font-weight: bold;
                        color: $black;
                    }

                    strong a:hover {
                        text-decoration-line: underline;
                    }

                    a {
                        text-decoration-line: none;
                        color: $black;
                    }
                }

                .mt-3.mb-3 {
                    h3 {
                        font-size: $font-size-medium;
                    }
                }

                .col-sm-12.text-center {
                    h3 {
                        font-size: $font-size-medium;
                        font-weight: bold;
                        margin-bottom: $spacer--medium - 1;
                        font-family: inherit;
                    }
                }
            }
        }
    }
}

body.cms-solar-batteries-extreme-solar-agm-sealed-battery {
    .cms-page__content {
        .container {
            hr {
                border: 0;
                border-top: 1px solid #d1d1d1;
                margin-bottom: $spacer--medium + 4;
                margin-top: $spacer--medium + 4;
            }

            .header-cont {
                h1.home-sub-header-x {
                    font-size: $font-size-super-extra-large + 8;

                    @media all and (max-width: (425px)) {
                        font-size: $font-size-extra-large + 4;
                    }
                }
            }

            .css-bottom-details {
                a {
                    color: $black;
                    font-size: $font-size-medium;
                    font-weight: bold;
                }

                h3 {
                    font-size: $font-size-medium;
                    font-weight: bold;
                }

                .table-responsive {
                    table tr td {
                        width: initial;
                    }
                }
            }
        }
    }
}

body.cms-solar-panels-vls-value-line-solar {
    .cms-page__content {
        .container {
            hr {
                border: 0;
                border-top: 1px solid #d1d1d1;
                margin-bottom: $spacer--medium + 4;
                margin-top: $spacer--medium + 4;
            }

            .header-cont {
                h1.home-sub-header-x {
                    font-size: $font-size-super-extra-large + 8;
                    line-height: 1.1;
                    font-weight: $font-weight-normal;

                    @media all and (max-width: (425px)) {
                        font-size: $font-size-extra-large + 4;
                    }
                }
            }

            .css-bottom-details {
                h3 {
                    font-size: $font-size-medium;
                    font-weight: bold;
                }

                h3.right-sidebar {
                    font-size: $font-size-large;
                    font-weight: bold;
                    margin-bottom: $spacer--medium - 1;
                    background-color: #f37321;
                    padding: $spacer + 2;
                    color: $white;
                }

                .sidebar-links {
                    list-style: none;
                    padding: 0px;

                    li {
                        margin-bottom: $spacer + 2;
                    }

                    a {
                        display: block;
                        color: $black;
                        padding: 8px 16px;
                        text-decoration: none;
                        border-bottom: 1px solid #ccc;
                    }

                    a:hover {
                        background-color: #555;
                        color: $white;
                    }
                }
            }

            .table-responsive .solar-table {
                th {
                    vertical-align: bottom;
                    text-align: left;
                }

                td {
                    vertical-align: top;
                    text-align: left;
                }
            }

            .col-sm-12.text-center {
                h3 strong {
                    font-size: $font-size-extra-large;
                    line-height: 1.1;

                    @media all and (max-width: (425px)) {
                        font-size: $font-size-large + 2;
                    }
                }
            }
        }
    }
}

body.cms-solar-panels-shell-shell-solar {
    .cms-page__content {
        .container {
            .css-bottom-details {
                h3 {
                    font-size: $font-size-medium;
                    font-weight: bold;
                }

                .grid12-12 {
                    h1 {
                        font-size: $font-size-super-extra-large + 8;
                        line-height: 1.1;
                        font-weight: $font-weight-normal;

                        @media all and (max-width: (425px)) {
                            font-size: $font-size-extra-large + 4;
                        }
                    }

                    .roof-cont {
                        width: 100%;
                        border: 1px solid #ddd;
                        padding: 20px 0px;
                        margin: 0 0 20px 0;

                        .availableb {
                            background-color: #fecc67;
                            padding: $spacer--medium + 4;
                            margin: 0px 0px 20px 0px;

                            h4 {
                                line-height: 1.1;
                                font-weight: bold;
                                color: $darkest-blue;
                                margin: 0 0 10px 0;
                            }

                            p strong a {
                                font-weight: bold;
                                color: $black;
                                text-decoration-line: none;
                            }

                            p strong a:hover {
                                text-decoration-line: underline;
                            }

                            p a {
                                color: $black;
                                text-decoration-line: none;
                            }

                            p a:hover {
                                text-decoration-line: underline;
                            }
                        }
                    }

                    .table-responsive {
                        table td {
                            width: initial;

                            strong a {
                                color: $black;
                                font-weight: bold;
                            }

                            p a {
                                color: $black;
                                text-decoration-line: none;
                            }

                            p a:hover {
                                text-decoration-line: underline;
                            }
                        }
                    }

                    h4 {
                        font-size: $font-size-base;
                        font-weight: bold;
                        margin: 25px 0px 10px;
                        color: $darkest-blue;
                    }
                }
            }
        }
    }
}

body.cms-solar-batteries-batteries {
    .cms-page__content {
        .container {
            hr {
                border: 0;
                border-top: 1px solid #d1d1d1;
                margin-bottom: $spacer--medium + 4;
                margin-top: $spacer--medium + 4;
            }

            .header-cont {
                h1.home-sub-header-x {
                    font-size: $font-size-super-extra-large + 8;

                    @media all and (max-width: (425px)) {
                        font-size: $font-size-extra-large + 4;
                    }
                }
            }

            .css-bottom-details {
                .col-sm-12 {
                    padding-left: $spacer--medium - 1;
                    padding-right: $spacer--medium - 1;

                    strong a {
                        text-decoration-line: none;
                        font-weight: bold;
                        color: $black;
                    }

                    strong a:hover {
                        text-decoration-line: underline;
                    }

                    a {
                        text-decoration-line: none;
                        color: $black;
                    }

                    a:hover {
                        text-decoration-line: underline;
                    }

                    table.solar-table a {
                        color: $darker-blue;
                        text-decoration: underline;
                        font-family: inherit;
                        font-size: $font-size-small;
                        font-weight: bold;
                    }
                }

                h3 {
                    font-size: $font-size-medium;
                }

                .text-center {
                    h3 {
                        font-size: $font-size-medium;
                        font-weight: bold;
                        margin-bottom: $spacer--medium - 1;
                        font-family: inherit;
                    }
                }
            }
        }
    }
}

body.cms-solar-batteries-flooded-battery {
    .cms-page__content {
        .container {
            hr {
                border: 0;
                border-top: 1px solid #d1d1d1;
                margin-bottom: $spacer--medium + 4;
                margin-top: $spacer--medium + 4;
            }

            .header-cont {
                h1.home-sub-header-x {
                    font-size: $font-size-super-extra-large + 8;
                    line-height: 1.1;
                    font-weight: $font-weight-normal;

                    @media all and (max-width: (425px)) {
                        font-size: $font-size-extra-large + 4;
                    }
                }
            }

            .css-bottom-details {
                h3 {
                    font-size: $font-size-medium;
                    font-weight: bold;
                }

                h3.right-sidebar {
                    font-size: $font-size-medium;
                    font-weight: bold;
                    line-height: 1.1;
                    margin-bottom: $spacer--medium - 1;
                    background-color: #f37321;
                    padding: $spacer + 2;
                    color: $white;
                }

                .sidebar-links {
                    list-style: none;
                    padding: 0px;
                    margin: 0px;
                    background-color: #f1f1f1;

                    li {
                        margin-bottom: $spacer + 2;
                    }

                    a {
                        display: block;
                        color: $black;
                        padding: 8px 16px;
                        text-decoration: none;
                        font-size: $font-size-base;
                        border-bottom: 1px solid #ccc;
                    }

                    a:hover {
                        background-color: #555;
                        color: $white;
                    }
                }

                .col-md-8.col-sm-12 {
                    p a {
                        text-decoration-line: none;
                        color: $black;
                    }

                    p a:hover {
                        text-decoration-line: underline;
                    }
                }
            }

            .table-responsive .solar-table {
                td {
                    vertical-align: top;
                    text-align: left;
                }
            }
        }
    }
}

body.cms-remote-industrial-solar-infographic {
    .cms-page__content {
        .container {
            .image,
            img,
            picture {
                width: 100%;
            }
        }
    }
}

body.cms-solar-panels-samsung-solar-modules {
    .cms-page__content {
        .container {
            h1 {
                font-size: $font-size-super-extra-large + 8;
                line-height: 1.1;
                font-weight: $font-weight-normal;

                @media all and (max-width: (425px)) {
                    font-size: $font-size-extra-large + 4;
                }
            }

            h2 {
                font-size: $font-size-extra-large + 6;
                line-height: 1.1;
                font-weight: $font-weight-normal;

                @media all and (max-width: (425px)) {
                    font-size: $font-size-extra-large + 1;
                }
            }

            ul {
                padding-left: 0px;
            }

            .solarIntro picture {
                display: inline-block;
            }

            .solarIntro div {
                display: inline-block;
            }
        }
    }
}

body.cms-solar-panel-mounts-ground-mounts-schletter {
    .cms-page__content {
        .container {
            h1 {
                font-size: $font-size-super-extra-large + 8;
                line-height: 1.1;
                font-weight: $font-weight-normal;

                @media all and (max-width: (425px)) {
                    font-size: $font-size-extra-large + 4;
                }
            }

            strong {
                font-size: $font-size-small;
            }

            table td {
                width: initial;
                font-size: $font-size-small;

                ul {
                    padding-left: 0px;
                }
            }

            td a {
                color: $black;
                font-size: $font-size-small;
                text-decoration-line: none;
            }

            td a:hover {
                text-decoration-line: underline;
            }
        }
    }
}

body.cms-solar-inverter-solaredge-optimizer {
    .black-links {
        a {
            color: $black;
            text-decoration: none;

            &:hover {
                text-decoration: underline;
            }
        }
    }

    .cms-page__content {
        .container {
            .header-cont {
                h1.home-sub-header-x {
                    font-size: $font-size-super-extra-large + 8;
                    line-height: 1.1;
                    font-weight: $font-weight-normal;

                    @media all and (max-width: (425px)) {
                        font-size: $font-size-extra-large + 4;
                    }
                }

                h3.home-sub-header-x {
                    font-size: $font-size-medium;
                    font-weight: 600;
                }

                picture {
                    @media all and (max-width: ($screen-l - 1)) {
                        display: none;
                    }

                    img {
                        margin-left: auto;
                    }
                }
            }

            .css-bottom-details {
                .flex.center.text-center {
                    @media all and (max-width: (425px)) {
                        flex-direction: column;
                    }
                }

                h3 {
                    font-size: $font-size-medium;
                    font-weight: bold;
                }

                .col-md-8.col-sm-12 {
                    p a {
                        text-decoration-line: none;
                        color: $black;
                    }

                    p a:hover {
                        text-decoration-line: underline;
                    }
                }

                .f-above-img-sec {
                    width: 96%;
                    margin-top: 22px;
                    border-radius: 4px;
                    margin-left: auto;
                    background-image: url(/media/product-images/remote-industrial-solar/remote-outdoor-solar-systems/remote-off-grid-solar-power-ground-2.png);
                    background-repeat: no-repeat;
                    background-position: left bottom;
                    background-color: #f0f0f0;

                    .col-md-4.col-sm-12 {
                        font-size: $font-size-small;

                        a {
                            display: inline;
                        }
                    }
                }
            }

            .table-responsive .solar-table {
                td {
                    vertical-align: top;
                    text-align: left;
                }

                a {
                    color: $darker-blue;
                    text-decoration: underline;
                    font-family: inherit;
                    font-size: $font-size-small;
                    font-weight: bold;
                }
            }

            strong a {
                color: $black;
                text-decoration: none;
                font-weight: bold;

                &:hover {
                    text-decoration: underline;
                }
            }
        }
    }
}

body.cms-solar-inverter-fronius-cl-inverters {
    .black-links {
        a {
            color: $black;
            text-decoration: none;

            &:hover {
                text-decoration: underline;
            }
        }
    }

    .cms-page__content {
        .container {
            font-size: $font-size-small;

            h1 {
                font-size: $font-size-super-extra-large + 8;
                line-height: 1.1;
                font-weight: $font-weight-normal;

                @media all and (max-width: (425px)) {
                    font-size: $font-size-extra-large + 4;
                }
            }

            h3 {
                font-size: $font-size-medium;
            }

            hr {
                border: 0;
                border-top: 1px solid #d1d1d1;
                margin-bottom: $spacer--medium + 4;
                margin-top: $spacer--medium + 4;
            }

            .css-bottom-details {
                .flex.center {
                    a {
                        flex-direction: column;
                    }

                    @media all and (max-width: (425px)) {
                        flex-direction: column;
                    }
                }

                .radial {
                    background: radial-gradient(
                        ellipse at center,
                        rgba(255, 255, 255, 0.01) 50%,
                        rgba(125, 185, 232, 0.16) 100%
                    );
                }

                .table-responsive {
                    table td {
                        width: initial;
                    }
                }
            }
        }
    }
}

body.cms-solar-panels-silevo-solar-panels {
    .black-links {
        a {
            color: $black;
            text-decoration: none;

            &:hover {
                text-decoration: underline;
            }
        }
    }

    .cms-page__content {
        .container {
            h1 {
                font-size: $font-size-super-extra-large + 8;
                line-height: 1.1;
                font-weight: $font-weight-normal;

                @media all and (max-width: (425px)) {
                    font-size: $font-size-extra-large + 4;
                }
            }

            h4 {
                font-size: $font-size-base;
                font-weight: bold;
                margin: 25px 0px 10px;
                color: $darkest-blue;
            }

            .css-bottom-details {
                h3 strong {
                    font-size: $font-size-medium;
                }
            }
        }
    }
}

body.cms-solar-inverter-kaco {
    .black-links {
        a {
            color: $black;
            text-decoration: none;

            &:hover {
                text-decoration: underline;
            }
        }
    }

    .cms-page__content {
        .container {
            h1 {
                font-size: $font-size-super-extra-large + 8;
                line-height: 1.1;
                font-weight: $font-weight-normal;

                @media all and (max-width: (425px)) {
                    font-size: $font-size-extra-large + 4;
                }
            }

            h2 {
                font-size: $font-size-super-extra-large - 2;
                line-height: 1.1;
                font-weight: $font-weight-normal;

                @media all and (max-width: (425px)) {
                    font-size: $font-size-extra-large + 1;
                }
            }

            h3 {
                font-size: $font-size-medium;
                font-weight: bold;

                a {
                    font-weight: bold;
                }
            }

            hr {
                border: 0;
                border-top: 1px solid #d1d1d1;
                margin-bottom: $spacer--medium + 4;
                margin-top: $spacer--medium + 4;
            }
        }
    }
}

body.cms-solar-panels-canadian-solar-modules {
    .header-cont h3 {
        font-size: $font-size-medium;
    }

    .p-2 {
        a {
            display: flex;
            flex-direction: column;
        }
    }

    .system-price {
        font-weight: bold;
        color: $dark-blue;
    }

    .hr-margin {
        margin-bottom: $spacer--semi-medium - 4;
    }

    @media (max-width: $screen-s) {
        .flex {
            flex-direction: column;
            align-items: center;
        }
    }
}

body.cms-remote-industrial-solar-power-panels {
    .dark-black-links {
        font-size: 12px;
    }

    .page-title {
        h1 {
            font-size: $spacer--semi-large;
        }
    }

    .right-sidebar {
        font-size: $font-size-extra-large;
        font-weight: normal;
        margin-bottom: 0;
        background-color: #f37321;
        padding: $spacer + 2;
        color: $white;
        line-height: 1.1;
    }

    .sidebar-links {
        list-style-type: none;
        margin: 0;
        padding: 0;
        background-color: #f1f1f1;

        li a {
            display: block;
            color: $black;
            padding: 8px 16px;
            text-decoration: none;
            font-size: $font-size-base;
            border-bottom: 1px solid #ccc;

            &:hover {
                background-color: #555;
                color: white;
            }
        }
    }

    .display-block {
        display: block;
    }

    .link-text {
        display: block;
        margin-top: $spacer--semi-large + 2;
    }

    .p-top {
        padding-top: $spacer--semi-large + 5;
    }

    @media (max-width: $screen-s) {
        .p-top {
            display: flex;
            justify-content: end;
            padding-top: 0px;
        }

        .page-title {
            h1 {
                font-size: 24px + 4;
            }
        }

        .small-img-on-mobile {
            max-width: 230px;
        }
    }
}

body.cms-solar-panels-shell-systems {
    .page-head {
        font-size: $font-size-super-extra-large + 8;
    }

    .sub-head {
        font-size: $font-size-medium;
        font-weight: bold;
    }

    .roof-cont {
        border: 1px solid #ddd;
        padding: $spacer + 2;
    }

    .black-links {
        color: $black;
        text-decoration: none;

        &:hover {
            text-decoration: underline;
        }
    }

    .flex-img {
        display: flex;
        flex-direction: row;
    }

    @media (max-width: $screen-s) {
        .page-head {
            font-size: $font-size-extra-large + 4;
        }
    }
}

body.cms-solar-charge-controllers-solar-boost {
    .dark-black-links {
        color: $black;
        text-decoration: none;
        font-weight: $font-weight-bold;

        &:hover {
            text-decoration: underline;
            color: $black;
        }
    }

    .black-links {
        color: $black;
        text-decoration: none;

        &:hover {
            text-decoration: underline;
        }
    }

    .page-head {
        font-size: $font-size-super-extra-large + 8;
    }

    .fw-16-bold {
        font-weight: bold;
        font-size: $font-size-medium;
    }

    .fw-14-bold {
        font-weight: bold;
        font-size: $font-size-base;
    }

    .roof-cont {
        border: 1px solid #ddd;
        padding: $spacer--medium + 4;
        margin-bottom: $spacer--medium + 2;
    }

    .margin-adjust {
        margin: -$spacer;
        margin-bottom: $spacer--small + 3;
        margin-top: $spacer--small + 3;
    }

    @media (max-width: $screen-s) {
        .page-head {
            font-size: $font-size-extra-large + 4;
        }
    }
}

body.cms-remote-industrial-solar-sunlight {
    .home-sub-header-x {
        font-size: $font-size-super-extra-large + 8;
    }

    .right-sidebar {
        font-size: $font-size-medium;
        font-weight: normal;
        margin-bottom: 0;
        background-color: #f37321;
        padding: $spacer + 2;
        color: $white;
        line-height: 1.1;
    }

    .sidebar-links {
        list-style-type: none;
        margin: 0;
        padding: 0;
        background-color: #f1f1f1;

        li a {
            display: block;
            color: $black;
            padding: 8px 16px;
            text-decoration: none;
            font-size: $font-size-base;
            border-bottom: 1px solid #ccc;

            &:hover {
                background-color: #555;
                color: white;
            }
        }
    }

    @media (max-width: $screen-s) {
        .home-sub-header-x {
            font-size: $font-size-extra-large + 4;
        }
    }
}

body.cms-remote-industrial-solar-xantrex {
    .home-sub-header-x {
        font-size: $font-size-super-extra-large + 8;
    }

    .img-resize {
        width: 100px;
    }

    @media (max-width: $screen-s) {
        .home-sub-header-x {
            font-size: $font-size-extra-large + 4;
        }
    }
}

body.cms-solar-panel-mounts-pole-mounts-dpw-top-of-pole {
    .home-sub-header-x {
        font-size: $font-size-super-extra-large + 8;
    }

    .right-sidebar {
        font-size: $font-size-medium;
        font-weight: normal;
        margin-bottom: 0;
        background-color: #f37321;
        padding: $spacer + 2;
        color: $white;
        line-height: 1.1;
    }

    .sidebar-links {
        list-style-type: none;
        margin: 0;
        padding: 0;

        li a {
            display: block;
            color: $black;
            padding: 8px 16px;
            text-decoration: none;
            font-size: $font-size-base;
            border-bottom: 1px solid #ccc;

            &:hover {
                background-color: #555;
                color: white;
            }
        }
    }

    .sub-head {
        font-size: $font-size-super-extra-large - 2;
    }

    .fw-16-bold {
        font-weight: bold;
        font-size: $font-size-medium;
    }

    .black-links {
        a {
            color: $black;
            text-decoration: none;

            &:hover {
                text-decoration: underline;
            }
        }
    }

    .custom-border-right {
        border-right: 1px solid #f4f4f4;
    }

    @media (max-width: $screen-s) {
        .home-sub-header-x {
            font-size: $font-size-extra-large + 4;
        }

        .sub-head {
            font-size: $font-size-extra-large + 1;
        }
    }
}

body.cms-solar-panel-mounts-roof-mounts-prosolar-fastjack {
    .dark-black-links {
        font-weight: $font-weight-base;
        padding-top: $spacer--medium - 1;
        padding-bottom: $spacer--medium - 1;
    }

    .black-links {
        color: $black;
        text-decoration: none;

        &:hover {
            text-decoration: underline;
        }
    }

    .header-cont {
        display: flex;
        flex-direction: row-reverse;
        justify-content: space-between;
    }

    .custom-flex {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .border-gray-thick {
        border: 2px solid #494848;
        border-radius: 10px;
    }

    .custom-flex-center {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .f-above-img-sec {
        margin-top: $spacer--semi-medium - 2;
        border-radius: 4px;
        background-image: url(/media/product-images/remote-industrial-solar/remote-outdoor-solar-systems/remote-off-grid-solar-power-ground-2.png);
        background-repeat: no-repeat;
        background-position: left bottom;
        background-color: #f0f0f0;
    }

    .fw-16-bold {
        font-weight: bold;
        font-size: $font-size-medium;
    }

    .dark-blue-links {
        color: #000099;
        font-weight: bold;
    }

    .dark-blue-links:hover {
        color: #333;
        text-decoration: underline;
    }

    .img-max-content {
        width: max-content;
    }

    @media (max-width: $screen-m) {
        .pro-solar-img {
            display: none;
        }
    }

    @media (max-width: $screen-s) {
        .home-sub-header-x {
            font-size: $font-size-extra-large + 4;
        }

        .sub-head {
            font-size: $font-size-extra-large - 4;
        }

        .flex {
            flex-direction: column;
        }

        .featured-cat-box {
            margin-bottom: $spacer--medium + 4;
        }
    }
}

body.cms-solar-panel-mounts-roof-mounts-prosolar-foam-jack {
    .right-sidebar {
        font-size: $font-size-medium;
        font-weight: normal;
        margin-bottom: 0;
        background-color: #f37321;
        padding: $spacer + 2;
        color: $white;
        line-height: 1.1;
    }

    .sidebar-links {
        list-style-type: none;
        margin: 0;
        padding: 0;

        li a {
            display: block;
            color: $black;
            padding: 8px 16px;
            text-decoration: none;
            font-size: $font-size-base;
            border-bottom: 1px solid #ccc;

            &:hover {
                background-color: #555;
                color: white;
            }
        }
    }

    .fw-16-bold {
        font-weight: bold;
        font-size: $font-size-medium;
    }

    .box {
        border-right: 1px solid #f4f4f4;

        @media all and (max-width: 425px) {
            border: none;
        }
    }
}

body.cms-solar-panel-mounts-roof-mounts-prosolar-rail-splice-kit {
    .right-sidebar {
        font-size: $font-size-medium;
        font-weight: normal;
        margin-bottom: 0;
        background-color: #f37321;
        padding: $spacer + 2;
        color: $white;
        line-height: 1.1;
    }

    .sidebar-links {
        list-style-type: none;
        margin: 0;
        padding: 0;

        li a {
            display: block;
            color: $black;
            padding: 8px 16px;
            text-decoration: none;
            font-size: $font-size-base;
            border-bottom: 1px solid #ccc;

            &:hover {
                background-color: #555;
                color: white;
            }
        }
    }

    .home-sub-header-x {
        font-size: $font-size-super-extra-large + 8;
    }

    .box {
        border-right: 1px solid #f4f4f4;

        @media all and (max-width: 425px) {
            border: none;
        }
    }

    .black-links {
        color: $black;
        text-decoration: none;

        &:hover {
            text-decoration: underline;
        }
    }

    @media (max-width: $screen-s) {
        .home-sub-header-x {
            font-size: $font-size-extra-large + 4;
        }
    }
}

body.cms-solar-panel-mounts-roof-mounts-prosolar-solar-wedge {
    .right-sidebar {
        font-size: $font-size-medium;
        font-weight: normal;
        margin-bottom: 0;
        background-color: #f37321;
        padding: $spacer + 2;
        color: $white;
        line-height: 1.1;
    }

    .sidebar-links {
        list-style-type: none;
        margin: 0;
        padding: 0;

        li a {
            display: block;
            color: $black;
            padding: 8px 16px;
            text-decoration: none;
            font-size: $font-size-base;
            border-bottom: 1px solid #ccc;

            &:hover {
                background-color: #555;
                color: white;
            }
        }
    }

    .box {
        border-right: 1px solid #f4f4f4;

        @media all and (max-width: 425px) {
            border: none;
        }
    }

    .black-links {
        color: $black;
        text-decoration: none;

        &:hover {
            text-decoration: underline;
        }
    }

    .fw-12-bold {
        font-size: $font-size-small;
        font-weight: bold;
    }

    .pdf-display {
        display: flex;
        gap: 5px;
    }
}

body.cms-solar-panel-mounts-pole-mounts {
    .css-bottom-details {
        .text-center {
            a {
                flex-direction: column;
                align-items: center;
            }
        }
    }

    .sidebar-links {
        background-color: transparent;

        li.item {
            margin-bottom: $spacer + 2;
        }
    }

    .eleven-link {
        display: inline;
    }

    .img-h-auto {
        img {
            height: auto;
        }
    }
}

/* cms-solar-panels-sharp-srs */
body.cms-solar-panels-sharp-srs {
    h1.heading--page {
        display: none;
    }

    #maincontent {
        margin-top: 0;
    }
}

body.cms-solar-panel-mounts-roof-mounts-quick-mount-pv-classic-conduit-mount {
    .right-sidebar {
        font-size: $font-size-medium;
        font-weight: normal;
        margin-bottom: 0;
        background-color: #f37321;
        padding: $spacer + 2;
        color: $white;
        line-height: 1.1;
    }

    .sidebar-links {
        list-style-type: none;
        margin: 0;
        padding: 0;
        background-color: $white;

        li {
            margin-bottom: $spacer + 2;
        }

        li a {
            display: block;
            color: $black;
            padding: $spacer 16px;
            text-decoration: none;
            font-size: $font-size-base;
            border-bottom: $border-width-base $border-style-base #ccc;

            &:hover {
                background-color: #555;
                color: $white;
            }
        }
    }

    .desktop-visible {
        display: none;

        @media all and (min-width: ($screen-xl)) {
            display: block;
        }
    }

    .tab-mobile-visible {
        display: none;

        @media all and (max-width: ($screen-xl - 1)) {
            display: block;
        }
    }
    .pdf-box.last-pdf-box {
        @media all and (max-width: 550px) {
            width: 100%;
        }
    }
}

body.cms-solar-panel-mounts-roof-mounts-quick-mount-pv {
    .header-cont {
        h3.large-h3 {
            @media all and (max-width: ($screen-s - 1)) {
                font-size: $font-size-medium + 4;
            }
        }
    }

    .featured-cat-box {
        display: flex;
        flex-direction: column;
        align-items: center;

        > p {
            margin-bottom: 0;
        }

        .featured-desc {
            display: flex;
            flex-direction: column;
            align-items: center;

            p {
                margin-bottom: $spacer + 2;
            }

            ol {
                list-style: none;
                padding: 0;
                margin: 0;

                li {
                    font-size: $font-size-small;
                    margin-bottom: $spacer + 2;
                    text-align: center;
                }
            }
        }
    }
}

body.cms-solar-inverter-schneider-electric-conext {
    .black-links {
        a {
            color: $black;
            text-decoration: none;

            &:hover {
                text-decoration: underline;
            }
        }
    }

    .cms-page__content {
        .container {
            .header-cont {
                h1.home-title-header-x {
                    font-size: $font-size-extra-large;
                    line-height: 10px;
                    font-weight: 800;

                    @media all and (max-width: (992px)) {
                        font-size: $font-size-extra-large;
                        line-height: 30px;
                        font-weight: 800;
                    }
                }

                h3 strong {
                    font-size: $font-size-extra-large;
                    font-weight: 700;
                    line-height: 1.1;
                }

                picture {
                    @media all and (max-width: ($screen-l - 1)) {
                        display: none;
                    }

                    img {
                        margin-left: auto;
                    }
                }
            }

            .css-bottom-details {
                .col-sm-12 {
                    a {
                        flex-direction: column;
                        align-items: center;
                    }
                }

                h3 strong {
                    font-size: $font-size-medium;
                }

                .f-above-img-sec {
                    margin-top: $spacer--semi-medium - 2;
                    border-radius: 4px;
                    background-image: url(/media/product-images/remote-industrial-solar/remote-outdoor-solar-systems/remote-off-grid-solar-power-ground-2.png);
                    background-repeat: no-repeat;
                    background-position: left bottom;
                    background-color: #f0f0f0;
                }

                ul {
                    padding-left: 0px;
                }
            }

            .table-responsive .solar-table {
                a {
                    color: $darker-blue;
                    text-decoration: underline;
                    font-family: inherit;
                    font-size: $font-size-small;
                    font-weight: bold;
                }
            }
        }
    }
}

body.cms-solar-batteries-concorde-sun-xtender-agm-solar-batteries {
    .black-links {
        a {
            color: $black;
            text-decoration: none;

            &:hover {
                text-decoration: underline;
            }
        }
    }

    .cms-page__content {
        .container {
            hr {
                border: 0;
                border-top: 1px solid #d1d1d1;
                margin-bottom: $spacer--medium + 4;
                margin-top: $spacer--medium + 4;
            }

            .header-cont {
                h1.home-sub-header-x {
                    font-size: $font-size-super-extra-large + 8;
                    line-height: 1.1;
                    font-weight: $font-weight-normal;

                    @media all and (max-width: (425px)) {
                        font-size: $font-size-extra-large + 4;
                    }
                }
            }

            .css-bottom-details {
                .col-sm-12 {
                    a {
                        flex-direction: column;
                        align-items: center;
                    }
                }

                .border-gray-thick {
                    border: 2px solid #494848;
                    border-radius: 10px;
                }

                h3 {
                    font-size: $font-size-medium;
                    font-weight: bold;

                    @media all and (max-width: (767px)) {
                        margin: 15px 0px;
                    }
                }

                h3.right-sidebar {
                    font-size: $font-size-medium;
                    font-weight: bold;
                    line-height: 1.1;
                    margin-bottom: $spacer--medium - 1;
                    background-color: #f37321;
                    padding: $spacer + 2;
                    color: $white;
                }

                .sidebar-links {
                    list-style: none;
                    padding: 0px;
                    margin: 0px;

                    li {
                        margin-bottom: $spacer + 2;
                    }

                    a {
                        display: block;
                        color: $black;
                        padding: 8px 16px;
                        text-decoration: none;
                        font-size: $font-size-base;
                        border-bottom: 1px solid #ccc;
                    }

                    a:hover {
                        background-color: #555;
                        color: $white;
                    }
                }

                p a {
                    text-decoration-line: none;
                    color: $black;
                }

                p a:hover {
                    text-decoration-line: underline;
                }
            }

            .table-responsive .solar-table {
                td {
                    vertical-align: top;
                    text-align: left;
                }
            }
        }
    }
}

body.cms-solar-panels-solarland-class-1-division-2-solar-panels {
    .black-links {
        a {
            color: $black;
            text-decoration: none;

            &:hover {
                text-decoration: underline;
            }
        }
    }

    .cms-page__content {
        .container {
            hr {
                border: 0;
                border-top: 1px solid #d1d1d1;
                margin-bottom: $spacer--medium + 4;
                margin-top: $spacer--medium + 4;
            }

            .header-cont {
                h1.home-title-header-x {
                    font-size: $font-size-extra-large;
                    line-height: 10px;
                    font-weight: 800;

                    @media all and (max-width: (992px)) {
                        font-size: $font-size-extra-large;
                        line-height: 30px;
                    }
                }

                h3 {
                    font-size: $font-size-medium;
                    font-weight: 600;
                    line-height: 1.1;
                }

                picture {
                    @media all and (max-width: ($screen-l - 1)) {
                        display: none;
                    }

                    img {
                        margin-left: auto;
                    }
                }
            }

            .css-bottom-details {
                .flex.center.text-center {
                    @media all and (max-width: (425px)) {
                        flex-direction: column;
                    }
                }

                h3 {
                    font-size: $font-size-extra-large;
                    line-height: 1.1;

                    @media all and (max-width: (425px)) {
                        font-size: $font-size-large + 2;
                    }
                }

                .p-2 a {
                    font-size: $font-size-small;
                }

                .radial {
                    background: radial-gradient(
                        ellipse at center,
                        rgba(255, 255, 255, 0.01) 50%,
                        rgba(125, 185, 232, 0.16) 100%
                    );

                    h3 {
                        font-size: $font-size-extra-large;
                        line-height: 1.1;
                        font-weight: $font-weight-normal;

                        @media all and (max-width: (425px)) {
                            font-size: $font-size-large + 2;
                        }
                    }
                }

                .border-gray-thick {
                    border: 2px solid #494848;
                    border-radius: 10px;
                }

                .mt-3 {
                    h3 {
                        font-size: $font-size-extra-large;
                        line-height: 1.1;
                        font-weight: $font-weight-bold;

                        @media all and (max-width: (425px)) {
                            font-size: $font-size-large + 2;
                        }
                    }
                }
            }
        }
    }
}

body.cms-solar-inverter-sma-sunny-tripower-inverters {
    .black-links {
        a {
            color: $black;
            text-decoration: none;

            &:hover {
                text-decoration: underline;
            }
        }
    }

    .cms-page__content {
        .container {
            hr {
                border: 0;
                border-top: 1px solid #d1d1d1;
                margin-bottom: $spacer--medium + 4;
                margin-top: $spacer--medium + 4;
            }

            .header-cont {
                h1.home-title-header-x {
                    font-size: $font-size-extra-large;
                    line-height: 10px;
                    font-weight: 800;

                    @media all and (max-width: (992px)) {
                        font-size: $font-size-extra-large;
                        line-height: 30px;
                    }
                }

                h3 {
                    font-size: $font-size-extra-large;
                    font-weight: 400;
                    line-height: 1.1;

                    @media all and (max-width: (425px)) {
                        font-size: $font-size-extra-large - 4;
                    }
                }

                picture {
                    @media all and (max-width: ($screen-l - 1)) {
                        display: none;
                    }

                    img {
                        margin-left: auto;
                    }
                }
            }

            .css-bottom-details {
                .featured-cat-box {
                    h3 {
                        font-size: $font-size-medium;
                        font-weight: bold;
                        line-height: 1.1;
                    }

                    .featured-desc {
                        ol {
                            list-style: none;
                            font-size: $font-size-small;
                            text-align: center;
                            padding: 0;

                            li {
                                margin-bottom: $spacer + 2;
                            }
                        }
                    }
                }

                .radial {
                    flex-direction: column;
                    background: radial-gradient(
                        ellipse at center,
                        rgba(255, 255, 255, 0.01) 50%,
                        rgba(125, 185, 232, 0.16) 100%
                    );

                    .text-center a {
                        color: $dark-orange;
                    }
                }

                .mt-3 {
                    h3 {
                        font-size: $font-size-medium;
                        line-height: 1.1;
                        font-weight: $font-weight-bold;
                        margin-bottom: $spacer--medium;

                        a {
                            font-weight: $font-weight-bold;
                        }
                    }
                }

                .box {
                    border-right: 1px solid #f4f4f4;

                    picture {
                        display: inline-block;
                    }
                }

                .featured-cat-box {
                    text-align: center;
                }
            }
        }
    }
}

body.cms-solar-battery-backup-systems-midnite-solar-sunny-island-e-panel {
    .black-links {
        a {
            color: $black;
            text-decoration: none;

            &:hover {
                text-decoration: underline;
            }
        }
    }

    .cms-page__content {
        .container {
            h1 {
                font-size: $font-size-super-extra-large + 8;
                line-height: 1.1;
                font-weight: $font-weight-normal;

                @media all and (max-width: (425px)) {
                    font-size: $font-size-extra-large + 4;
                }
            }

            h3 {
                font-size: $font-size-medium;
                font-weight: bold;

                a {
                    font-weight: bold;
                }
            }

            hr {
                border: 0;
                border-top: 1px solid #d1d1d1;
                margin-bottom: $spacer--medium + 4;
                margin-top: $spacer--medium + 4;
            }

            .h3-shadow {
                font-size: $font-size-medium;
            }
        }
    }
}

body.cms-solar-panel-mounts-roof-mounts-prosolar-tiletrac {
    .border-gray-thick {
        border: 2px $border-style-base #494848;
        border-radius: 10px;
    }

    .common-paddings {
        .p-1 {
            padding: $spacer + 2;
        }

        .p-2 {
            padding: $spacer--medium + 4;
        }

        .p-3 {
            padding: $spacer--semi-medium + 6;
        }
    }

    .featured-cat-box {
        @media all and (max-width: ($screen-m - 1)) {
            margin-bottom: $spacer--semi-large + 5;
        }

        img {
            margin-left: auto;
            margin-right: auto;
        }

        p:last-child {
            margin-bottom: 0;
            padding: 0 15px;
        }
    }

    .f-above-img-sec {
        border-radius: 4px;

        picture [class^="p"] {
            padding: 0;
        }
    }

    .flex {
        @media all and (max-width: ($screen-m - 1)) {
            flex-direction: column;
        }
    }

    .col-pad-1 {
        [class*="col-"] {
            padding-left: $spacer--small + 3;
            padding-right: $spacer--small + 3;
        }

        [class*="col-"].p-1p {
            padding-left: 1%;
            padding-right: 1%;
        }
    }
}

body.cms-solar-panel-mounts-roof-mounts-quick-mount-pv-qbase,
body.cms-solar-panel-mounts-roof-mounts-quick-mount-pv-classic-shake,
body.cms-solar-panel-mounts-roof-mounts-quick-mount-pv-classic-composition,
body.cms-solar-panel-mounts-roof-mounts-prosolar-tilt-up-kit {
    .sidebar-links {
        background-color: transparent;

        li {
            margin-bottom: $spacer + 2;
        }
    }
}

body.cms-solar-panel-mounts-roof-mounts-quick-mount-pv-classic-composition {
    img {
        display: inline;
    }
}

body.cms-remote-industrial-solar-mapps-street-light-power-pole-ac-adapter {
    .custom-flex-center {
        display: flex;
        flex-direction: row-reverse;
        justify-content: space-between;
        align-items: center;
    }

    .sub-head {
        font-size: $font-size-extra-large;
    }

    .radial-bg {
        background: radial-gradient(
            ellipse at center,
            rgba(255, 255, 255, 0.01) 50%,
            rgba(125, 185, 232, 0.16) 100%
        );
    }

    .right-sidebar {
        font-size: $font-size-extra-large;
        font-weight: normal;
        margin-bottom: 0;
        background-color: #f37321;
        padding: $spacer + 2;
        color: $white;
        line-height: 1.1;
    }

    .sidebar-links {
        list-style-type: none;
        margin: 0;
        padding: 0;
        background-color: #f1f1f1;

        li a {
            display: block;
            color: #000;
            padding: 8px 16px;
            text-decoration: none;
            font-size: 14px;
            border-bottom: 1px solid #ccc;

            &:hover {
                background-color: #555;
                color: white;
            }
        }
    }

    .black-links {
        color: $black;
        text-decoration: none;

        &:hover {
            text-decoration: underline;
        }
    }

    .no-margin {
        margin: 0;
    }

    .margin-top-bottom {
        margin-top: $spacer--medium + 4;
        margin-bottom: $spacer--medium + 4;
    }

    @media (max-width: $screen-m) {
        .mapps-icon {
            display: none;
        }
    }

    @media (max-width: $screen-s) {
        .sub-head {
            font-size: $font-size-extra-large - 4;
        }
    }
}

body.cms-latinoamerica-sistemas-energia-solar-comercial {
    .home-sub-header-x {
        font-size: $font-size-super-extra-large + 8;
    }

    .sub-head {
        font-size: $font-size-super-extra-large - 2;
    }

    .fw-16-bold {
        font-size: $font-size-medium;
        font-weight: bold;
    }

    .margin-top-bottom {
        margin-top: $spacer--medium + 4;
        margin-bottom: $spacer--medium + 4;
    }

    .contact-btn {
        background-color: #f4f4f4;
        padding: $spacer--medium + 4;
        vertical-align: middle;
        text-align: center;
        border: 5px solid #ef7e18;
        border-radius: $spacer--medium + 6;
        width: 200px;
        margin-bottom: 10px;
    }

    .black-links {
        a {
            color: $black;
            text-decoration: none;

            &:hover {
                text-decoration: underline;
            }
        }
    }

    @media (max-width: $screen-s) {
        .home-sub-header-x {
            font-size: $font-size-extra-large + 4;
        }

        .sub-head {
            font-size: $font-size-extra-large + 1;
        }
    }
}

body.cms-latinoamerica-sistemas-energia-solar-hogar-residencial {
    .home-sub-header-x {
        font-size: $font-size-super-extra-large + 8;
    }

    .sub-head {
        font-size: $font-size-super-extra-large - 2;
    }

    .contact-btn {
        background-color: #f4f4f4;
        padding: 20px;
        vertical-align: middle;
        text-align: center;
        border: 5px solid #ef7e18;
        border-radius: 22px;
        width: 200px;
        margin-bottom: 10px;
    }

    .fw-16-bold {
        font-size: $font-size-medium;
        font-weight: bold;
    }

    .black-links {
        a {
            color: $black;
            text-decoration: none;

            &:hover {
                text-decoration: underline;
            }
        }
    }

    .margin-top-bottom {
        margin-top: $spacer--medium + 4;
        margin-bottom: $spacer--medium + 4;
    }

    @media (max-width: $screen-s) {
        .home-sub-header-x {
            font-size: $font-size-extra-large + 4;
        }

        .sub-head {
            font-size: $font-size-extra-large + 1;
        }
    }
}

body.cms-latinoamerica-sistemas-fuera-de-red-remotos {
    .home-sub-header-x {
        font-size: $font-size-super-extra-large + 8;
    }

    .home-sub-header {
        font-size: $font-size-super-extra-large - 2;
    }

    .sub-head {
        font-size: $font-size-medium;
    }

    .fs-14 {
        font-size: $font-size-base;
    }

    .margin-top-bottom {
        margin-top: $spacer--medium + 4;
        margin-bottom: $spacer--medium + 4;
    }

    .dark-black-links {
        color: $black;
        text-decoration: none;
        font-weight: $font-weight-bold;

        &:hover {
            text-decoration: underline;
            color: $black;
        }
    }

    .no-list-style-center {
        text-align: center;

        div {
            ol {
                li {
                    font-size: 12px;
                    list-style: none;
                    padding: 0;
                    line-height: 2;
                }
            }
        }
    }

    .flex-column {
        flex-direction: column;
    }

    .border-gray-thick {
        border: 2px solid #494848;
        border-radius: $spacer--small - 2;
    }

    @media (max-width: $screen-s) {
        .home-sub-header-x {
            font-size: $font-size-extra-large + 4;
        }

        .home-sub-header {
            font-size: $font-size-extra-large + 1;
        }
    }
}

body.cms-latinoamerica-sistemas-paneles-solares-energia {
    .home-title-header-x {
        font-size: $font-size-extra-large;
        font-weight: normal;
    }

    .home-sub-header-x {
        font-size: $font-size-large + 2;
        font-weight: bold;
    }

    .fw-20-bold {
        font-size: $font-size-large + 2;
        font-weight: bold;
    }

    .fw-16-bold {
        font-weight: bold;
        font-size: $font-size-medium;
    }

    .flex-center {
        display: flex;
        flex-direction: column;
        text-align: center;
        align-items: center;
    }

    .margin-top-bottom {
        margin-top: $spacer--medium + 4;
        margin-bottom: $spacer--medium + 4;
    }

    .dark-black-links {
        font-size: $font-size-medium;
        font-weight: bold;
    }

    .res_bg {
        width: 209px;
        height: auto;
        background-position: right center;
        background-repeat: no-repeat;
        background-size: contain;
        padding: 20px;
        background-image: url(/media/product-images/Solar-Electric-Supply/residential-system-projects.jpg);
    }

    .res_bg {
        p {
            margin: 0;
            padding: 0;
            line-height: 5px;
            color: #ffb200;
            font-weight: 600;
            font-size: 15px;

            &:hover {
                text-decoration: underline;
            }
        }
    }

    .com_bg {
        width: 209px;
        height: auto;
        background-position: right center;
        background-repeat: no-repeat;
        background-size: contain;
        padding: 10px 20px 10px 20px;
        background-image: url(/media/product-images/Solar-Electric-Supply/commercial-system-projects.jpg);
    }

    .com_bg {
        p {
            margin: 0;
            padding: 0;
            line-height: 5px;
            color: #ffcc00;
            font-weight: 600;
            font-size: 15px;

            &:hover {
                text-decoration: underline;
            }
        }
    }

    .con_bg {
        width: 209px;
        height: auto;
        background-position: right center;
        background-repeat: no-repeat;
        background-size: contain;
        padding: 27px 20px 12px 20px;
        margin-top: 22px;
        background-image: url(/media/product-images/Solar-Electric-Supply/solar-contractor.png);
    }

    .con_bg {
        p {
            margin: 0;
            padding: 0;
            line-height: 5px;
            color: #ffb200;
            font-weight: 600;
            font-size: 15px;

            &:hover {
                text-decoration: underline;
            }
        }
    }

    @media (max-width: $screen-s) {
        .home-title-header-x {
            font-size: $font-size-medium + 4;
        }

        .home-sub-header {
            font-size: $font-size-medium + 4;
        }

        .flex {
            flex-direction: column;
        }
    }
}

body.cms-solar-panel-mounts-roof-mounts-ironridge-flashfoot-composition-shingle-mount {
    .home-sub-header-x {
        font-size: $font-size-super-extra-large + 8;
    }

    .right-sidebar {
        font-size: 16px;
        font-weight: normal;
        margin-bottom: 0;
        background-color: #f37321;
        padding: $spacer + 2;
        color: $white;
        line-height: 1.1;
    }

    .sidebar-links {
        list-style-type: none;
        margin: 0;
        padding: 0;
        line-height: 2;

        li a {
            display: block;
            color: $black;
            padding: 8px 16px;
            text-decoration: none;
            font-size: $font-size-base;
            border-bottom: 1px solid #ccc;

            &:hover {
                background-color: #555;
                color: white;
                text-decoration: none;
            }
        }
    }

    .fw-16-bold {
        font-weight: bold;
        font-size: $font-size-medium;
    }

    .black-links {
        color: $black;
        text-decoration: none;

        &:hover {
            text-decoration: underline;
        }
    }

    .orange-links {
        color: $dark-orange;
        text-decoration: none;
        font-weight: bold;

        &:hover {
            text-decoration: underline;
        }
    }

    .dark-black-links {
        color: $black;
        text-decoration: none;
        font-weight: $font-weight-bold;

        &:hover {
            text-decoration: underline;
            color: $black;
        }
    }

    .flex {
        flex-direction: column;
    }

    @media (max-width: $screen-s) {
        .home-sub-header-x {
            font-size: $font-size-extra-large + 4;
        }
    }
}

body.cms-solar-panel-mounts-roof-mounts-snapnrack-flashed-l-foot-kit {
    .home-sub-header-x {
        font-size: $font-size-super-extra-large + 8;
    }

    .right-sidebar {
        font-size: 16px;
        font-weight: normal;
        margin-bottom: 0;
        background-color: #f37321;
        padding: $spacer + 2;
        color: $white;
        line-height: 1.1;
    }

    .sidebar-links {
        list-style-type: none;
        margin: 0;
        padding: 0;
        line-height: 2;

        li a {
            display: block;
            color: $black;
            padding: 8px 16px;
            text-decoration: none;
            font-size: $font-size-base;
            border-bottom: 1px solid #ccc;

            &:hover {
                background-color: #555;
                color: white;
                text-decoration: none;
            }
        }
    }

    .fw-16-bold {
        font-weight: bold;
        font-size: $font-size-medium;
    }

    .dark-black-links {
        color: $black;
        text-decoration: none;
        font-weight: $font-weight-bold;

        &:hover {
            text-decoration: underline;
            color: $black;
        }
    }

    .radial {
        background: radial-gradient(
            ellipse at center,
            rgba(255, 255, 255, 0.01) 50%,
            rgba(125, 185, 232, 0.16) 100%
        );
        margin: 20px 0px;
    }

    .ten-link {
        color: #000066;
        text-decoration: underline;
        text-transform: capitalize;
        font-size: 10px;
        text-shadow: 1px 1px 1px #d4d4d4;
    }

    .box {
        border-right: 1px solid #f4f4f4;
    }

    @media (max-width: $screen-s) {
        .home-sub-header-x {
            font-size: 28px;
        }
    }
}

body.cms-solar-panel-mounts-roof-mounts-snapnrack-metal-roof-base {
    .home-sub-header-x {
        font-size: $font-size-super-extra-large + 8;
    }

    .right-sidebar {
        font-size: 16px;
        font-weight: bold;
        margin-bottom: 0;
        background-color: #f37321;
        padding: $spacer + 2;
        color: $white;
        line-height: 1.1;
    }

    .sidebar-links {
        list-style-type: none;
        margin: 0;
        padding: 0;
        line-height: 2;

        li a {
            display: block;
            color: $black;
            padding: 8px 16px;
            text-decoration: none;
            font-size: $font-size-base;
            border-bottom: 1px solid #ccc;

            &:hover {
                background-color: #555;
                color: white;
                text-decoration: none;
            }
        }
    }

    .radial {
        background: radial-gradient(
            ellipse at center,
            rgba(255, 255, 255, 0.01) 50%,
            rgba(125, 185, 232, 0.16) 100%
        );
        margin: 20px 0px;
    }

    .dark-black-links {
        color: $black;
        text-decoration: none;
        font-weight: $font-weight-bold;

        &:hover {
            text-decoration: underline;
            color: $black;
        }
    }

    .black-links {
        color: $black;
        text-decoration: none;

        &:hover {
            text-decoration: underline;
        }
    }

    .display-unset {
        display: unset;
    }

    .fw-16-bold {
        font-weight: bold;
        font-size: $font-size-medium;
    }

    .box {
        border-right: 1px solid #f4f4f4;
    }

    .margin-top-bottom {
        margin-top: $spacer--medium + 4;
        margin-bottom: $spacer--medium + 4;
    }

    @media (max-width: $screen-s) {
        .home-sub-header-x {
            font-size: $font-size-extra-large + 4;
        }
    }
}

body.cms-solar-panel-mounts-roof-mounts-snapnrack-series-500-standing-seam-metal-system {
    .home-sub-header-x {
        font-size: $font-size-super-extra-large + 8;
    }

    .right-sidebar {
        font-size: 16px;
        font-weight: bold;
        margin-bottom: 0;
        background-color: #f37321;
        padding: $spacer + 2;
        color: $white;
        line-height: 1.1;
    }

    .sidebar-links {
        list-style-type: none;
        margin: 0;
        padding: 0;
        line-height: 2;

        li a {
            display: block;
            color: $black;
            padding: 8px 16px;
            text-decoration: none;
            font-size: $font-size-base;
            border-bottom: 1px solid #ccc;

            &:hover {
                background-color: #555;
                color: white;
                text-decoration: none;
            }
        }
    }

    .sub-head {
        font-size: $font-size-medium;
    }

    .fw-16-bold {
        font-size: $font-size-medium;
        font-weight: bold;
    }

    .dark-black-links {
        color: $black;
        text-decoration: none;
        font-weight: $font-weight-bold;

        &:hover {
            text-decoration: underline;
            color: $black;
        }
    }

    .radial {
        background: radial-gradient(
            ellipse at center,
            rgba(255, 255, 255, 0.01) 50%,
            rgba(125, 185, 232, 0.16) 100%
        );
    }

    .margin-top-bottom {
        margin-top: $spacer--medium + 4;
        margin-bottom: $spacer--medium + 4;
    }

    .black-links {
        color: $black;
        text-decoration: none;

        &:hover {
            text-decoration: underline;
        }
    }

    @media (max-width: $screen-s) {
        .home-sub-header-x {
            font-size: $font-size-extra-large + 4;
        }
    }
}

body.cms-solar-panels-panasonic-hit-solar-panels {
    .black-links {
        a {
            color: $black;
            text-decoration: none;

            &:hover {
                text-decoration: underline;
            }
        }
    }

    .cms-page__content {
        .container {
            .header-cont {
                h1.home-title-header-x {
                    font-size: $font-size-extra-large;
                    line-height: 10px;
                    font-weight: 800;

                    @media all and (max-width: (992px)) {
                        font-size: $font-size-extra-large;
                        line-height: 30px;
                        font-weight: 800;
                    }
                }

                h3 strong {
                    font-size: $font-size-extra-large;
                    font-weight: 700;
                    line-height: 1.1;
                }

                picture {
                    @media all and (max-width: ($screen-l - 1)) {
                        display: none;
                    }

                    img {
                        margin-left: auto;
                    }
                }
            }

            .css-bottom-details {
                .flex.center.text-center {
                    @media all and (max-width: (425px)) {
                        flex-direction: column;
                    }
                }

                .col-sm-12 {
                    a {
                        flex-direction: column;
                        align-items: center;
                    }
                }

                .p-2 {
                    font-size: $font-size-small;
                }

                h3 strong {
                    font-size: $font-size-medium;
                }

                .f-above-img-sec {
                    margin-top: $spacer--semi-medium - 2;
                    border-radius: 4px;
                    background-image: url(/media/product-images/remote-industrial-solar/remote-outdoor-solar-systems/remote-off-grid-solar-power-ground-2.png);
                    background-repeat: no-repeat;
                    background-position: left bottom;
                    background-color: #f0f0f0;
                    padding-left: $spacer--medium - 1;
                    padding-right: $spacer--medium - 1;
                    padding-bottom: $spacer--medium - 1;
                }
            }

            .table-responsive .solar-table {
                a {
                    color: $darker-blue;
                    text-decoration: underline;
                    font-family: inherit;
                    font-size: $font-size-small;
                    font-weight: bold;
                }
            }
        }
    }
}

body.cms-solar-panel-mounts-roof-mounts-s-5-versabracket,
body.cms-solar-panel-mounts-roof-mounts-quick-mount-pv-e-mount,
body.cms-solar-panel-mounts-roof-mounts-quick-mount-pv-quick-rack,
body.cms-solar-panel-mounts-roof-mounts-quick-mount-pv-conduit-penetration-flashing {
    .black-links {
        a {
            color: $black;
            text-decoration: none;

            &:hover {
                text-decoration: underline;
            }
        }
    }

    .cms-page__content {
        .container {
            hr {
                border: 0;
                border-top: 1px solid #d1d1d1;
                margin-bottom: $spacer--medium + 4;
                margin-top: $spacer--medium + 4;
            }

            .header-cont {
                h1.home-sub-header-x {
                    font-size: $font-size-super-extra-large + 8;
                    line-height: 1.1;
                    font-weight: $font-weight-normal;

                    @media all and (max-width: (425px)) {
                        font-size: $font-size-extra-large + 4;
                    }
                }
            }

            .css-bottom-details {
                .radial {
                    background: radial-gradient(
                        ellipse at center,
                        rgba(255, 255, 255, 0.01) 50%,
                        rgba(125, 185, 232, 0.16) 100%
                    );
                }

                .col-sm-12 {
                    a {
                        flex-direction: column;
                        align-items: center;
                    }
                }

                .row {
                    .col-md-4,
                    .col-md-3 {
                        padding: 0 15px 0 15px;
                    }
                }

                h3 {
                    font-size: $font-size-medium;
                    font-weight: bold;
                    margin: 15px 0px;
                }

                h3.right-sidebar {
                    font-size: $font-size-medium;
                    font-weight: bold;
                    line-height: 1.1;
                    margin-bottom: $spacer--medium - 1;
                    background-color: #f37321;
                    padding: $spacer + 2;
                    color: $white;
                }

                .sidebar-links {
                    list-style: none;
                    padding: 0px;
                    margin: 0px;

                    li {
                        margin-bottom: $spacer + 2;
                    }

                    a {
                        display: block;
                        color: $black;
                        padding: 8px 16px;
                        text-decoration: none;
                        font-size: $font-size-base;
                        border-bottom: 1px solid #ccc;
                    }

                    a:hover {
                        background-color: #555;
                        color: $white;
                    }
                }

                p a {
                    text-decoration-line: none;
                    color: $black;
                }

                p a:hover {
                    text-decoration-line: underline;
                }

                .pdf-box {
                    @media all and (max-width: (425px)) {
                        width: auto;
                    }

                    h3 {
                        margin: 0 0 10px 0;
                    }
                }

                .small-screen {
                    @media all and (min-width: (1440px)) {
                        display: none;
                    }
                }

                .large-screen {
                    @media all and (max-width: (1439px)) {
                        display: none;
                    }
                }

                .box {
                    border-right: 1px solid #f4f4f4;

                    @media all and (max-width: (425px)) {
                        border: none;
                    }
                }
            }
        }
    }
}

body.cms-solar-panels-discount-gridtie {
    .custom-rightbar {
        ol {
            padding: 0;

            li {
                list-style: none;

                a {
                    font-size: 12px;
                    text-decoration: none;
                    margin-bottom: 1rem;

                    &:hover {
                        color: #000;
                        text-decoration: underline;
                    }
                }
            }
        }
    }

    .home-sub-header-x {
        font-size: $font-size-super-extra-large + 8;
    }

    .black-links {
        color: $black;
        text-decoration: none;

        &:hover {
            text-decoration: underline;
        }
    }

    .fw-16-bold {
        font-weight: bold;
        font-size: $font-size-medium;
    }

    .fw-12-link {
        a {
            font-size: $font-size-small;

            &:hover {
                text-decoration: underline;
            }
        }
    }

    .cell_gray_grad {
        height: min-content;
    }

    .fw-14-bold {
        font-weight: bold;
        font-size: $font-size-base;
    }

    .margin-top-bottom {
        margin-top: $spacer--medium + 4;
        margin-bottom: $spacer--medium + 4;
    }

    .padding-left {
        padding-left: $spacer--medium + 4;
    }

    .box {
        border-right: 1px solid #f4f4f4;

        @media all and (max-width: 425px) {
            border: none;
        }
    }

    .all-black-links {
        a {
            color: $black;
            text-decoration: none;

            &:hover {
                text-decoration: underline;
            }
        }
    }

    .text-center {
        h2 {
            font-size: $font-size-super-extra-large - 2;
        }

        h3 {
            font-size: $font-size-medium;
        }
    }

    @media (max-width: $screen-s) {
        .home-sub-header-x {
            font-size: $font-size-extra-large + 4;
        }
    }
}

body.cms-solar-inverter-solaredge-solaredge-storedge-energy-storage-inverters {
    .header-cont {
        display: flex;
        flex-direction: row-reverse;
        justify-content: space-between;
    }

    .custom-flex {
        div {
            display: flex;
            justify-content: center;
            align-items: center;

            div {
                display: flex;
                justify-content: center;
                flex-direction: column;
                align-items: center;
            }
        }
    }

    .text-center {
        font-size: $font-size-medium;
    }

    .padding-8 {
        padding-left: 8px;
        padding-right: 8px;
    }

    .padding-20 {
        padding-top: $spacer--medium + 4;
        padding-bottom: $spacer--medium + 4;
    }

    .fw-14-bold {
        font-weight: bold;
        font-size: $font-size-base;

        a {
            text-decoration: none;
        }
    }

    .flex-col {
        display: flex;
        flex-direction: column;
    }

    .black-links {
        color: $black;
        text-decoration: none;

        &:hover {
            text-decoration: underline;
        }
    }

    .dark-black-links {
        color: $black;
        text-decoration: none;
        font-weight: $font-weight-bold;

        &:hover {
            text-decoration: underline;
            color: $black;
        }
    }

    .f-above-img-sec {
        margin-top: 22px;
        border-radius: 4px;
        margin-left: auto;
        background-image: url(/media/product-images/remote-industrial-solar/remote-outdoor-solar-systems/remote-off-grid-solar-power-ground-2.png);
        background-repeat: no-repeat;
        background-position: left bottom;
        background-color: #f0f0f0;
        display: flex;
    }

    .font-30 {
        font-size: $font-size-super-extra-large - 2;
    }

    .fw-16-bold {
        font-weight: bold;
        font-size: $font-size-medium;
    }

    .box {
        border-right: 1px solid #f4f4f4;
    }

    @media (max-width: $screen-s) {
        .pdf-box {
            width: 100%;
        }

        .container {
            flex-direction: column;
        }

        .font-30 {
            font-size: 25px;
        }

        .display-m-none {
            display: none;
        }

        .sub-head {
            font-size: 20px;
        }

        .padding-left-0 {
            padding-left: 0px;
        }

        .margin-inherit {
            margin: inherit;
        }

        .no-float {
            float: none;
            margin-bottom: $spacer--medium + 4;
        }
    }
}

body.cms-solaria-solar-panels-powerxt-low-wholesale-price {
    .custom-flex {
        div {
            a {
                flex-direction: column;
                margin-left: $spacer--semi-medium - 2;
            }
        }
    }

    .margin-top-bottom {
        margin-top: $spacer--medium + 4;
        margin-bottom: $spacer--medium + 4;
    }

    .text-center {
        text-align: center;
    }

    .sub-head {
        font-size: $font-size-extra-large;
    }

    .dark-blue-links {
        color: #000099;
        font-weight: bold;
        text-decoration: underline;
    }

    .dark-blue-links:hover {
        color: #333;
        text-decoration: underline;
    }

    .flex-column {
        flex-direction: column;
        align-items: center;
    }

    .black-links {
        color: $black;
        text-decoration: none;

        &:hover {
            text-decoration: underline;
        }
    }

    .dark-black-links {
        color: $black;
        text-decoration: none;
        font-weight: $font-weight-bold;

        &:hover {
            text-decoration: underline;
            color: $black;
        }
    }

    .table-view {
        font-size: $font-size-small;

        a {
            color: $black;
            text-decoration: none;
            font-weight: $font-weight-bold;

            &:hover {
                text-decoration: underline;
                color: $black;
            }
        }
    }

    .home-sub-header-x {
        font-size: $font-size-large + 4;
    }

    .h3-shadow {
        margin: 1px 1px 1px 1px;
        color: #000033;
        font-size: 16px;
        font-weight: normal;
        text-shadow: 1px 1px 1px #b1b1b2;
    }

    .ten-link {
        text-decoration: underline;
        text-transform: capitalize;
        font-size: $font-size-extra-small;
        text-shadow: 1px 1px 1px #d4d4d4;
        font-weight: 700;
    }

    table {
        td {
            width: fit-content;
        }
    }

    @media (max-width: $screen-s) {
        .custom-flex {
            flex-direction: column;

            div {
                a {
                    align-items: center;
                }
            }
        }

        .img-center {
            width: fit-content;
            margin: auto;
        }
    }

    .img-center-flex {
        display: flex;
        justify-content: center;
    }
}

body.cms-solar-panel-mounts-roof-mounts-s-5 {
    .header-cont{
        padding: 15px;
    }
    hr{
        opacity: 0.4;
        margin: 20px 0;
    }
    a {
        text-decoration: none;
    }

    a:hover {
        text-decoration: underline;
        color: #000;
    }
    strong {
        a {
            font-weight: $font-weight-bold;
            text-decoration: none;
        }

        a:hover {
            text-decoration: underline;
            color: #000;
        }
    }
    .radial {
        background: radial-gradient(
            ellipse at center,
            rgba(255, 255, 255, 0.01) 50%,
            rgba(125, 185, 232, 0.16) 100%
        );
    }

    .home-sub-header-x {
        font-size: $font-size-medium;
    }

    .kp-width100 {
        width: 100%;
    }

    .kp-justify-contentCenter {
        justify-content: center;
    }

    .kp-justify-contentSpace-bet {
        justify-content: space-between;
    }

    .kp-margin {
        margin: 20px 0px;
    }

    .kp-tableHead {
        border: 1px solid #ddd;
        font-size: $font-size-small;
        font-weight: $font-weight-normal;
    }

    .kp-tableContent {
        border: 1px solid #ddd;

        p {
            margin-bottom: $spacer--small - 2;

            a {
                color: #000066;
                text-decoration: underline;
                font-family: inherit;
                font-size: $font-size-small;
                font-weight: bold;
            }
        }
    }

    .kp-link {
        color: #000066;
        text-decoration: underline;
        text-transform: capitalize;
        font-size: $font-size-extra-small;
        text-shadow: 1px 1px 1px #d4d4d4;
        font-weight: $font-weight-bold;
    }

    .kp-contact {
        color: #000;
        text-decoration: none;
        font-weight: $font-weight-bold;
    }

    .kp-contact:hover {
        text-decoration: underline;
    }

    .kp-margin30 {
        margin-top: $spacer--extra-large + 12;

        h3 {
            strong {
                margin-top: $spacer--large - 2;
                font-size: $spacer--medium;

                a {
                    color: #000;
                    font-weight: $font-weight-bold;
                }
            }
        }
    }

    .twelve-link {
        color: #ef7e18;
        font-weight: $font-weight-bold;
    }

    .row {
        .col-md-8 {
            p {
                strong {
                    a {
                        color: #000;
                        font-size: $font-weight-bold;
                    }
                }
            }
        }
    }

    @media all and (max-width: 811px) {
        .home-title-header-x {
            white-space: nowrap;
            margin-bottom: $spacer--medium + 4;
        }

        .css-bottom-details {
            strong {
                hr {
                    margin: 20px 0px;
                    opacity: 0.4;
                }
            }
        }

        .kp-margin {
            margin: 20px 0px;
            opacity: 0.4;
        }
    }

    @media all and (max-width: 811px) {
        .home-title-header-x {
            white-space: normal;
            line-height: $spacer--large - 2;
        }

        .kp-margin {
            margin: 20px 0px;
            opacity: 0.4;
        }

        .kp-margin30 {
            margin-top: $spacer--large - 2;
        }

        .page-wrapper {
            margin-bottom: $spacer--semi-large;
        }
    }
}

body.cms-solar-panel-mounts-roof-mounts-s-5-s-5-clamps-brackets {
    .col-sm-4 {
        img{
            padding: 20px 0px;
        }
        picture{
            display: contents;
        }
    }
    a {
        text-decoration: none;
    }

    a:hover {
        text-decoration: underline;
        color: #000;
    }
    strong {
        a {
            font-weight: $font-weight-bold;
            text-decoration: none;
        }

        a:hover {
            text-decoration: underline;
            color: #000;
        }
    }
    padding: 0px;
    font-style: normal;
    font-weight: $font-weight-normal;
    // line-height: 1.42857143;

    .floatlft {
        float: left;
        padding: 0 $spacer--medium - 1;
    }

    .floatright {
        float: right;

        .right-sidebar {
            strong {
                white-space: normal;
            }
        }
    }

    @media (min-width: 768px) {
        .col-sm-1,
        .col-sm-2,
        .col-sm-3,
        .col-sm-4,
        .col-sm-5,
        .col-sm-6,
        .col-sm-7,
        .col-sm-8,
        .col-sm-9,
        .col-sm-10,
        .col-sm-11,
        .col-sm-12 {
            float: left;
        }
    }

    .radial {
        background: radial-gradient(
            ellipse at center,
            rgba(255, 255, 255, 0.01) 50%,
            rgba(125, 185, 232, 0.16) 100%
        );
    }

    .css-bottom-details h3 {
        font-size: $font-size-medium;
        font-weight: bold;
        margin-bottom: $spacer--medium - 1;
        font-family: inherit;
    }

    .right-sidebar {
        background-color: #f37321;
        padding: $spacer--small - 2;
        color: #fff;
    }

    .ul,
    ol {
        margin-top: 0rem;
        margin-bottom: $spacer--semi-medium + 1;
        list-style: none;
    }

    .col-xs-1,
    .col-sm-1,
    .col-md-1,
    .col-lg-1,
    .col-xs-2,
    .col-sm-2,
    .col-md-2,
    .col-lg-2,
    .col-xs-3,
    .col-sm-3,
    .col-md-3,
    .col-lg-3,
    .col-xs-4,
    .col-sm-4,
    .col-md-4,
    .col-lg-4,
    .col-xs-5,
    .col-sm-5,
    .col-md-5,
    .col-lg-5,
    .col-xs-6,
    .col-sm-6,
    .col-md-6,
    .col-lg-6,
    .col-xs-7,
    .col-sm-7,
    .col-md-7,
    .col-lg-7,
    .col-xs-8,
    .col-sm-8,
    .col-md-8,
    .col-lg-8,
    .col-xs-9,
    .col-sm-9,
    .col-md-9,
    .col-lg-9,
    .col-xs-10,
    .col-sm-10,
    .col-md-10,
    .col-lg-10,
    .col-xs-11,
    .col-sm-11,
    .col-md-11,
    .col-lg-11,
    .col-xs-12,
    .col-sm-12,
    .col-md-12,
    .col-lg-12 {
        position: relative;
        min-height: 1px;
        padding-left: $spacer--medium - 1;
        padding-right: $spacer--medium - 1;
    }

    .sidebar-links {
        padding: 0px;

        li {
            margin-bottom: $spacer--small - 2;

            a {
                display: block;
                color: #000;
                padding: 8px 16px;
                text-decoration: none;
                font-size: $font-size-base;
                border-bottom: 1px solid #ccc;
            }

            a:hover:not(.active) {
                background-color: #555;
                color: white;
            }
        }
    }

    @media (min-width: 768px) {
        .col-sm-3 {
            a {
                width: 100%;

                h3 {
                    width: 100%;
                }
            }

            width: 25%;
        }
    }

    .col-sm-4 {
        width: 33.33333333%;

        .py-2 {
            width: 152px;
            height: 248px;
        }

        p {
            margin-bottom: $spacer + 2;
        }
    }

    hr {
        border: 0;
        border-top-color: currentcolor;
        border-top-style: none;
        border-top-width: 0px;
        border-top: 1px solid #d1d1d1;
        margin-bottom: $spacer--medium + 4;
        margin-top: $spacer--medium + 4;
    }

    .kp-width100 {
        width: 100%;

        .side-box {
            text-align: initial;

            p {
                margin-bottom: $spacer--small - 2;
            }

            a {
                strong {
                    p {
                        font-weight: $font-weight-bold;
                    }
                }

                p {
                    margin-bottom: $spacer--small - 2;
                }
            }
        }
    }

    .width100 {
        width: 100%;

        .table-responsive {
            width: 100%;

            img {
                padding-bottom: 0px;
            }

            .solar-table {
                p {
                    margin-bottom: $spacer--small - 2;
                }

                strong {
                    p {
                        font-weight: $font-weight-bold;
                    }
                }
            }

            p:hover {
                color: #333333;
                text-decoration: underline;
            }
        }
    }

    .box {
        border-right: 1px solid #f4f4f4;
    }

    .bottomContent {
        .col-sm-12 {
            width: 100%;
            text-decoration: none;
        }
    }

    .kp-bold {
        font-weight: $font-weight-bold;
    }

    .notextdeco {
        text-decoration: none;
    }

    .kp-height {
        height: 296px;

        h3 {
            strong {
                text-decoration: none;
                font-weight: $font-weight-bold;
                font-size: $spacer--medium;
            }
        }

        img {
            height: auto;
        }
    }

    @media all and (max-width: $screen-m) {
        .container {
            width: 100%;
            margin: auto;
            padding: 0px 10px;
        }
    }

    @media all and (max-width: $screen-s) {
        .floatlft {
            float: none;
            display: contents;
        }

        .col-sm-8 {
            display: contents;
        }

        .col-sm-4,
        .col-sm-6 {
            display: contents;
        }

        .floatright {
            display: contents;
            float: none;

            a {
                width: 100%;

                h3 {
                    width: 100%;
                }
            }
        }
    }
}

body.cms-solar-panel-mounts-roof-mounts-s-5-s-5-pv-kit {
    a {
        text-decoration: none;
    }

    a:hover {
        text-decoration: underline;
        color: #000;
    }
    strong {
        a {
            font-weight: $font-weight-bold;
            text-decoration: none;
        }

        a:hover {
            text-decoration: underline;
            color: #000;
        }
    }
    .radial {
        background: radial-gradient(
            ellipse at center,
            rgba(255, 255, 255, 0.01) 50%,
            rgba(125, 185, 232, 0.16) 100%
        );
    }

    @media all and (min-width: ($screen-s + 1)) {
        .floatlft {
            float: left;
        }

        .floatright {
            float: right;
        }

        .inlineblock {
            display: inline-block;
        }

        .sidebar-links {
            padding: 0px;

            li {
                margin-bottom: $spacer--small - 2;

                a {
                    display: block;
                    color: #000;
                    padding: 8px 16px;
                    text-decoration: none;
                    font-size: $font-size-base;
                    border-bottom: 1px solid #ccc;
                    list-style: none;
                }

                a:hover:not(.active) {
                    background-color: #555;
                    color: white;
                }
            }
        }

        .right-sidebar {
            background-color: #f37321;
            padding: $spacer--small - 2;
            color: #fff;
            height: auto;
        }

        .ul,
        ol {
            margin-top: 0rem;
            margin-bottom: $spacer--semi-medium + 1;
            list-style: none;
        }

        a {
            text-decoration: none;
        }

        a:hover {
            color: #000000;
            text-decoration: underline;
            cursor: pointer;
        }

        strong {
            a {
                font-weight: $font-weight-bold;
            }
        }

        .rubycont {
            img {
                display: ruby-base-container;
            }
        }

        h3 {
            font-weight: $font-weight-bold;
        }
    }

    @media (max-width: ($screen-s)) {
        .displaycontents {
            display: contents;
        }
    }

    @media (max-width: ($screen-m)) {
        .displaycontents {
            display: contents;
        }

        .sidebar-links {
            padding: 0px;

            li {
                margin-bottom: 10px;

                a {
                    display: block;
                    color: #000;
                    padding: 8px 16px;
                    text-decoration: none;
                    font-size: $font-size-base;
                    border-bottom: 1px solid #ccc;
                    list-style: none;
                }

                a:hover:not(.active) {
                    background-color: #555;
                    color: white;
                }
            }
        }

        .right-sidebar {
            background-color: #f37321;
            padding: $spacer--small - 2;
            color: #fff;
            // height: 37px;
            float: none;
            height: 92px;
            display: inline-block;
        }

        .ul,
        ol {
            margin-top: 0rem;
            margin-bottom: $spacer--semi-medium + 1;
            list-style: none;
        }

        strong {
            a {
                font-weight: $font-weight-bold;
            }
        }
    }
}

body.cms-solar-panel-mounts-roof-mounts-s-5-proteabracket {
    a {
        font-weight: $font-weight-bold;
        text-decoration: none;
    }

    a:hover {
        text-decoration: underline;
        color: #000;
    }
    .radial {
        background: radial-gradient(
            ellipse at center,
            rgba(255, 255, 255, 0.01) 50%,
            rgba(125, 185, 232, 0.16) 100%
        );
    }

    @media (max-width: ($screen-s)) {
        .col-sm-9,
        .col-sm-8,
        .col-sm-4,
        .col-sm-6,
        .col-sm-3 {
            display: contents;
        }

        .sidebar-links {
            padding: 0px;

            li {
                margin-bottom: $spacer--small - 2;

                a {
                    display: block;
                    color: #000;
                    padding: 8px 16px;
                    text-decoration: none;
                    font-size: $font-size-base;
                    border-bottom: 1px solid #ccc;
                    list-style: none;
                }

                a:hover:not(.active) {
                    background-color: #555;
                    color: white;
                }
            }
        }

        .right-sidebar {
            background-color: #f37321;
            padding: $spacer--small - 2;
            color: #fff;
            height: auto;
        }

        .ul,
        ol {
            margin-top: 0rem;
            margin-bottom: $spacer--semi-medium + 1;
            list-style: none;
        }

        .proteabracketpoints {
            padding-left: 0px;
            font-size: $font-size-small;
            line-height: 2;
        }

        h3 {
            font-size: $font-size-medium;
            font-weight: bold;
        }

        strong {
            a {
                font-weight: $font-weight-bold;
                text-decoration: none;
            }

            a:hover {
                text-decoration: underline;
                color: #000;
            }
        }
    }

    @media (min-width: ($screen-s+1)) {
        .sidebar-links {
            padding: 0px;

            li {
                margin-bottom: 10px;

                a {
                    display: block;
                    color: #000;
                    padding: 8px 16px;
                    text-decoration: none;
                    font-size: $font-size-base;
                    border-bottom: 1px solid #ccc;
                    list-style: none;
                }

                a:hover:not(.active) {
                    background-color: #555;
                    color: white;
                }
            }
        }

        .right-sidebar {
            background-color: #f37321;
            padding: 10px;
            color: #fff;
            height: auto;
        }

        .ul,
        ol {
            margin-top: 0rem;
            margin-bottom: $spacer--semi-medium + 1;
            list-style: none;
        }

        .floatlft {
            float: left;
        }

        .floatright {
            float: right;
        }

        h3 {
            font-size: $font-size-medium;
        }

        .proteabracketpoints {
            padding-left: 0px;
            font-size: 12px;
            line-height: 2;
            margin-bottom: 91px;
        }

        strong {
            a {
                font-size: $font-weight-bold;
                text-decoration: none;
            }

            a:hover {
                text-decoration: underline;
                color: #000;
            }
        }
    }

    @media (min-width: $screen-m) {
        h3 {
            font-size: $font-size-medium;
        }
    }
}

body.cms-solar-panel-mounts-roof-mounts-s-5-s-5-corrubracket-100t {
    .floatright {
        p {
            a {
                strong {
                    font-size: 10px;
                    color: #000066;
                    text-decoration: underline;
                }
            }
        }
    }
    .radial {
        background: radial-gradient(
            ellipse at center,
            rgba(255, 255, 255, 0.01) 50%,
            rgba(125, 185, 232, 0.16) 100%
        );
    }

    hr {
        border: 0;
        border-top: 1px solid #d1d1d1;
        margin-bottom: 20px;
        margin-top: 20px;
    }

    .sidebar-links {
        padding: 0px;

        li {
            margin-bottom: 10px;

            a {
                display: block;
                color: #000;
                padding: 8px 16px;
                text-decoration: none;
                font-size: $font-size-base;
                border-bottom: 1px solid #ccc;
                list-style: none;
            }

            a:hover:not(.active) {
                background-color: #555;
                color: white;
            }
        }
    }

    .right-sidebar {
        background-color: #f37321;
        padding: 10px;
        color: #fff;
        height: auto;
    }

    .ul,
    ol {
        margin-top: 0rem;
        margin-bottom: $spacer--semi-medium + 1;
        list-style: none;
    }

    .col-sm-6 {
        ol {
            padding-left: 0px;
            line-height: 1.8;
            font-size: 12px;
        }
    }
    a {
        text-decoration: none;
        color: #000;

        &:hover {
            text-decoration: underline;
        }
    }

    strong {
        a {
            font-weight: $font-weight-bold;
            text-decoration: none;
        }

        a:hover {
            text-decoration: underline;
            color: #000;
        }
    }

    @media (max-width: ($screen-s)) {
        img {
            height: auto;
        }

        .pdf-box {
            width: 100%;
        }
    }

    @media (min-width: ($screen-s+1)) {
        .radial {
            .col-sm-8 {
                width: 100%;

                img {
                    margin: auto !important;
                }
            }
        }

        #container {
            margin: auto;
        }

        .floatlft {
            float: left;
        }

        .floatright {
            float: right;
        }

        h3 {
            font-size: $font-size-medium;
            font-weight: $font-weight-bold;
        }

        img {
            height: auto;
        }

        .col-sm-6 {
            ol {
                padding-left: 0px;
                line-height: 1.8;
                font-size: $font-size-small;
                list-style: decimal;
                font-weight: $font-weight-bold;
            }
        }

        hr {
            overflow: hidden;
        }

        .leftali {
            width: -moz-available;
        }

        .width100 {
            width: 100%;
        }
    }
}

body.cms-solar-charge-controllers-morningstar-charge-controllers {
    a {
        text-decoration: none;
    }

    a:hover {
        text-decoration: underline;
        color: #000;
    }
    strong {
        a {
            font-weight: $font-weight-bold;
            text-decoration: none;
        }

        a:hover {
            text-decoration: underline;
            color: #000;
        }
    }
    .row.container.f-above-img-sec {
        width: 952px;
        margin-top: 22px;
        border-radius: 4px;
        margin-left: auto;
        background-image: url(/media/product-images/remote-industrial-solar/remote-outdoor-solar-systems/remote-off-grid-solar-power-ground-2.png);
        background-repeat: no-repeat;
        background-position: left bottom;
        background-color: #f0f0f0;
    }

    link,
    a {
        text-decoration: none;
    }

    link:hover {
        text-decoration: underline;
        color: #000;
    }

    a:hover {
        text-decoration: underline;
        color: #000;
    }

    @media (max-width: $screen-s) {
        .row.container.f-above-img-sec {
            width: auto;
        }
        .header-cont {
            picture {
                display: none;
            }
        }

        .home-title-header-x {
            margin-bottom: $spacer--medium + 4;
        }

        .home-sub-header-x {
            font-size: $font-size-medium;
            font-weight: $font-weight-bold - 100;
        }

        .css-bottom-details {
            margin-top: $spacer--medium - 1;
            margin-bottom: $spacer--medium - 1;
        }

        .css-bottom-details {
            .sloped-roof {
                margin-top: $spacer--medium - 1;

                h3 {
                    text-align: left;
                    padding-left: $spacer--medium - 1;
                }

                ul {
                    li {
                        text-align: left;
                        margin-bottom: 3px;
                    }
                }
            }

            h4 {
                font-size: $font-size-base;
                font-weight: bold;
                margin: 25px 0px 10px;
                color: #000033;
            }
        }

        .featured-cat-box {
            padding-left: 0;
            text-align: center;
            margin-bottom: $spacer--semi-large + 5;

            h3 {
                font-size: 13px;
                color: #342e2a;
                font-family: inherit;
                text-decoration: none;
                text-align: center;
                font-weight: bold;
                margin-top: 15px;
            }
        }

        .d-block {
            display: grid;
        }

        .mt-3 {
            .row {
                .col-sm-12 {
                    picture {
                        margin-top: 0px;
                    }
                }
            }
        }

        .mt-3 {
            margin-top: 30px;
        }
    }

    @media (min-width: ($screen-s+1)) {
        .header-cont {
            picture {
                display: none;
            }
        }

        .home-title-header-x {
            margin-bottom: 24px;
        }

        .home-sub-header-x {
            font-size: $font-size-medium;
            font-weight: $font-weight-bold - 100;
        }

        .featured-cat-box {
            text-align: center;

            ul {
                li {
                    text-align: left;
                    margin-bottom: 3px;
                }
            }
        }

        .sloped-roof {
            .featured-cat-box {
                h3 {
                    font-size: 13px;
                    color: #342e2a;
                    font-family: inherit;
                    text-decoration: none;
                    text-align: center;
                    font-weight: bold;
                    margin-top: 15px;
                    margin-bottom: 15px;
                }
            }
        }

        .sloped-roof {
            .featured-desc > h3 {
                text-align: left;
                padding-left: 15px;
            }
        }

        .d-block {
            display: grid;
            font-size: 12px;
        }

        .floatlft {
            float: left;
        }

        .floatright {
            float: right;
        }

        .css-bottom-details {
            #width100 {
                width: 100%;
            }
        }

        .col-md-4 {
            .pt-2 {
                img {
                    margin-top: 0px;
                }
            }
        }

        h4 {
            font-size: $font-size-base;
        }
    }

    @media (min-width: ($screen-l)) {
        .floatright {
            float: right;
        }

        .header-cont {
            picture {
                display: block;
            }
        }

        h3 {
            font-size: $font-size-medium;
        }
    }
}

body.cms-solar-charge-controllers-morningstar-charge-controllers-morningstar-tristar-charge-controllers {
    a {
        text-decoration: none;
    }

    a:hover {
        text-decoration: underline;
        color: #000;
    }
    strong {
        a {
            font-weight: $font-weight-bold;
            text-decoration: none;
        }

        a:hover {
            text-decoration: underline;
            color: #000;
        }
    }
    font-size: 12px;

    link,
    a {
        text-decoration: none;
    }

    link:hover {
        text-decoration: underline;
        color: #000;
    }
    a {
        font-size: $font-weight-bold;
        text-decoration: none;
    }

    a:hover {
        text-decoration: underline;
        color: #000;
    }

    @media (max-width: $screen-s) {
        .css-bottom-details {
            .row {
                .col-md-9 {
                    .flex{
                        flex-direction: column;
                    }
                }
            }
        }
        .header-cont {
            img {
                display: none;
            }
        }
        .css-bottom-details {
            width: 100%;
            margin: 15px 0px 25px 0px;
            display: inline-block;
            h3 {
                margin: 15px 0px;
            }
        }
        .my-2 {
            margin-top: $spacer--semi-medium - 4;
            margin-bottom: $spacer--semi-medium - 4;
        }
        .fs-12 {
            font-size: $font-size-small;
        }

        .flex {
            .p-2 {
                a {
                    display: grid;
                }
            }
        }

        .fs-14 {
            font-size: $font-size-base;
        }
        .f-above-img-sec {
            width: auto;
            margin-top: $spacer--semi-medium - 2;
            border-radius: $spacer--extra-small;
            margin-left: auto;
            background-image: url(/media/product-images/remote-industrial-solar/remote-outdoor-solar-systems/remote-off-grid-solar-power-ground-2.png);
            background-repeat: no-repeat;
            background-position: left bottom;
            background-color: #f0f0f0;
            .col-md-4 {
                a {
                    display: grid;
                    align-content: center;
                    width: 100%;
                    justify-content: center;
                }
            }
        }
    }

    @media (min-width: ($screen-s+1)) {
        .floatright {
            float: right;
        }
        h3 {
            font-size: $font-size-medium;
            font-weight: bold;
            font-family: inherit;
        }
        .flex {
            .p-2 {
                a {
                    display: grid;
                }
            }
        }
        .fs-12 {
            font-size: $font-size-small;
        }
        .fs-14 {
            font-size: $font-size-base;
        }
        .css-bottom-details h3 {
            font-size: $font-size-medium;
            font-weight: bold;
            margin-bottom: $font-size-medium - 1;
            font-family: inherit;
        }
        .f-above-img-sec {
            width: auto;
            margin-top: $spacer--semi-medium - 2;
            border-radius: $spacer--extra-small;
            margin-left: auto;
            background-image: url(/media/product-images/remote-industrial-solar/remote-outdoor-solar-systems/remote-off-grid-solar-power-ground-2.png);
            background-repeat: no-repeat;
            background-position: left bottom;
            background-color: #f0f0f0;
            .col-md-4 {
                a {
                    display: grid;
                    align-content: center;
                    width: 100%;
                    justify-content: center;
                }
            }
        }
    }
}
body.cms-silfab-solar-panels,
body.cms-mission-solar-panels,
body.cms-energy-storage-systems-lg-chem-ess-lg-chem-resu-ess-energy-storage-battery-systems {
    .black-links {
        a {
            color: $black;
            text-decoration: none;

            &:hover {
                text-decoration: underline;
            }
        }
    }
    .cms-page__content {
        .container {
            .mission-solar-panels_bg {
                background-image: url(/media/product-images/SolarPanel/Mission-Solar/Mission-Solar-residential-system-back.jpg);
                margin-right: auto;
                margin-left: auto;
                width: 100%;
                height: 250px;
                position: relative;
                background-repeat: no-repeat;
                background-position: center;
                background-size: cover;
                img.pos_bot {
                    position: absolute;
                    float: left;
                    bottom: 30px;
                    left: 30px;
                    @media all and (max-width: (425px)) {
                        left: 0;
                    }
                }
            }
            .h-500 {
                height: 500px;
            }

            .header-cont {
                h1.home-sub-header-x {
                    font-size: $font-size-super-extra-large + 8;
                    line-height: 1.1;
                    font-weight: $font-weight-normal;

                    @media all and (max-width: (425px)) {
                        font-size: $font-size-extra-large + 4;
                    }
                }
                picture {
                    @media all and (max-width: ($screen-l - 1)) {
                        display: none;
                    }

                    img {
                        margin-left: auto;
                    }
                }
                .col-lg-9 h3 strong {
                    @media all and (max-width: (425px)) {
                        font-size: $font-size-medium + 4;
                    }
                }
            }

            .css-bottom-details {
                h1 {
                    font-size: $font-size-extra-large + 4;
                    @media all and (min-width: (768px)) {
                        font-size: $font-size-super-extra-large + 8;
                    }
                }
                .row {
                    .col-md-4,
                    .col-md-3 {
                        padding: 0 15px 0 15px;
                    }
                }
                @media all and (max-width: (425px)) {
                    .flex.center.text-center {
                        flex-direction: column;
                    }
                }
                .p-2 a {
                    display: inline;
                }

                h3 {
                    font-size: $font-size-medium;
                    font-weight: bold;
                    margin: 15px 0px;
                }
                .pdf-box {
                    @media all and (max-width: (425px)) {
                        width: auto;
                    }

                    h3 {
                        margin: 0 0 10px 0;
                    }
                    a.home-rich-orange-link-small {
                        display: inline;
                    }
                }

                .solar-table.no_border {
                    td,
                    th {
                        vertical-align: middle;
                        border: 1px solid #ddd;
                    }
                }
                .f-above-img-sec {
                    width: 96%;
                    margin-top: 22px;
                    border-radius: 4px;
                    margin-left: auto;
                    background-image: url(/media/product-images/remote-industrial-solar/remote-outdoor-solar-systems/remote-off-grid-solar-power-ground-2.png);
                    background-repeat: no-repeat;
                    background-position: left bottom;
                    background-color: #f0f0f0;

                    .col-md-4.col-sm-12 {
                        font-size: $font-size-small;

                        a {
                            display: inline;
                        }
                    }
                }
                a.system-price {
                    font-family: Segoe, "Segoe UI", "DejaVu Sans",
                        "Trebuchet MS", Verdana, Arial, sans-serif;
                    text-decoration: underline;
                    font-size: $font-size-small;
                    font-weight: bold;
                    color: $dark-blue;
                    p strong {
                        color: $dark-blue;
                    }
                    p strong:hover {
                        color: $black;
                    }
                }
                a.system-price:hover {
                    color: $black;
                }
            }
        }
    }
}
body.cms-solar-panel-mounts-mt-solar-ground-mount,
body.cms-solar-charge-controllers-morningstar-charge-controllers-morningstar-prostar-charge-controllers,
body.cms-solar-panels-jinko-solar-modules,
body.cms-solar-battery-backup-systems-magnum-pae-solar-system-battery-backup-systems,
body.cms-rec-solar-professional-program,
body.cms-solar-batteries-discover-batteries,
body.cms-solar-batteries-relion-lithium-batteries,
body.cms-solar-panels-ameresco-solar-panels,
body.cms-energy-storage-systems-lg-chem-ess,
body.cms-rec-alpha-n-peak-twinpeak-sale,
body.cms-rec-alpha-series-solar-panels-v1,
body.cms-solar-panel-mounts-roof-mounts-snapnrack-series-100,
body.cms-solar-inverter-enphase-enphase-iq-battery-energy-storage,
body.cms-solar-batteries-narada-batteries,
body.cms-solar-batteries-aquion-energy-aspen-ahi-saltwater-battery,
body.cms-solar-batteries-simpliphi-batteries,
body.cms-solar-batteries-deka-fahrenheit-batteries,
body.cms-solar-panels-hyundai-solar-panels,
body.cms-rec-alpha-series-solar-panels,
body.cms-solar-inverter-hiq-solar,
body.cms-energy-storage-systems
{
    .page-main h3{
        font-size: 16px;
    }
 .page-title-header{
    font-size: 40px;
 }   
 .home-sub-header{
    font-size: 24px;
 }
 .page-title{
    font-size: 40px;
 }   
 .home-title-header-x{
    font-size: 24px;
 }   
 .home-sub-header-x{
    font-size: 16px;
 }
 .flex-column {
    flex-direction: column;
}
.flex-row-header{
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
}

.f-above-img-sec
{
    margin-top: 22px;
    border-radius: 4px;
    margin-left: auto;
    background-image: url(/media/product-images/remote-industrial-solar/remote-outdoor-solar-systems/remote-off-grid-solar-power-ground-2.png);
    background-repeat: no-repeat;
    background-position: left bottom;
    background-color: #F0F0F0;
}
.dark-black-links {
    color: $black;
    text-decoration: none;
    font-weight: $font-weight-bold;

    &:hover {
        text-decoration: underline;
        color: $black;
    }
} 
.black-links {
        color: $black;
        text-decoration: none;

        &:hover {
            text-decoration: underline;
        }
}
.orange-links {
        font-weight: bold;
        color: $dark-orange;
        text-decoration: none;

        &:hover {
            text-decoration: underline;
        }
}
.font-weight-bold{
    font-weight: bold;
}
.display-none{
    display: initial;
}
.box{
    border-right: 1px solid #f4f4f4;
}
.cell_gray_grad {
    background-color: #f3f3f3;
    padding: 10px;
    border-radius: 10px;
}
.dark-blue-links {
    color: #000099;
    font-weight: bold;

    &:hover{
        color: black;
    }
}
.dark-blue-links-underline {
    color: #000099;
    font-weight: bold;
    text-decoration: underline;


    &:hover{
        color: black;
        text-decoration: underline;
    }
}
.radial {
    background: radial-gradient(ellipse at center, rgba(255, 255, 255, 0.01) 50%, rgba(125, 185, 232, 0.16) 100%);
}
.fs-16{
    font-size: 16px;
}
.home-sub-header-x{
    font-weight: 600;
}


.rec_head_bg {
    background-image: url(/media/product-images/SolarPanel/REC/Alpha/Alpha-sky.jpg);
    height: 100px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}
.custom-width{
    width: 318px;
}
.css-bottom-details {
    position: relative;
    margin: 15px 15px 25px 15px;
}
.justify-content{
    justify-content: center;
}
.align-center{
    align-items: center;
}
.fs-12{
    font-size: 12px;
}
.list-style{
    list-style-type: unset;
    padding: 0;
}
.all-flex-col{
    a{
    flex-direction: column;
    color: #000099;
    font-weight: bold;
    }
}
.all-flex-col{
    div{
        a{
            flex-direction: column;
            font-weight: bold;
            }
    }
    
}
.all-flex-col-black{
    a{
    flex-direction: column;
    color: black;
    font-weight: bold;
    }
}
.right-sidebar {
    background-color: #f37321;
    padding: 10px;
    color: #fff;
}


.sidebar-links {
    list-style-type: none;
    margin: 0;
    padding: 0;
    background-color: #fff;

    li a {
        display: block;
        color: $black;
        padding: 8px 16px;
        text-decoration: none;
        font-size: $font-size-base;
        border-bottom: 1px solid #ccc;
        line-height: 1.4;

        &:hover {
            background-color: #555;
            color: white;
            text-decoration: none;
        }
    }
}

.custom-flex-row-center{
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
    align-items: center;
}

.no-list-style{
    li{
        padding-top:10px ;
        list-style-type: none;
        font-size: 12px;
    }
}
.rec-body-bg {
    background-image: url(/media/product-images/SolarPanel/REC/Alpha/Alpha-Series-climber-sun-large-web.jpg);
    background-repeat: no-repeat;
    background-position: top center;
    background-size: cover;
    height: 1350px;
    border-radius: 10px;
    overflow: hidden;
}
.img-flex{
    display: flex;
    flex-direction: row-reverse;
}
.brand-box {

text-align: center;
h3 {
    background-color: #ef7e18;
}
}
.custom-img-flex{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
}
.orange{
    color:$dark-orange ;
}
.red{
    color: red;
}
.text-decoration{
    text-decoration: underline;
}
.fs-20{
    font-size: 20px;
}
.fs-30{
    font-size: 30px;
}
.fs-40{
    font-size: 40px;
}
.fs-19{
    font-size: 19px;
}
.fs-24{
    font-size: 24px;
}
.text-white{
    color: white;
}
.fs-15{
    font-size: 15px;
}
.product-wrap-flex{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}
.custom-img-header{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}
.height-100{
    height: 100px;
}

.p-0{
    padding: 0px;
}
.list-style-none{
    li{
        list-style: none;
        font-size: 12px;
        margin-bottom: 10px;
    }
}
table.solar-table {
    margin-bottom: 35px;
}
.solar-table {
    font-size: $font-size-small;

    td {
        border: 1px solid #ddd;
    }
}
.header-cont-absolute {
    position: absolute;
    right: 0;
}
.custom-sub-header{
    font-size: 24px;
}
.f-right{
    float: right;
}
.f-left{
    float: left;
}
.custom-img-cell-grey{
    display: flex;
    justify-content: space-around;
    width: 60%;
    align-items: center;
    padding: 10px;
    flex-wrap: wrap;
}
@media all and (max-width: ($screen-m)) {
    .custom-img-cell-grey{
        width: 100%;
    }
    .home-sub-header{
        font-size: 20px;
    }
    .page-title-header{
        font-size: 28px;
    }   
    .flex-row-header{
        display: flex;
        flex-direction: row-reverse;
        justify-content: flex-end;
    }
    .display-none{
        display: none;
    }
    .img-flex{
        display: flex;
        flex-direction: column-reverse;
        align-items: center;
    }
}
@media all and (max-width: ($screen-s)) {
    .custom-img-cell-grey{
        flex-direction: column;
        width: 100%;
    }
    .f-left{
        float: unset;
        margin-top: 10px;
        margin-bottom: 10px;
    }
    .custom-sub-header{
        font-size: 20px;
    }
    .table-overflow{
        display: block !important;
        overflow-y: scroll !important;
    height: fit-content !important;
    }
    .custom-cell-grey-block{
        width: fit-content !important;
    }
    .rec_head_bg{
        height: 200px;
    }
    .height-100{
        height: 200px;
    }
    .text-center{
        text-align: center;
    }
    .height-200{
        height: 200px;
    }
    .flex-row-col{
        flex-direction: column;
        align-items: center;
    }
    .width-fit{
        width: fit-content;
    }
    .sub-head{
        font-size: 20px;
    }
    .f-right{
        float: unset;
    }
    .fs-20{
        font-size: 20px;
    }
    .fs-40{
        font-size: 24px;
    }
    .flow-root{
        display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
    }
}
}
body.cms-solar-inverter-hiq-solar,
body.cms-solar-charge-controllers-morningstar-charge-controllers-morningstar-prostar-charge-controllers{
    @media all and (max-width: 830px) {
        .f-right{
            float: unset;
        }
    }   
}
body.cms-solar-batteries-relion-lithium-batteries{
@media all and (max-width: ($screen-s)) {
    .display-block{
        display: block;
    }
}   
}
body.cms-rec-alpha-series-solar-panels-v1{
    @media all and (max-width: 830px) { 
        .custom-pd1{
            div{
                    font-size: 12px;
                    img{
                        padding-top:10px ;
                        padding-bottom: 10px;
                    }
                    p{
                        font-size: 12px;
                    }
                }
            }
        }
    }
body.cms-rec-alpha-series-solar-panels{
@media all and (max-width: 830px) { 
    .custom-pd{
        div{
            a{
                font-size: 12px;
                img{
                    padding-top:10px ;
                    padding-right: 10px;
                    padding-bottom: 10px;
                }
            }
        }
    }
}
}

body.cms-solar-batteries-simpliphi-batteries{
    table td {
        width: fit-content;
    }
}

body.cms-rec-alpha-n-peak-twinpeak-sale{
     .rec-body-bg{
        .pdf-box {
            .datasheet-custom-font{
             font-size: 24px; 
             @media all and (max-width: ($screen-s))  {
                font-size: 20px;
             }
            }
        } 
     } 
    @media all and (max-width: 830px) { 
        .mob-view{
            height: fit-content;
        }
    }
@media all and (max-width:($screen-s) ) {
    .mob-view{
        display: flex;
        flex-direction: column-reverse;
        height: fit-content;
    }
}   
}