.product-grid-item {
    margin-bottom: 0;

    .product-grid-item__image-wrapper {
        img.image.product-image-photo {
            object-fit: scale-down;
        }
    }

    &:hover {
        .product-grid-item__image-wrapper {
            &::after {
                background-color: transparent;
            }
            img.image.product-image-photo {
                filter: drop-shadow(2px 4px 6px $black);
                transform: translate(-50%, -50%) scale(1.2);
                transition: all 0.6s ease-in;
            }

            .button.button--tocompare {
                visibility: visible;
                background-color: #f5f5f5;
                &::before {
                    background-color: #f5f5f5;
                }

                &:hover {
                    background-color: $product-grid-item__tocompare-background-color--hover;
                    color: $black;
                    &::before {
                        background-color: $product-grid-item__tocompare-background-color--hover;
                    }
                }
            }
        }
    }

    &__actions {
        justify-content: center;
        @include mq($screen-m) {
            width: 100%;
        }
    }

    &__name {
        font-size: $product-grid-item__name-font-size;
        @include mq($screen-m) {
            font-size: $product-grid-item__name-font-size;
        }
    }

    &__name-link {
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2; /* number of lines to show */
        line-clamp: 2; 
        -webkit-box-orient: vertical;
        text-align: center;
    }

    &__image-wrapper {
        padding: $product-grid-item__image-wrapper-padding;

        .button.button--tocompare {
            visibility: hidden;
            color: $product-grid-item__tocompare-color;
            background-color: transparent;
            transform: rotateX(180deg);
            position: absolute;
            bottom: 0;
            right: 0;
            z-index: 5;
        }
    }
}
