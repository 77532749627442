// Confirm show modal variables

// Inner wrap
$confirm-show-modal__inner-wrap-width                   : 75%;
$confirm-show-modal__inner-wrap-max-width               : 550px;

// Header
$confirm-show-modal__header-padding--bottom             : 1.2rem;
$confirm-show-modal__header-padding--top                : 3rem;
$confirm-show-modal__header-box-shadow                  : 0px 0px 5px rgba(50, 50, 50, 0.75);
$confirm-show-modal__header-background                  : $gray-light;

// Content
$confirm-show-modal__content-padding                    : $spacer--extra-small $spacer--large;

// Footer
$confirm-show-modal__footer-button-background           : $dark-orange;
$confirm-show-modal__footer-button-background--hover    : $black;
$confirm-show-modal__footer-button-color                : $white;
$confirm-show-modal__footer-button-padding              : $spacer 15px;
$confirm-show-modal__footer-padding                     : $spacer--medium $spacer--large;