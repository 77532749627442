/* category-residential-solar-systems-cost */
/* category-power-panels categorypath-solar-components-solar-inverter-power-panels */
body.category-residential-solar-system-cost,
body.category-power-panels.categorypath-solar-components-solar-inverter-power-panels {
    #page-title-heading {
        display: none;
    }
}

/* category-residential-solar-power-systems */
body.category-residential-solar-power-systems {
    #page-title-heading {
        display: none;
    }

    .residential-solar-systems-block {
        .css-bottom-details {
            h4 {
                font-size: $font-size-base;
                font-weight: $font-weight-bold;
                color: $darkest-blue;
            }
        }
    }

    .grid-image-followed-link {
        margin-bottom: $spacer--semi-large;
    }
}

/* category-residential-solar-system */
body.category-residential-solar-system {
    #page-title-heading {
        display: none;
    }

    .message-box {
        width: 420px;

        @media all and (max-width: ($screen-s)) {
            width: 100%;
        }

        .twelve-link-one {
            font-weight: $font-weight-bold;
            text-decoration: none;

            &:hover {
                color: $black-pearl;
                text-decoration: underline;
            }
        }

        .twelve-link-two {
            text-decoration: none;

            &:hover {
                color: $black-pearl;
                text-decoration: underline;
            }
        }
    }

    .h2-shadow {
        font-size: $font-size-small;

        a {
            font-weight: $font-weight-bold;
            text-decoration: none;

            &:hover {
                text-decoration: underline;
                color: $black-pearl;
            }

        }
    }

    .popular-panel-systems {
        width: 100%;
        max-width: 100%;

        td.nopad-vmid {
            padding: $spacer 10px;

            h3 {
                font-weight: $font-weight-bold;
                font-size: $spacer--semi-medium;
            }
        }

        tr.nopad-vmid {
            @media all and (max-width: ($screen-s - 1)) {
                display: grid;
                grid-template-columns: 1fr 1fr;
                gap: 10px;
            }

            td {
                .system-price {
                    display: flex;
                    flex-direction: column;
                    align-items: center;

                    strong {
                        color: $dark-blue;
                        text-decoration: underline;

                        &:hover {
                            color: $black-pearl;
                        }
                    }
                }
            }
        }
    }
}

/* category-low-profile-system-price */
body.category-low-profile-system-price {
    #page-title-heading {
        display: none;
    }

    .css-bottom-details {
        .low-wholesale-prices {
            >strong {
                font-size: $font-size-small;
            }

            >p {
                margin-bottom: $spacer + 2;
            }

            a {
                color: $black;
                font-weight: $font-weight-bold;

                &:hover {
                    color: $black;
                }
            }
        }
    }
}

/* category-commercial-solar-systems */
body.category-commercial-solar-systems {
    #page-title-heading {
        display: none;
    }

    .remove-padding {
        padding: 0;
    }

    .header-cont {
        h3 {
            font-size: $font-size-medium;
        }

        picture {
            display: none;

            img {
                margin-left: auto;
            }

            @media all and (min-width: ($screen-l)) {
                display: block;
            }
        }
    }

    .css-bottom-details {
        .grid-image-followed-para.pricing-grid {
            .col-sm-3 p:first-child {
                min-height: $spacer--extra-large - 3;
            }
        }

        .row.systems-grid {
            .col-sm-3 {
                display: flex;
                flex-direction: column;

                & div:last-child {
                    flex-basis: 100%;
                }
            }
        }

        .cell_gray_grad {
            .flex.center {
                @media all and (max-width: ($screen-m - 1)) {
                    flex-direction: column;
                }
            }

            p strong a {
                text-decoration: none;
                font-weight: $font-weight-bold;
                font-size: $font-size-base;
                color: $black;
            }
        }
    }

    .f-above-img-sec {
        .css-last-one {
            background-image: url(/media/product-images/remote-industrial-solar/remote-outdoor-solar-systems/remote-off-grid-solar-power-ground-2.png);
            padding: $spacer--large - 2;

            .text-left {
                font-size: $font-size-medium;
            }
        }
    }
}

/* category-sloped-roof */
/* category-flat-roof */
/* category-ground-mount */
/* category-solar-carport */
body.category-sloped-roof,
body.category-flat-roof,
body.category-ground-mount,
body.category-solar-carport {
    #page-title-heading {
        display: none;
    }

    .remove-padding {
        padding: 0;
    }

    .header-cont {
        h3 {
            font-size: $font-size-medium;
            font-weight: $font-weight-semi-medium;
        }

        picture {
            display: none;

            img {
                margin-left: auto;
            }

            @media all and (min-width: ($screen-l)) {
                display: block;
            }
        }
    }

    .sloped-roof-bottom {
        margin-left: 0;
    }

    .css-bottom-details {
        a {
            text-decoration: none;
        }
    }

    .comm-projects {
        .projects-list {
            li {
                a {
                    color: $black;
                }
            }

            @media all and (max-width: ($screen-m - 1)) {
                flex-direction: column;
            }
        }
    }
}

/* remote-industrial-solar-block */
body.category-remote-industrial-solar {
    #page-title-heading {
        display: none;
    }

    .header-cont {
        img {
            float: right;

            @media all and (max-width: ($screen-l - 1)) {
                display: none;
            }
        }
    }

    .container {
        width: 96%;
        padding-left: 0;
        padding-right: 0;
        margin-left: auto;
        margin-right: auto;

        .sloped-roof {
            margin-top: $spacer--medium - 1;
            margin-bottom: $spacer--semi-large;
        }
    }

    .home-title-header-x {
        font-size: $font-size-super-extra-large - 2;
        font-weight: $font-weight-normal;
        line-height: 33px;
        font-style: normal;
        color: rgb(51, 51, 51);

        @media all and (max-width: ($screen-m - 1)) {
            font-size: $font-size-extra-large + 1;
        }
    }

    .home-sub-header-x {
        font-size: $font-size-medium;
        font-family: inherit;
        font-weight: 600;
        line-height: 18px;
        font-style: normal;
        color: #000033;
    }

    .featured-cat-box {
        h3 {
            margin-top: $spacer--medium - 1;
            text-align: center;
        }

        strong {
            font-weight: $font-weight-bold;
            color: #ef7e18;
            line-height: $font-line-height;
            font-size: $font-size-small + 1;
        }

        img {
            vertical-align: top;
            text-align: center;
            max-width: 100%;
            max-height: 100%;
            margin: auto auto;
        }
    }

    .featured-desc {
        text-align: left;
        padding-left: $spacer--medium - 1;

        h3 {
            font-size: $font-size-small + 1;
            font-weight: $font-weight-bold;
            line-height: 14.9px;
            text-align: left;
        }
    }

    .css-bottom-details {
        position: relative;
        margin: 15px 15px 25px 15px;

        .col-sm-6 {
            width: 50%;
            float: left;

            @media all and (max-width: ($screen-l)) {
                width: 100%;
            }

            img {
                vertical-align: top;
                text-align: center;
                max-width: 100%;
                max-height: 100%;
                margin: auto auto;
            }

            p {
                font-size: $font-size-base;
                margin-bottom: $spacer + 2;
            }

            ul {
                list-style: circle;
                font-size: $font-size-small;
                margin-bottom: 2.5rem;

                li {
                    margin-bottom: $spacer + 2;
                }
            }
        }

        h3 {
            font-size: $font-size-medium;
            font-weight: bold;
            margin-bottom: $spacer--medium - 1;
            font-family: inherit;
        }

        h5 {
            font-size: $font-size-base;
            font-weight: bold;
            margin-top: $spacer--semi-medium + 1;
        }
    }

    .css-bottom-details {
        h4 {
            font-size: $font-size-base;
            font-weight: bold;
            margin: 25px 0px 10px;
            color: #000033;
        }

        h2 {
            font-size: $font-size-super-extra-large - 2;
            font-weight: $font-weight-normal;

            @media all and (max-width: ($screen-m - 1)) {
                font-size: $font-size-extra-large + 1;
            }
        }
    }

}

/* category-mapps-pole-mount */
body.category-mapps-pole-mount {
    #page-title-heading {
        display: none;
    }

    .title-container {
        picture {
            padding-left: 0;
            margin-left: 0;

            @media all and (max-width: ($screen-s - 1)) {
                float: none;
            }

            img {
                margin: 0;

                @media all and (max-width: ($screen-s - 1)) {
                    float: none;
                }
            }
        }

        .header-cont {
            @media all and (max-width: ($screen-s - 1)) {
                margin-top: 0;
                padding-top: 0;
            }

            h3.dark-text {
                font-size: $font-size-medium;
            }
        }
    }

    .header-cont {
        .home-sub-header-x {
            font-family: inherit;
            font-weight: $font-weight-semi-medium;
            font-size: $font-size-medium;
            line-height: 18px;
            color: $darkest-blue;
            font-style: normal;
        }
    }

    .css-bottom-details {

        .vdc-12,
        .vdc-24 {
            a {
                display: flex;
                flex-direction: column;
            }
        }

        .table-responsive {
            overflow-x: auto;
            min-height: .01%;

            .solar-table {
                margin-bottom: $spacer--large + 3;
                width: 100%;
                border-collapse: collapse;
                border-spacing: 0;
                max-width: 100%;

                a {
                    color: $darker-blue;
                    text-decoration: underline;
                    font-family: inherit;
                    font-size: $font-size-small;
                    font-weight: $font-weight-bold;
                }

                td {
                    padding: 8px 10px;
                    vertical-align: top;
                }

                td,
                th {
                    border: $border-width-base $border-style-base $gray-light;
                }
            }
        }
    }
}

/* mapps-pole-mount-block */
body.category-mapps-pole-mount {
    #page-title-heading {
        display: none;
    }

    .home-title-header-x {
        font-size: $font-size-super-extra-large - 2;
        font-weight: $font-weight-normal;
        line-height: 33px;
        font-style: normal;
        color: rgb(51, 51, 51);

        @media all and (max-width: ($screen-m - 1)) {
            font-size: $font-size-extra-large + 1;
        }
    }

    .css-bottom-details {
        .row {
            width: 100%;

            .col-sm-10 {
                padding-left: 15px;
                padding-right: 15px;
            }

            .col-sm-2 {
                text-align: -webkit-center;
            }
        }

        h4 {
            font-size: 14px;
            font-weight: bold;
            margin: 25px 0px 10px;
            color: #000033;
        }

        .flex.center.text-center {
            display: flex;
            text-align: center;
            justify-content: center;

            @media all and (max-width: ($screen-s)) {
                flex-direction: column;
            }

            .p-2 {
                margin-left: auto;
                margin-right: auto;

                a {
                    text-align: center;
                    display: block;
                }
            }
        }

        .flex.center {
            display: flex;
            justify-content: center;

            @media all and (max-width: ($screen-s)) {
                flex-direction: column;
            }

            .p-2 {
                margin-left: auto;
                margin-right: auto;

                a {
                    text-align: center;
                    display: block;
                }
            }
        }

        .mt-2 {
            a {
                text-align: center;
                display: block;
            }
        }

        .col-sm-4 {
            width: 33.33%;
            float: left;
            position: relative;
            padding-left: 15px;
            padding-right: 15px;

            @media all and (max-width: ($screen-s)) {
                width: 100%;
            }

        }

        .col-sm-6 {
            width: 50%;
            float: left;
            position: relative;
            padding-left: 15px;
            padding-right: 15px;

            @media all and (max-width: ($screen-s)) {
                width: 100%;
            }
        }

        .col-sm-6.text-center {
            width: 50%;
            float: left;
            position: relative;
            padding-left: 15px;
            padding-right: 15px;
            text-align: center;
            margin-left: auto;
            margin-right: auto;

            @media all and (max-width: ($screen-s)) {
                width: 100%;
            }

            img {
                margin-left: auto;
                margin-right: auto;
            }

        }

        .col-sm-4.mt-3.pt-2.text-center {
            width: 33.33%;
            float: left;
            position: relative;
            padding-left: 15px;
            padding-right: 15px;
            margin-top: 30px;
            padding-top: 20px;
            text-align: center;
            margin-left: auto;
            margin-right: auto;

            @media all and (max-width: ($screen-s)) {
                width: 100%;
            }
        }

        .col-sm-4.text-center {
            width: 33.33%;
            float: left;
            position: relative;
            padding-left: 15px;
            padding-right: 15px;
            text-align: center;

            @media all and (max-width: ($screen-s)) {
                width: 100%;
            }

            img {
                margin-left: auto;
                margin-right: auto;

            }

        }

        .col-sm-12 {
            width: 100%;
            float: left;
            position: relative;
            padding-left: 15px;
            padding-right: 15px;
        }

        .col-sm-8 {
            width: 66.66%;
            float: left;
            position: relative;
            padding-left: 15px;
            padding-right: 15px;

            @media all and (max-width: ($screen-s)) {
                width: 100%;
            }
        }

        .col-sm-9 {
            width: 75%;
            float: left;
            position: relative;
            padding-left: 15px;
            padding-right: 15px;

            @media all and (max-width: ($screen-s)) {
                width: 100%;
            }
        }

        .col-sm-3 {
            width: 25%;
            float: left;
            position: relative;
            padding-left: 15px;
            padding-right: 15px;

            @media all and (max-width: ($screen-s)) {
                width: 100%;
            }
        }

        .pl-3 {
            padding-left: 30px;
        }

        ul {
            list-style: circle;
            margin-bottom: 25px;
        }

        .cell_gray_grad.row.text-center {
            display: block;
        }
    }
}

/* category-solar-panels */
body.category-solar-panels {
    #page-title-heading {
        display: none;
    }

    .panels-model {
        flex-direction: column;
        align-items: center;
    }
}

/* category-rec */
body.category-rec {
    #page-title-heading {
        display: none;
    }

    .twelve-px-verdana {
        min-width: 100%;
        margin-top: $spacer--semi-medium;
        margin-bottom: $spacer--semi-medium;

        p {
            font-size: $font-size-base;
            line-height: 30px;

            a.twelve-link {
                text-decoration: none;
                font-weight: bold;
            }
        }

        .system-price {
            background: #ef7e17;
            padding: $spacer + 2;
            color: $white;
            text-decoration: none;
            font-weight: $font-weight-bold;
            font-family: Segoe, "Segoe UI", "DejaVu Sans", "Trebuchet MS", Verdana, Arial, sans-serif;
        }

        .col-sm-9 {
            p {
                margin: $spacer--small + 2 0;
            }
        }

        .col-sm-3 {
            div {
                background-image: url(/media/product-images/SolarPanel/REC/REC-solar-panel-sky-back.png);
                background-position: right top;
                background-repeat: repeat-y;
                max-width: 218px;
            }

        }
    }
}

/* category-trina-solar */
/* category-lg */
/* category-canadian-solar */
/* category-q-cells */
/* category-solarland-solar-panels */
/* category-panasonic-solar-panels */
/* category-solaria-solar-modules */
/* category-hyundai-solar-modules */
/* category-ameresco */
/* category-silfab-solar-modules */
/* category-mission-solar-modules */
/* category-jinko */
/* category-yingli-solar */
/* category-trina-solar */
/* category-hanwha-solar */
/* category-solaria-powerxt */
/* category-panasonic */
/* category-mission-solar */
/* category-silfab */
/* category-isofoton */
body.category-jinko,
body.category-mission-solar-modules,
body.category-silfab-solar-modules,
body.category-ameresco,
body.category-hyundai-solar-modules,
body.category-solaria-solar-modules,
body.category-panasonic-solar-panels,
body.category-solarland-solar-panels,
body.category-q-cells,
body.category-canadian-solar,
body.category-lg,
body.category-trina-solar,
body.category-yingli-solar,
body.category-solaria-powerxt,
body.category-panasonic,
body.category-mission-solar,
body.category-silfab,
body.category-isofoton,
body.category-hanwha-solar {
    #page-title-heading {
        display: none;
    }

    .about-panel-manufacturer {
        margin-top: $spacer--medium;
        margin-bottom: $spacer--extra-large + 2;

        a {
            text-decoration: none;
        }

        h3.h3-shadow {
            margin-bottom: $spacer--medium - 1;
            color: $black;
            font-size: $font-size-medium;
            font-weight: bold;
        }
    }
}

/* category-sloped-roof-mounted */
/* category-flat-roof-mounted */
/* category-solar-carport-mounted */
body.category-sloped-roof-mounted,
body.category-flat-roof-mounted,
body.category-solar-carport-mounted {
    #page-title-heading {
        display: none;
    }

    .padding-10 {
        padding: 0 10px;
    }

    .cell_gray_grad {
        &.with-orange-ul-links {
            ul {
                a {
                    font-weight: $font-weight-base;
                }
            }
        }
    }

    .css-last-one {
        background-image: url('/media/product-images/remote-industrial-solar/remote-outdoor-solar-systems/remote-off-grid-solar-power-ground-2.png');
        background-position: center;
    }

    .top-pad-tab-mobile {
        @media all and (max-width: ($screen-m - 1)) {
            margin-top: $spacer--medium - 1;
        }
    }
}

/* category-ground-mounted */
body.category-ground-mounted {
    #page-title-heading {
        display: none;
    }

    .ground-mounted-solar-systems-title {
        font-size: $font-size-super-extra-large - 2;
        margin-bottom: $spacer + 2;
    }

    .top-pad-tab-mobile {
        @media all and (max-width: ($screen-m - 1)) {
            margin-top: $spacer--medium - 1;
        }
    }
}

/* category-rec-solar-panel-system-prices */
/* category-solaria-solar-panel-system-prices */
/* category-canadian-solar-panel-system-prices */
/* category-lg-solar-panel-system-prices */
/* category-panasonic-solar-panel-system-prices */
/* category-q-peak-solar-panel-system-prices */
/* category-mission-solar-systems */
/* category-silfab-solar-systems */
body.category-rec-solar-panel-system-prices,
body.category-solaria-solar-panel-system-prices,
body.category-canadian-solar-panel-system-prices,
body.category-lg-solar-panel-system-prices,
body.category-panasonic-solar-panel-system-prices,
body.category-q-peak-solar-panel-system-prices,
body.category-mission-solar-systems,
body.category-silfab-solar-systems {
    #page-title-heading {
        display: none;
    }

    .remove-padding-ul {
        padding: 0;
    }

    .systems-options-column {
        a {
            flex-direction: column;
            align-items: center;
        }
    }
}

/* category-solar-panel */
body.category-solar-panel {
    #page-title-heading {
        display: none;
    }

    .header-cont {
        .page-title-header {
            font-size: $font-size-super-extra-large + 8;
            margin-bottom: $spacer--medium + 4;

            @media all and (max-width: ($screen-m - 1)) {
                font-size: $font-size-extra-large + 4;
            }

            margin-bottom: 0;
        }

        .home-sub-header {
            margin-bottom: $spacer + 2;

            @media all and (max-width: ($screen-m - 1)) {
                font-size: $font-size-large + 2;
            }
        }
    }

    .li-mn-bx {
        .system-price {
            color: $dark-orange;
        }
    }

    .solar-tb-hd {
        border-top-left-radius: $spacer + 1;
        border-top-right-radius: $spacer + 1;
        background-color: $extra-extra-faint-blue;
        padding: $spacer - 1;
        margin: 20px auto;
        max-width: $screen-m - 68;
        justify-content: space-between;

        @media all and (max-width: ($screen-m - 1)) {
            justify-content: center;
        }

        padding-right: $spacer--medium+4;
        padding-left: $spacer--medium+4;

        .solar-tb-hd-rt {
            max-width: $screen-xs - 150;
            order: 2;

            @media all and (max-width: ($screen-m - 1)) {
                order: 0;
            }

            margin-top: $spacer--medium;
            border: 1px solid #E7C44A;
            padding: $spacer;

            .ten-link {
                font-size: $font-size-extra-small;
            }
        }

        .solar-tb-hd-lt {
            margin-top: $spacer--semi-medium + 2;
            text-align: left;

            @media all and (max-width: ($screen-m - 1)) {
                text-align: center;
            }

            .twelve-link {
                flex-direction: column;
            }
        }

        a {
            color: $dark-orange;
        }
    }

    .table-responsive {
        table {
            width: 100%;
            font-size: $font-size-small;

            a {
                color: $dark-orange;
            }

            th,
            td {
                padding: $spacer $spacer + 2
            }
        }
    }

    .home-title-header-x {
        font-size: $font-size-extra-large;
    }

    .h2-shadow {
        font-size: $font-size-large + 2;
    }
}

/* category-72-cell-solar-panels */
body.category-72-cell-solar-panels {
    #page-title-heading {
        display: none;
    }

    .header-cont {
        .home-sub-header-x {
            font-size: $font-size-medium;
        }
    }

    .solar-table {
        font-size: $font-size-small;

        h3 {
            font-size: $font-size-medium;
        }
    }

    .category-view {
        a {
            display: initial;
        }
    }

    .flex.center.text-center {
        @media all and (max-width: ($screen-m - 1)) {
            flex-direction: column;
        }
    }
}

/* category-rec-n-peak-solar-panels */
body.category-rec-n-peak-solar-panels {
    #page-title-heading {
        display: none;
    }

    .category-cms {
        .container.p-3 {
            @media all and (max-width: ($screen-m - 1)) {
                margin: 0;
            }
        }
    }

    .header-cont {
        .home-sub-header-x {
            font-size: $font-size-super-extra-large + 8;

            @media all and (max-width: ($screen-m - 1)) {
                font-size: $font-size-extra-large + 4;
            }
        }
    }

    .css-bottom-details {
        h3 {
            font-size: $font-size-medium;
            font-weight: bold;
        }

        .cell_gray_grad {
            @media all and (max-width: ($screen-m - 1)) {
                margin: 0;
            }
        }

        .pdf-box {
            @media all and (max-width: ($screen-m - 1)) {
                width: auto;
            }
        }
    }

    .table-responsive {
        table {
            font-size: $font-size-small;
        }
    }

    .text-decoration-none {
        text-decoration: none;
    }

    .category-view {
        a {
            display: initial;
        }
    }

    p {
        @media all and (max-width: ($screen-m - 1)) {
            margin-top: $spacer + 2;
            margin-bottom: $spacer + 2;
        }
    }

}

/* category-mapps-outdoor-battery-enclosures */
body.category-mapps-outdoor-battery-enclosures {
    #page-title-heading {
        display: none;
    }

    .title-container {
        picture {
            img {
                margin: 0;
            }
        }

        .header-cont {
            .home-sub-header-x {
                font-size: $font-size-medium;
                font-weight: $font-weight-semi-medium;
            }
        }
    }

    .images-with-titles {
        a {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            font-size: $font-size-small;
        }
    }

    .featured-cat-box {
        text-align: center;

        img {
            margin-left: auto;
            margin-right: auto;
        }
    }

    .text-center-on-mobile {
        @media all and (max-width: ($screen-m - 1)) {
            text-align: center;

            img {
                margin-left: auto;
                margin-right: auto;
            }
        }
    }
}

/* category-large-battery-enclosures */
body.category-large-battery-enclosures {
    #page-title-heading {
        display: none;
    }
}

/* category-solar-system-design */
body.category-solar-system-design {
    #page-title-heading {
        display: none;
    }

    .header-cont {
        .page-title-header {
            font-size: $font-size-super-extra-large + 8;
            margin-bottom: $spacer--medium + 4;
            line-height: 1.1;

            @media all and (max-width: ($screen-m - 1)) {
                font-size: $font-size-extra-large + 4;
            }
        }

        .home-sub-header {
            margin-bottom: $spacer + 2;
            line-height: 1.1;

            @media all and (max-width: ($screen-m - 1)) {
                font-size: $font-size-large + 2;
            }
        }
    }

    .section-title {
        font-size: $font-size-extra-large;
        line-height: 1.1;

        @media all and (max-width: ($screen-m - 1)) {
            font-size: $font-size-large + 2;
        }
    }

    h4.section-title {
        font-size: $font-size-large + 1;
    }
}

/* category-solar-battery-backup-systems */
body.category-solar-battery-backup-systems {
    #page-title-heading {
        display: none;
    }

    .header-cont {
        .home-sub-header-x {
            font-size: $font-size-medium;
            font-weight: $font-weight-semi-medium;
        }
    }
}

/* category-solar-components */
body.category-solar-components {
    #page-title-heading {
        display: none;
    }

    .category-cms {
        overflow-x: auto;
    }

    .twelve-link {
        text-decoration: none;
        font-weight: bold;
    }

    .black-twelve-link {
        display: initial;
        font-size: $font-size-small;
    }

    a {
        &:hover {
            color: $black;
            text-decoration: underline;
        }
    }
}

/* category-off-grid-solar-panels categorypath-solar-components-off-grid-solar-panels */
/* category-ses */
/* category-vls */
body.category-vls,
body.category-ses,
body.category-off-grid-solar-panels.categorypath-solar-components-off-grid-solar-panels {
    #page-title-heading {
        display: none;
    }

    .category-view {
        padding: 12px 10px 0;
        margin-bottom: $spacer--large - 2;
    }

    .eleven-link {
        text-decoration: none;
        font-weight: bold;
    }

    .panels-model {
        text-decoration: none;
    }

    .read_more {
        font-size: $font-size-small;
    }

    a {
        &:hover {
            color: $black;
            text-decoration: underline;
        }
    }

    p {
        line-height: 1.4;
    }
}

/* category-view-all */
/* category-solar-electric-supply */
body.category-solar-electric-supply,
body.category-view-all {
    #page-title-heading {
        display: none;
    }

    .page-title-header {
        font-size: $font-size-super-extra-large + 8;
        margin-bottom: $spacer--medium + 4;
        line-height: 1.1;

        @media all and (max-width: ($screen-m - 1)) {
            font-size: $font-size-extra-large + 4;
        }
    }

    .css-bottom-details {
        h3 {
            font-size: $font-size-medium;
            font-weight: bold;
            margin-bottom: $spacer--medium - 1;
            font-family: inherit;
        }

        h4 {
            font-size: $font-size-base;
            font-weight: bold;
        }

        p {
            line-height: 1.4;
        }
    }

    .roof-cont {
        border: 1px solid #ddd;
        padding: 20px 0px;
        margin-bottom: $spacer--medium + 4;

        .roof-mounted {
            display: inline-block;
            text-align: center;
            width: 100%;
            margin-bottom: $spacer--medium + 4;

            p {
                margin-top: $spacer--medium - 1;

                a {
                    color: $dark-orange;
                    text-decoration: none;
                    font-weight: $font-weight-semi-medium;

                    &:hover {
                        color: $dark-blue;
                    }
                }
            }
        }

        h4 {
            margin-bottom: $spacer + 2;
        }
    }

    .roof-bottom {
        margin-bottom: $spacer--large - 2;
        border: 1px solid #ddd;
        background: #f6f6f6;

        .roof-left {
            padding: 15px 0px;
            text-align: left;

            a {
                text-decoration: none;

                &:hover {
                    text-decoration: underline;
                    color: $black;
                }
            }
        }

        .roof-right {
            a {
                display: initial;
                text-decoration: underline;
                color: $dark-blue;

                img {
                    padding: 20px 0px;
                    margin: 0px auto;
                    display: block;
                }

                &:hover {
                    color: $black;
                }
            }
        }
    }

    .row {
        margin-right: auto;
        margin-left: auto;
    }

    .font-size-small {
        font-size: $font-size-small;
    }
}

/* category-fronius */
/* category-sunny-boy */
/* category-pv-powered */
/* category-solaron */
/* category-solectria */
/* category-enphase categorypath-solar-components-solar-inverter-enphase */
/* category-magnum */
/* category-sma */
/* category-outback-fx-series */
/* category-morningstar-suresine */
/* category-solaredge-inverters */
/* category-certainteed */
/* category-schneider-electric-conext */
/* category-solar-charge-controllers */
/* category-morningstar-charge-controllers */
/* category-morningstar-accessories */
/* category-outback-flexmax-charge-controllers */
/* category-gel-batteries */
/* category-deka-gel-batteries */
/* category-outback-flexware categorypath-solar-components-solar-inverter-power-panels-outback-flexware*/
/* category-xantrex-xw-inverter-system categorypath-solar-components-solar-inverter-power-panels-xantrex-xw-inverter-system*/
/* category-sunny-island */
/* category-sunny-island-multicluster */
/* category-sunny-tripower */
/* category-outback-sealed-turbo */
/* category-outback-vented */
/* category-outback-grid-tie-sealed-turbo */
/* category-outback-grid-tie-vented */
/* category-conext-xw-accessories */
/* category-solaredge-power-optimizers */
/* category-solar-accessories */
/* category-enphase categorypath-solar-components-solar-accessories-enphase*/
/* category-relion-lithium */
/* category-discover-aes-battery */
/* category-simplifi-lithium-ion-batteries */
/* category-narada */
/* category-concorde-sun-xtender */
/* category-solar-combiners */
/* category-outback-power-combiner */
/* category-battery-enclosures */
/* category-midnite-solar-mnpv-combiner */
/* category-power-panels categorypath-solar-components-power-panels */
/* category-outback-flexware categorypath-solar-components-power-panels-outback-flexware */
/* category-xantrex-xw-inverter-system */
/* category-flooded-batteries */
/* category-gnb-absolyte-batteries */
/* category-solar-batteries categorypath-solar-components-solar-batteries */
/* category-top-of-pole-mounts */
/* category-lithium-batteries */
/* category-agm-batteries */
/* category-deka-agm-batteries */
/* category-deka-solar-flooded-system */
/* category-deka-unigy */
/* category-samsung */
/* category-yingli-solar */
/* category-bp-solar */
/* category-solarex */
/* category-shell */
/* category-siemens */
/* category-day-4 */
/* category-kaneka */
/* category-sanyo */
/* category-kyocera */
/* category-perlight-solar */
/* category-our-featured-products */
body.category-deka-unigy,
body.category-deka-solar-flooded-system,
body.category-deka-agm-batteries,
body.category-agm-batteries,
body.category-lithium-batteries,
body.category-top-of-pole-mounts,
body.category-solar-batteries.categorypath-solar-components-solar-batteries,
body.category-gnb-absolyte-batteries,
body.category-flooded-batteries,
body.category-xantrex-xw-inverter-system-xantrex.categorypath-solar-components-power-panels-xantrex-xw-inverter-system,
body.category-outback-flexware.categorypath-solar-components-power-panels-outback-flexware,
body.category-power-panels.categorypath-solar-components-power-panels,
body.category-midnite-solar-mnpv-combiner,
body.category-battery-enclosures,
body.category-outback-power-combiner,
body.category-solar-combiners,
body.category-concorde-sun-xtender,
body.category-narada,
body.category-simplifi-lithium-ion-batteries,
body.category-discover-aes-battery,
body.category-relion-lithium,
body.category-enphase.categorypath-solar-components-solar-accessories-enphase,
body.category-solar-accessories,
body.category-solaredge-power-optimizers,
body.category-conext-xw-accessories,
body.category-outback-sealed-turbo,
body.category-outback-grid-tie-vented,
body.category-outback-grid-tie-sealed-turbo,
body.category-outback-vented,
body.category-sunny-tripower,
body.category-sunny-island-multicluster,
body.category-sunny-island,
body.category-xantrex-xw-inverter-system,
body.category-outback-flexware.categorypath-solar-components-solar-inverter-power-panels-outback-flexware,
body.category-deka-gel-batteries,
body.category-gel-batteries,
body.category-outback-flexmax-charge-controllers,
body.category-morningstar-accessories,
body.category-morningstar-charge-controllers,
body.category-solar-charge-controllers,
body.category-schneider-electric-conext,
body.category-certainteed,
body.category-solaredge-inverters,
body.category-morningstar-suresine,
body.category-outback-fx-series,
body.category-sma,
body.category-magnum,
body.category-enphase.categorypath-solar-components-solar-inverter-enphase,
body.category-solectria,
body.category-solaron,
body.category-pv-powered,
body.category-sunny-boy,
body.category-fronius,
body.category-samsung,
body.category-yingli-solar,
body.category-bp-solar,
body.category-solarex,
body.category-shell,
body.category-siemens,
body.category-day-4,
body.category-kaneka,
body.category-our-featured-products,
body.category-perlight-solar,
body.category-kyocera,
body.category-sanyo {
    #page-title-heading {
        display: none;
    }

    .read_more {
        font-size: $font-size-small;
    }

    .category-image {
        margin-bottom: $spacer--extra-small + 1;
    }
}

body.category-xantrex-xw-inverter-system-xantrex-xw-inverter-system {
    .category-description {
        h3 {
            font-size: $font-size-extra-large;
            font-weight: $font-weight-base;
            margin-bottom: $spacer + 2;

            @media all and (max-width: ($screen-m - 1)) {
                font-size: $font-size-large + 2;
            }
        }
    }
}

body.category-outback-power-combiner,
body.category-concorde-sun-xtender,
body.category-discover-aes-battery,
body.category-conext-xw-accessories,
body.category-outback-grid-tie-sealed-turbo,
body.category-sunny-tripower,
body.category-schneider-electric-conext,
body.category-certainteed {
    .category-description {
        font-size: $font-size-small;
    }
}



body.category-battery-enclosures {
    .category-description {
        .ten-link {
            color: $darker-blue;
            text-decoration: underline;
            text-transform: capitalize;
            font-size: $font-size-extra-small;
            text-shadow: 1px 1px 1px #d4d4d4;
        }
    }
}

body.category-enphase.categorypath-solar-components-solar-inverter-enphase {
    .category-description {
        h3 {
            line-height: 1.1;
            font-size: $font-size-extra-large;
            margin-top: 0;
            margin-bottom: $spacer + 2;

            @media all and (max-width: ($screen-s)) {
                font-size: $font-size-large + 2;
            }
        }

        .read_more {
            font-size: $font-size-base;
        }
    }
}

/* category-solar-inverter */
body.category-lithium-batteries,
body.category-solar-inverter {
    #page-title-heading {
        display: none;
    }

    .category-description {
        font-size: $font-size-small;

        .inverters-container {
            max-width: 654px;
            margin-left: auto;
            margin-right: auto;
        }
    }

    .twelve-link {
        font-weight: bold;
    }
}

body.category-lithium-batteries {
    .category-description {
        .ten-link {
            color: $darker-blue;
            text-decoration: underline;
            text-transform: capitalize;
            font-size: 10px;
            text-shadow: 1px 1px 1px #d4d4d4;
        }

        .inverters-container {
            max-width: 582px;

            .bot_text-cont {
                @media all and (max-width: ($screen-s - 1)) {
                    margin-bottom: $spacer--medium + 4;
                }

                .system-price {
                    flex-direction: column;
                    color: $dark-blue;
                    text-decoration: underline;
                    font-family: Segoe, "Segoe UI", "DejaVu Sans", "Trebuchet MS", Verdana, Arial, sans-serif;
                    font-size: $font-size-small;
                    font-weight: bold;

                    &:hover {
                        color: $black;
                    }

                    img {
                        margin-bottom: $spacer - 1;
                        margin-left: auto;
                        margin-right: auto;
                    }
                }
            }
        }
    }
}

/* category-morningstar-charge-controllers */
body.category-morningstar-charge-controllers {
    .eleven-link {
        font-weight: bold;
    }

    .category-view {
        @media all and (min-width: ($screen-m)) {
            padding-bottom: 0;
        }
    }
}

/* category-solar-battery-backup-storage-systems */
/* category-carport-mounts */
/* category-ground-mounts */
/* category-roof-mounts */
/* category-solar-panel-mounts */
body.category-solar-battery-backup-storage-systems,
body.category-carport-mounts,
body.category-ground-mounts,
body.category-roof-mounts,
body.category-solar-panel-mounts {
    #page-title-heading {
        display: none;
    }
}

body.category-roof-mounts,
body.category-solar-panel-mounts {
    .featured-cat-box {
        @media all and (max-width: ($screen-m - 1)) {
            display: flex;
            flex-direction: column;
            align-items: center;
        }
    }
}

/* category-ironridge - Roof Mounts */
/* category-ironridge - Ground Mounts */
body.category-ironridge {
    #page-title-heading {
        display: none;
    }

    .header-cont {
        .ironridge-image-column {
            display: flex;
            align-items: center;
        }
    }

    .box {
        border-right: 1px solid #f4f4f4;

        @media all and (max-width: ($screen-m)) {
            border-right: none;
        }
    }

    .col-sm-3 {
        padding-right: $spacer--small + 3;
        padding-left: $spacer--small + 3;
    }

    hr {
        border: 0;
        border-top: 1px solid #d1d1d1;
        margin-bottom: $spacer--medium + 4;
        margin-top: $spacer--medium + 4;
    }

    .featured-cat-box {
        ol {
            padding-left: 0;
        }

        h3 {
            font-size: $font-size-medium;
            font-weight: bold;
        }
    }

    .mt-0 {
        margin-top: 0;
    }

    .system-price {
        flex-direction: column;
        color: $dark-blue;
        text-decoration: underline;
        font-family: Segoe, "Segoe UI", "DejaVu Sans", "Trebuchet MS", Verdana, Arial, sans-serif;
        font-size: $font-size-small;
        font-weight: bold;

        &:hover {
            color: $black;
        }

        img {
            margin-bottom: $spacer - 1;
            margin-left: auto;
            margin-right: auto;
        }
    }

    p {
        margin-bottom: $spacer + 2;
    }

    .mid-gray-container {
        display: flex;
        flex-direction: row;
        justify-content: center;

        h3.large-h3 {
            font-size: $font-size-extra-large;

            @media all and (max-width: ($screen-s - 1)) {
                font-size: $font-size-extra-large - 4;
            }
        }

        .col-sm-12 {
            >a.dark-orange-links {
                display: flex;
                flex-direction: column;

                strong {
                    color: $dark-orange;
                }
            }
        }
    }

    .remove-padding {
        padding: 0;
    }

    .padding-10 {
        padding: 0 10px;
    }
}

/* category-tile-hook */
/* category-quick-hook */
/* category-multi-pole */
/* category-upsolar */
/* category-orion */
/* category-renusol */
body.category-renusol,
body.category-orion,
body.category-upsolar,
body.category-multi-pole,
body.category-quick-hook,
body.category-tile-hook {
    #page-title-heading {
        display: none;
    }

    .right-sidebar {
        font-size: $font-size-medium;
        font-weight: bold;
        margin-bottom: $spacer--medium - 1;
        background-color: #f37321;
        padding: $spacer + 2;
        color: $white;
    }

    .sidebar-links {
        list-style-type: none;
        margin: 0;
        padding: 0;
        background-color: #f1f1f1;

        li a {
            display: block;
            color: #000;
            padding: 8px 16px;
            text-decoration: none;
            font-size: $font-size-base;
            border-bottom: 1px solid #ccc;

            &:hover {
                background-color: #555;
                color: white;
            }
        }
    }

    .box {
        border-right: 1px solid #f4f4f4;

        @media all and (max-width: ($screen-m)) {
            border-right: none;
        }
    }
}

body.category-renusol,
body.category-orion,
body.category-upsolar,
body.category-multi-pole,
body.category-quick-hook {
    .sidebar-links {
        background-color: white;

        li {
            margin-bottom: $spacer--small + 2;
        }
    }

    .mobile-flex-column {
        @media all and (max-width: ($screen-s - 1)) {
            flex-direction: column;
        }

        img {
            min-width: 200px;
            min-height: 50px;
        }
    }

    .flex-column-mobile {
        @media all and (max-width: ($screen-s - 1)) {
            flex-direction: column;
        }
    }
}

body.category-upsolar {
    .sidebar-links {
        li a {
            font-weight: bold;
        }
    }

    .font-size-small {
        font-size: $font-size-small;
    }
}

body.category-quick-hook {
    .image-right-md {
        @media all and (min-width: ($screen-l + 1)) {
            margin-left: auto;
        }

        @media all and (max-width: ($screen-l)) {
            flex-basis: 100%;
            max-width: 100%;
        }
    }
}

/* category-schletter */
/* category-snapnrack */
/* category-groundtrac */
/* category-rooftrac */
body.category-rooftrac,
body.category-groundtrac,
body.category-snapnrack,
body.category-schletter {
    #page-title-heading {
        display: none;
    }

    .font-size-small {
        font-size: $font-size-small;
    }

    .d-inline {
        display: inline;
    }

    .system-price {
        flex-direction: column;
        color: $dark-blue;
        text-decoration: underline;
        font-family: Segoe, "Segoe UI", "DejaVu Sans", "Trebuchet MS", Verdana, Arial, sans-serif;
        font-size: $font-size-small;
        font-weight: bold;

        &:hover {
            color: $black;
        }
    }

    .ordered-list-small {
        list-style-type: none;
        margin-bottom: $spacer--semi-medium + 1;

        li {
            font-size: $font-size-small;
            margin-bottom: $spacer + 2;
        }
    }

    .border-gray-thick {
        border: 2px solid #494848;
        border-radius: 10px;
    }

    .flex {
        @media all and (max-width: ($screen-m - 1)) {
            flex-direction: column;
        }
    }

    .flex-d-column {
        flex-direction: column;
    }
}

body.category-rooftrac {
    .featured-cat-box {
        @media all and (max-width: ($screen-m - 1)) {
            margin-bottom: $spacer--semi-large + 5;
        }
    }

    .image-with-text {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .system-parts-block {
        .featured-cat-box {
            .featured-desc {
                p {
                    padding: 0 15px;
                }
            }
        }
    }
}

/* category-side-of-pole-mounts */
body.category-side-of-pole-mounts {
    #page-title-heading {
        display: none;
    }

    .font-size-small {
        font-size: $font-size-small;
    }

    .panels-model {
        display: inline;

        &:hover {
            color: $black;
        }
    }

    .rpi {
        margin-left: $spacer + 2;
        background-color: #f3f3f3;
        margin-top: $spacer--medium + 4;
        display: inline-block;
        padding: $spacer--extra-small + 1;

        .ten-link-gill {
            font-size: $font-size-small;
            padding: 0 $spacer--medium + 4;

            &:hover {
                color: $black;
            }
        }
    }

    .review-box {
        background-color: #f2f2f2;
    }

    .three-image-div {
        display: flex;
        justify-content: center;
    }
}

/* category-schneider-xw-inverter-solar-battery-backup-systems */
body.category-schneider-xw-inverter-solar-battery-backup-systems {
    #page-title-heading {
        display: none;
    }

    .solar-table {
        margin-bottom: 0;

        td,
        th {
            border-color: $black;
        }
    }

    .d-inline {
        display: inline;
    }

    .label {
        a.system-price {
            color: $dark-orange;
        }
    }

    a.system-price {
        color: $dark-blue;
        text-decoration: underline;
        font-family: Segoe, "Segoe UI", "DejaVu Sans", "Trebuchet MS", Verdana, Arial, sans-serif;
        font-size: $font-size-small;
        font-weight: bold;
        margin-bottom: $spacer + 2;
    }

    .font-size-small {
        font-size: $font-size-small;
    }

    p {
        margin-bottom: $spacer + 2;
    }
}

/* category-outdoor-solar-led-lighting-systems */
body.category-outdoor-solar-led-lighting-systems {
    #page-title-heading {
        display: none;
    }

    .title-container {
        picture {
            padding-left: 0;
            margin-left: 0;

            @media all and (max-width: ($screen-s - 1)) {
                float: none;
            }

            img {
                margin: 0;

                @media all and (max-width: ($screen-s - 1)) {
                    float: none;
                }
            }
        }

        .header-cont {
            @media all and (max-width: ($screen-s - 1)) {
                margin-top: 0;
                padding-top: 0;
            }

            h3.dark-text {
                font-size: $font-size-medium;
            }
        }
    }

    .solar-list {
        margin-top: 0;
    }

    .clr-bth-sm {
        display: flex;
        flex-direction: column;
        justify-content: center;

        span {
            font-size: $font-size-small;
        }
    }

    .radial {
        background: radial-gradient(ellipse at center, rgba(255, 255, 255, 0.01) 50%, rgba(125, 185, 232, 0.16) 100%);
    }

    .featured-cat-box {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .security-light-img {
        max-width: initial;

        @media all and (max-width: ($screen-l - 1)) {
            max-width: 210px;
        }

        @media all and (max-width: ($screen-m - 1)) {
            max-width: 100%;
        }
    }

    .sloped-roof {
        .featured-cat-box {
            @include mq($max-screen: $screen-m - 1) {
                margin-bottom: $spacer--semi-large + 5;
            }
        }
    }
}

/* category-conext-quick-fit */
body.category-conext-quick-fit {
    #page-title-heading {
        display: none;
    }

    .product-grid {
        display: grid;
        grid-template-columns: repeat(5, 1fr);
        gap: $spacer + 2;

        @media all and (max-width: ($screen-s - 1)) {
            grid-template-columns: repeat(1, 1fr);
        }

        .featured-cat-box.with-ol {
            ol {
                padding-left: 0;
            }
        }
    }

    .solar-table.table-box {
        a {
            flex-direction: column;
            justify-content: center;
            text-align: center;
            margin-left: auto;
            margin-right: auto;

            p {
                color: $darker-blue;
                font-weight: $font-weight-bold;
                margin-right: auto;
                margin-left: auto;
            }
        }
    }

    .f-above-img-sec {
        margin-top: $spacer--semi-medium - 2;
        border-radius: 4px;
        background-image: url(/media/product-images/remote-industrial-solar/remote-outdoor-solar-systems/remote-off-grid-solar-power-ground-2.png);
        background-repeat: no-repeat;
        background-position: left bottom;
        background-color: #F0F0F0;
    }

    .image-with-text {
        display: flex;
        flex-direction: column;
        align-items: center;
    }
}

/* category-deka-unigy-ii-spacesaver */
body.category-deka-unigy-ii-spacesaver {
    #page-title-heading {
        display: none;
    }

    .bg-color {
        background-color: #F5EEDE;
        flex-direction: column;
        justify-content: center;

        h3,
        p {
            margin-bottom: $spacer + 2;
            padding: $spacer + 2;
        }
    }

    .deka-avr {
        background-color: #f3f3f3;
        text-align: center;
        flex-direction: column;
        justify-content: center;
    }

    .call-box {
        background-color: #f3f3f3;
    }

    .right-box {
        background-color: #f3f3f3;
    }

    .avr-cells table {
        width: 100%;
        border: $border-width-base $border-style-base #ddd;
    }

    .deka-module-model {
        background-color: #f3f3f3;
    }

    .review-box {
        background-color: #f2f2f2;

        h2 {
            font-size: $font-size-super-extra-large - 2;
            padding: 0 15px;

            @media all and (max-width: ($screen-m - 1)) {
                font-size: $font-size-extra-large + 1;
            }
        }
    }

    .image-with-text {
        display: flex;
        flex-direction: column;
        align-items: center;

        strong {
            color: $dark-blue;
            font-size: $font-size-extra-small;
        }
    }
}

/* category-gnb-absolyte-gp-industrial-sealed-battery */
body.category-gnb-absolyte-gp-industrial-sealed-battery {
    #page-title-heading {
        display: none;
    }

    .absolyte-gp-images {
        p {
            display: grid;
            grid-template-columns: 1fr 1fr;

            @media all and (max-width: ($screen-l - 1)) {
                grid-template-columns: 1fr;
            }
        }
    }

    .h3-shadow {
        margin: 1px 1px 1px 1px;
        color: $darkest-blue;
        font-size: $font-size-medium;
        font-weight: normal;
        text-shadow: 1px 1px 1px #b1b1b2;
    }

    .h4-shadow {
        color: $black;
        font-size: $spacer--medium - 1;
        font-weight: $font-weight-bold;
        margin-top: $spacer--medium - 1;
    }

    .list-style-none {
        list-style: none;

        li {
            font-size: $font-size-small;
            margin-bottom: $spacer + 2;
        }
    }

    .remove-padding {
        padding: 0;
    }

    .table-absolyte-image {
        img {
            margin-left: auto;
        }
    }

    .review-box {
        padding-top: $spacer--large - 2;
        background-color: #f2f2f2;
        margin-top: $spacer--large - 2;
    }

    .link-right {
        text-align: right;
    }

    .mobile-image-center {
        @include mq($max-screen: ($screen-m - 1)) {
            margin-left: auto;
            margin-right: auto;
        }
    }
}

/* category-sma-sunny-island-inverter-off-grid-solar-battery-backup-systems */
body.category-sma-sunny-island-inverter-off-grid-solar-battery-backup-systems {
    #page-title-heading {
        display: none;
    }

    .radial {
        background: radial-gradient(ellipse at center, rgba(255, 255, 255, 0.01) 50%, rgba(125, 185, 232, 0.16) 100%);
    }

    h3.home-sub-header-x {
        font-size: $font-size-medium;
    }
}

/* category-mapps-ob-series */
body.category-mapps-ob-series {
    #page-title-heading {
        display: none;
    }

    .title-container {
        picture {
            padding-left: 0;
            margin-left: 0;

            @media all and (max-width: ($screen-s - 1)) {
                float: none;
            }

            img {
                margin: 0;

                @media all and (max-width: ($screen-s - 1)) {
                    float: none;
                }
            }
        }

        .header-cont {
            @media all and (max-width: ($screen-s - 1)) {
                margin-top: 0;
                padding-top: 0;
            }

            h3.dark-text {
                font-size: $font-size-medium;
            }
        }
    }

    .sloped-roof-bottom {
        background-position: left;

        @media all and (max-width: ($screen-m - 1)) {
            background-position: center;
        }
    }
}

/* category-class-1-division-2-solar-panels-systems */
body.category-class-1-division-2-solar-panels-systems {
    #page-title-heading {
        display: none;
    }

    .title-container {
        picture {
            padding-left: 0;
            margin-left: 0;

            @media all and (max-width: ($screen-s - 1)) {
                float: none;
            }

            img {
                margin: 0;

                @media all and (max-width: ($screen-s - 1)) {
                    float: none;
                }
            }
        }

        .header-cont {
            @media all and (max-width: ($screen-s - 1)) {
                margin-top: 0;
                padding-top: 0;
            }

            h3.dark-text {
                font-size: $font-size-medium;
            }
        }
    }

    .image-with-link {
        display: flex;
        flex-direction: column;
        align-items: center;

        &:hover {
            color: $charcoal-black;
        }
    }

    .comm-list {
        padding: $spacer--medium + 2;
        border: $border-width-base $border-style-base #dad8d8;

        ul {
            list-style: square;
        }
    }

    .solar-list {
        ul {
            list-style: square;
        }
    }

    .dark-blue-links {
        a {
            color: $dark-blue;
            font-weight: $font-weight-bold;
            font-family: Segoe, "Segoe UI", "DejaVu Sans", "Trebuchet MS", Verdana, Arial, sans-serif;
            text-decoration: underline;
            font-size: $font-size-small;
        }
    }

    .image-with-text {
        display: flex;
        flex-direction: column;
        align-items: center;
        text-decoration: underline;

        strong {
            color: $dark-blue;
            font-size: $font-size-small;

            &:hover {
                color: $black;
            }
        }
    }

    .vdc-12-class {
        @media all and (max-width: ($screen-s - 1)) {
            display: grid;
            grid-template-columns: repeat(1, 1fr);
        }
    }

    .vdc-24-class {
        @media all and (max-width: ($screen-s - 1)) {
            display: grid;
            grid-template-columns: repeat(1, 1fr);
        }
    }

    .pdf-box {
        max-width: 100%;
    }

    table.solar-table {
        td {
            width: initial;
        }
    }
}

/* category-off-grid-solar-panels categorypath-solar-panels-off-grid-solar-panels */
body.category-off-grid-solar-panels.categorypath-solar-panels-off-grid-solar-panels {
    #page-title-heading {
        display: none;
    }

    .roof-cont {
        border: 1px solid #ddd;
        padding: 20px 0px;
        margin-bottom: $spacer--medium + 4;

        .roof-mounted {
            display: inline-block;
            text-align: center;
            width: 100%;
            margin-bottom: $spacer--medium + 4;

            p {
                margin-top: $spacer--medium - 1;

                a {
                    color: $dark-orange;
                    text-decoration: none;
                    font-weight: $font-weight-semi-medium;

                    &:hover {
                        color: $dark-blue;
                    }
                }
            }
        }

        h4 {
            margin-bottom: $spacer + 2;
        }
    }

    .roof-bottom {
        margin-bottom: $spacer--large - 2;
        border: 1px solid #ddd;
        background: #f6f6f6;

        .roof-left {
            padding: 15px 15px;
            text-align: left;

            a {
                text-decoration: none;

                &:hover {
                    text-decoration: underline;
                    color: $black;
                }
            }
        }

        .roof-right {
            a {
                display: initial;
                text-decoration: underline;
                color: $dark-blue;

                img {
                    padding: 20px 0px;
                    margin: 0px auto;
                    display: block;
                }

                &:hover {
                    color: $black;
                }
            }
        }
    }
}

/* category-sdc-microgrid-solar-systems */
body.category-sdc-microgrid-solar-systems {
    #page-title-heading {
        display: none;
    }

    .header-container {
        align-items: center;

        .col-md-2 {
            @media all and (min-width: ($screen-m)) {
                max-width: 37%;
                flex-basis: auto;
            }
        }

        .col-md-10 {
            @media all and (min-width: ($screen-m)) {
                max-width: 63%;
                flex-basis: auto;
            }
        }

        img {
            margin-left: auto;
            margin-right: auto;
        }

        h3.home-sub-header-x {
            font-weight: $font-weight-semi-medium;
        }
    }

    .css-bottom-details {
        .side-box {
            text-align: left;
        }
    }

    .sloped-roof.ml-1 {
        margin-top: $spacer--small + 3;
        margin-bottom: $spacer--semi-large;

        .featured-cat-box {
            picture.mt-3 {
                margin-top: 0;
            }
        }
    }

    .sloped-roof {
        .featured-cat-box {
            text-align: center;

            img {
                margin-left: auto;
                margin-right: auto;
            }

            .featured-desc {
                padding: $spacer + 2;
            }
        }
    }

    .css-last {
        h3 {
            a {
                display: inline;
            }
        }
    }

    .mt-xs-5 {
        @media all and (max-width: ($screen-m - 1)) {
            margin-top: $spacer--extra-large + 2;
        }
    }

    .mb-xs-3 {
        @media all and (max-width: ($screen-m - 1)) {
            margin-bottom: $spacer--semi-medium + 6;
        }
    }

    .mt-xs-3 {
        @media all and (max-width: ($screen-m - 1)) {
            margin-top: $spacer--semi-medium + 6;
        }
    }
}

/* category-ac-dc-outdoor-ups-systems */
body.category-ac-dc-outdoor-ups-systems {
    #page-title-heading {
        display: none;
    }

    .header-container {
        align-items: center;
        padding: $spacer--small + 3;
        margin-top: $spacer--small + 3;

        .col-md-2 {
            @media all and (min-width: ($screen-m)) {
                max-width: 37%;
                flex-basis: auto;
            }
        }

        .col-md-10 {
            @media all and (min-width: ($screen-m)) {
                max-width: 63%;
                flex-basis: auto;
            }
        }

        img {
            margin-left: auto;
            margin-right: auto;
        }

        h3.home-sub-header-x {
            font-weight: $font-weight-semi-medium;
        }
    }

    .css-bottom-details {
        @media all and (max-width: ($screen-m - 1)) {
            margin-top: $spacer--small + 3;
            margin-bottom: $spacer--semi-medium + 1;
        }

        h3 {
            @media all and (max-width: ($screen-m - 1)) {
                margin-top: $spacer--small + 3;
            }
        }

        h5.dark-text {
            margin-bottom: 0;
            margin-top: $spacer--semi-medium + 1;
        }
    }

    h5.dark-text {
        font-size: $font-size-base;
        font-weight: bold;
        color: $charcoal-black;
    }

    .sloped-roof-bottom {
        @media all and (min-width: ($screen-m)) {
            background-position: bottom left;
        }
    }
}

/* category-rd-skid-mounted-portable-solar-generator-system */
body.category-rd-skid-mounted-portable-solar-generator-system {
    #page-title-heading {
        display: none;
    }

    .title-container {
        picture {
            padding-left: 0;
            margin-left: 0;

            @media all and (max-width: ($screen-s - 1)) {
                float: none;
            }

            img {
                margin: 0;

                @media all and (max-width: ($screen-s - 1)) {
                    float: none;
                }
            }
        }

        .header-cont {
            @media all and (max-width: ($screen-s - 1)) {
                margin-top: 0;
                padding-top: 0;
            }

            h3.dark-text {
                font-size: $font-size-medium;
            }
        }
    }

    h4 {
        font-size: $font-size-large + 1;
    }

    .right-sidebar {
        font-size: $font-size-extra-large;
        font-weight: normal;
        margin-bottom: 0;
        background-color: #f37321;
        padding: $spacer + 2;
        color: $white;
    }

    .sidebar-links {
        list-style-type: none;
        margin: 0;
        padding: 0;
        background-color: #f1f1f1;

        li a {
            display: block;
            color: #000;
            padding: 8px 16px;
            text-decoration: none;
            font-size: $font-size-base;
            border-bottom: 1px solid #ccc;

            &:hover {
                background-color: #555;
                color: white;
            }
        }
    }
}

/* category-enphase-ensemble */
body.category-enphase-ensemble {
    #page-title-heading {
        display: none;
    }

    .f-above-img-sec {
        margin-top: $spacer--semi-medium - 2;
        border-radius: 4px;
        background-image: url(/media/product-images/remote-industrial-solar/remote-outdoor-solar-systems/remote-off-grid-solar-power-ground-2.png);
        background-repeat: no-repeat;
        background-position: left bottom;
        background-color: #F0F0F0;

        .row+.col-sm-12 {
            padding: 0;
        }
    }

    .image-with-text {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .solar-table {
        a {
            flex-direction: column;
            align-items: center;
        }
    }

    h2.mb-3.home-title-header-x {
        margin-bottom: $spacer--semi-medium + 6;
    }
}

/* category-ecoult-ultraflex-energy-storage-systems */
body.category-ecoult-ultraflex-energy-storage-systems {
    #page-title-heading {
        display: none;
    }

    .header-cont {
        padding-bottom: 0;

        &+.css-bottom-details {
            hr {
                @media all and (min-width: ($screen-l)) {
                    margin-top: 0;
                }
            }
        }

        h2.home-sub-header-x {
            color: $darkest-blue;
        }
    }

    .right-sidebar {
        font-size: $font-size-medium;
        font-weight: bold;
        margin-bottom: 0;
        background-color: #f37321;
        padding: $spacer + 2;
        color: $white;
    }

    .sidebar-links {
        list-style-type: none;
        margin: 0;
        padding: 0;
        background-color: white;

        li {
            margin-bottom: $spacer--small + 2;

            a {
                display: block;
                color: #000;
                padding: 8px 16px;
                text-decoration: none;
                font-size: $font-size-base;
                border-bottom: 1px solid #ccc;

                &:hover {
                    background-color: #555;
                    color: white;
                }
            }
        }
    }

    .image-with-text {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .w-100 {
        width: 100%;
    }

    .f-above-img-sec {
        margin-top: $spacer--semi-medium - 2;
        border-radius: 4px;
        background-image: url(/media/product-images/remote-industrial-solar/remote-outdoor-solar-systems/remote-off-grid-solar-power-ground-2.png);
        background-repeat: no-repeat;
        background-position: left bottom;
        background-color: #F0F0F0;

        .row+.col-sm-12 {
            padding: 0;
        }
    }

    .image-with-text {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .d-inline {
        display: inline;
    }
}

/* category-solar-batteries categorypath-solar-batteries */
body.category-solar-batteries.categorypath-solar-batteries {
    #page-title-heading {
        display: none;
    }

    .header-cont {
        h3.home-sub-header-x {
            font-size: $font-size-medium;
        }
    }

    .border-right {
        border-right: $border-width-base $border-style-base #f4f4f4;
    }

    .orange-links {
        color: $dark-orange;
        text-decoration: none;

        &:hover {
            text-decoration: underline;
            color: $dark-orange;
        }
    }

    .dark-blue-links {
        color: $darker-blue;
        text-decoration: underline;

        &:hover {
            text-decoration: underline;
            color: $black;
        }
    }

    .font-small {
        font-size: $font-size-small;
    }

    .font-base {
        font-size: $font-size-base;
    }

    .font-large,
    h4 {
        font-size: $font-size-large + 1;
    }

    .w-100 {
        width: 100%;
    }

    .d-inline {
        display: inline;
    }

    .flex {
        @media all and (max-width: (539px)) {
            flex-direction: column;
        }

        a {
            padding-top: 0;
            padding-bottom: 0;
        }
    }

    p {
        margin-bottom: 10px;
    }
}

body.category-silfab,
body.category-mission-solar,
body.category-panasonic,
body.category-solaria-powerxt,
body.category-hanwha-solar,
body.category-trina-solar,
body.category-yingli-solar {
    .product-grid-item__name {
        margin-bottom: 0px;
    }

    .lazyload-wrapper {
        padding-bottom: 125% !important;

        picture {
            margin-top: $spacer--semi-medium + 3;
        }
    }

    .product-grid-item__swatches {
        padding: 0 5%;
    }

    .columns {
        .column {
            .block {
                display: none;
            }
        }
    }

    .button--wishlist {
        display: none;
    }

    #maincontent {
        a {
            text-decoration: none;
            font-weight: $font-weight-normal;

            &:hover {
                text-decoration: underline;
                color: #000;
            }
        }
    }

    strong {
        a {
            font-weight: $font-weight-bold;
        }
    }

}

body.category-silfab,
body.category-mission-solar,
body.category-panasonic,
body.category-solaria-powerxt,
body.category-hanwha-solar,
body.category-trina-solar {
    .category-view {
        margin-bottom: $spacer--large - 2;
    }

    .category-description {
        h3 {
            font-size: $font-size-extra-large;
            line-height: 1.1;
        }
    }
}

body.category-hanwha-solar {
    #page-title-heading {
        display: none;
    }

}

body.category-solaria-powerxt {
    .twelve-px-verdana {
        strong {
            a:hover {
                color: #000;
            }

            a {
                color: #000099;
                text-decoration: underline;
            }
        }
    }

    .category-cms {
        .category-view {
            padding: 0 15px;
        }
    }


    @media (max-width:$screen-s ) {
        .twelve-px-verdana {
            text-align: left;
            display: flex;
            flex-direction: column;
        }

    }
}

body.category-silfab,
body.category-panasonic {
    .category-cms {
        .category-view {
            padding: 0 15px;
        }
    }
}

body.category-mission-solar {
    .category-cms {
        .category-view {
            padding: 0 15px;
        }
    }

    .category-description {
        font-size: $spacer--small;
    }
}

body.category-isofoton {
    .category-image {
        padding-bottom: 0px !important;
        display: flex;
        align-items: flex-start;
        padding-top: $spacer--semi-medium + 3;
        width: 86px;
    }

    #maincontent {
        .category-view {
            padding-left: $spacer--small + 3;
            padding-bottom: $spacer--large + 3;
        }
    }

    .product-image-photo {
        height: 260px;
    }

    .product-grid-item__link {
        padding-top: $spacer--semi-medium - 1;
    }

    .product-grid-item__name {
        min-height: auto;
    }

    .button--wishlist {
        display: none;
    }

    .about-panel-manufacturer {
        ul {
            padding-left: 0px;
        }
    }

}


body.category-samsung {
    .lazyload-wrapper {
        padding-bottom: 116% !important;
    }

    .category-image {
        padding-bottom: 0px !important;
        display: flex;
        align-items: flex-start;
        padding-top: $spacer--semi-medium + 3;
    }

    #maincontent {
        .category-cms {
            padding-bottom: $spacer--large + 3;

            .category-view {
                h2 {
                    font-size: $font-size-super-extra-large - 2;
                    margin-bottom: $spacer--small - 2;
                }
            }
        }
    }

    .product-image-photo {
        height: 260px;
    }

    .product-grid-item__link {
        padding-top: $spacer--semi-medium - 1;
    }

    .product-grid-item__name {
        min-height: auto;
    }

    .button--wishlist {
        display: none;
    }

    ul {
        padding-left: 0px;
    }


    .product-grid-item__swatches {
        padding: 0px;
    }

    .product-grid-item__name-link {
        padding: 0 5%;
    }
}

body.category-yingli-solar {
    h3 {
        font-size: $font-size-extra-large;
    }
}

body.category-our-featured-products,
body.category-perlight-solar,
body.category-kyocera,
body.category-sanyo,
body.category-kaneka,
body.category-day-4,
body.category-shell,
body.category-solarex,
body.category-bp-solar {
    .button--wishlist {
        display: none;
    }

    #maincontent {
        .category-cms {
            padding-bottom: $spacer--large + 3;

            .category-view {
                h2 {
                    font-size: $font-size-super-extra-large - 2;
                    margin-bottom: $spacer--small - 2;
                }
            }
        }
    }

    .product-grid-item__name {
        min-height: auto;
    }
}

body.category-our-featured-products,
body.category-perlight-solar,
body.category-kyocera,
body.category-sanyo,
body.category-kaneka,
body.category-day-4,
body.category-siemens {
    .button--wishlist {
        display: none;
    }
}

body.category-canadiansolar,
body.category-recsolar,
body.category-schuco,
body.category-sharp,
body.category-kyocera,
body.category-solarworld,
body.category-sanyo,
body.category-lg-solar,
body.category-solon,
body.category-alps,
body.category-renesola {
    font-size: $font-size-small;

    #page-title-heading {
        display: none;
    }

    .category-image {
        margin-bottom: 5px;
    }

    .fs-12 {
        font-size: $font-size-small;
    }

    .sub-head {
        font-size: $font-size-extra-large;
    }

    .fs-bold {
        font-weight: bold;
    }

    .black-links {
        color: $black;
        text-decoration: none;

        &:hover {
            text-decoration: underline;
        }
    }

    .dark-blue-links {

        color: $dark-blue;
        font-weight: $font-weight-bold;
        text-decoration: underline;
        font-size: $font-size-small;

        &:hover {
            color: black;
        }

    }

    .fs-15 {
        font-size: $font-size-base + 1;
        margin-top: 10px;
    }

    .fs-14 {
        font-size: $font-size-base;
        margin-top: 10px;
    }

    .pad-mar {
        padding: 12px 10px 0;
        margin-bottom: 30px;
    }

    .display-initial {
        display: initial;
    }

    @media (max-width: $screen-s) {
        .sub-head {
            font-size: $font-size-large + 2;
        }
    }
}