@import 'product-view-variables';

.product-view {


    padding: $spacer--large + 3 $spacer--medium - 1 0px $spacer--medium - 1;
    margin: 0px auto;

    .col-md-4 {
        padding-left: $spacer--medium - 1;
        padding-right: $spacer--medium - 1;
    }

    &__description {
        width: 100%;
    }

    &__title {
        &.heading--page {
            font-size: $font-size-extra-large + 4;
            padding: $spacer--medium - 1 $spacer--medium - 1 0px 00px;

            @include mq($screen-m) {
                font-size: $font-size-super-extra-large;
            }

            @include mq($screen-l) {
                font-size: $font-size-super-extra-large;
            }
        }
    }

    &__sku {
        display: block;
        margin-bottom: $spacer--large - 2;

    }

    &__stock {

        &:hover {
            text-decoration: none;
        }
    }

    // tabs
    &__tabs {
        margin: 64px auto 0px auto;
        width: 96%;
        max-width: none;

    }
    @media (max-width: $screen-m) {
        &__tabs{
            width: 88vw;
        }
    }

    &__tab {
        max-width: none;
        width: 100%;
        padding: 0;
        justify-content: flex-start;
        margin: 0;
        background: #fff;


        #tab-title-1 {
            order: 1;
        }

        #tab-title-2 {
            order: 0;
        }

        #tab-1 {
            order: 2;
        }

        #tab-2 {
            order: 2;
        }

        .tab__title {
            display: inline-block;
            flex-grow: 0;
            margin: 0 0 -1px;
            padding: $spacer--medium - 1 $spacer--semi-medium - 1;
            font-weight: normal;
            font-size: $font-size-base;
            text-align: center;
            color: #bbb;
            background-color: #eaeaea;
            border: 1px solid transparent;
        }

        .tab__title:hover {
            color: #000;
            cursor: pointer;

        }

        .tab__title--active {
            color: #000;
            border: 1px solid #ddd;
            border-top: 2px solid #f05451;
            border-bottom: 1px solid #fff;
            background-color: #fff;
            z-index: 2;

        }

        .tab__content {
            display: none;
            padding: $spacer--medium + 4;
            border: 1px solid #d1d1d1;
            box-shadow: 0 0 6px #000020;

            .footer {
                border: none;
            }

            ul {
                list-style: circle;
                padding-left: 0px;

                &.links {
                    li {
                        a:hover {
                            color: $dark-orange ;
                        }
                    }
                }
            }
        }

        .tab__title--active {
            display: block;
        }

        button[aria-selected="false"] {
            color: $black;
            border: $border-width-base $border-style-base #d1d1d1;
            &:hover {
                background-color: #fcfcfc;
            }
        }

        @media (max-width: $screen-m) {
            display: flex;
            flex-direction: column;

            #tab-title-2 {
                order: 1;
            }

            #tab-2 {
                order: 2;
                overflow-x: scroll;
            }

            #tab-title-1 {
                order: 3;
            }

            #tab-1 {
                order: 4;
            }

            .tab__title {
                color: #333;
                background-color: #fff;
                border: 1px solid #d1d1d1;
            }

            .tab__title--active {
                color: #333;
                border-top: 2px solid #f05451;
                background-color: #fff;
                z-index: 2;
            }
        }
    }



    &__tabs-sticky {
        display: none;

        .tab__title--sticky {
            display: none;
        }
    }


    h3 {
        font-size: $font-size-medium + 4;

        @include mq($screen-m) {
            font-size: $font-size-extra-large;
        }

    }

    p {
        color: #333;
        line-height: 20px;
        font-style: normal;

        .twelve-link {
            text-decoration: none;
            font-weight: $font-weight-bold;
        }
    }
}

.feature-list {
    width: 80%;
    margin-left: auto;

    ul {
        padding-left: $spacer--medium + 4;
    }

    border-left: 1px solid #e5e5e5;

    li {
        list-style: none;
        display: flex;

        a {
            display: inline-flex;
            align-items: center;
            font-size: $font-size-base;
            line-height: 20px;
            color: #000;
        }

        a:hover {
            color: #000;
        }

        i {
            background-color: #ef7e18;
            color: #fff;
            line-height: 40px;
            width: $spacer--semi-large;
            height: $spacer--semi-large;
            min-width: $spacer--semi-large;
            text-align: center;
            border-radius: 50%;
            margin-right: $spacer--medium + 4;

        }

        i:hover {
            background-color: transparent;
            color: #fff;
        }
    }

    li:hover {
        i {
            background-color: transparent;
            color: #fff;
        }
    }
}

@media only screen and (max-width: (($screen-m)-1)) {
    .feature-list {
        border: none;
        width: 96%;
        margin-left: 0px;
        padding-left: 0px;
    }
}

table {
    width: 100%;
    border-collapse: collapse;
    border-spacing: 0;
    border-color: grey;
    max-width: 100%;
    border-width: 1px;

    td {
        padding: $spacer $spacer + 2;
        vertical-align: top;
        width: 150px;

    }
}

.cards {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    max-width: 1500px;
    width: 96%;
    margin-left: auto;
    margin-right: auto;

    .card-4 {
        grid-column-start: 1;
        grid-column-end: 4;
        grid-row-start: 2;
        grid-row-end: 3;
    }

    .card-1 {
        padding-right: $spacer--medium - 1;
        margin-bottom: 25px;
    }

    .card-2 {
        padding-left: $spacer--medium - 1;
        padding-right: $spacer--medium - 1;
        margin-bottom: 25px;

        h3 {
            strong {
                font-size: $font-size-extra-large;
            }
        }

        @media only screen and (max-width: (($screen-m)-1)) {
            h3 {
                strong {
                    font-size: $font-size-extra-large - 4;
                }
            }
        }
    }

    .card-3 {
        padding-left: $spacer--medium - 1;
        margin-bottom: $spacer--semi-medium + 1;
    }

    .card-4 {
        padding-left: $spacer--medium - 1;
        padding-right: $spacer--medium - 1;
        margin-top: $spacer--semi-medium + 1;
        margin-bottom: $spacer--semi-medium + 1;
    }

}

@media only screen and (max-width: (($screen-m)-1)) {
    .card-1 {
        grid-area: card-1;
    }

    .card-2 {
        grid-area: card-2;
    }

    .card-3 {
        grid-area: card-3;
    }

    .card-4 {
        grid-area: card-4;
    }

    .cards {
        display: grid;
        grid-template-columns: repeat(1, 1fr);
        grid-template-areas:
            'card-1'
            'card-2'
            'card-4'
            'card-3';

        .card-1,
        .card-2,
        .card-3,
        .card-4 {
            padding: 0px;
        }

        .card-4 {
            grid-column-start: 1;
            grid-column-end: 2;
            grid-row-start: 3;
            grid-row-end: 4;
        }

        padding-left: $spacer--medium - 1;
        padding-right: $spacer--medium - 1;


    }
}
