// Request a quote modal variables

// Wrapper
$request-quote-modal__inner-wrap-width\@small               : 95%;
$request-quote-modal__inner-wrap-width\@medium              : 75%;
$request-quote-modal__inner-wrap-width                      : 50%;

// Header-footer
$request-quote-modal__header-footer-bg-color                : $gray-light;
$request-quote-modal__header-footer-box-shadow              : 0px 0px 5px rgba(50, 50, 50, 0.75);
$request-quote-modal__header-remove-transition              : all 0s ease-in-out;
$request-quote-modal__footer-remove-transition              : all 0s ease-in-out;

// Heading
$request-quote-modal__heading-font-size                     : $font-size-extra-large;
$request-quote-modal__heading-font-size--small              : $font-size-extra-large - 4;
$request-quote-modal__heading-font-weight                   : $font-weight-base;
$request-quote-modal__heading-line-height                   : 1.1;

// Contact buttons
$request-quote-modal__buttons-margin                        : 30px 0 10px 0;
$request-quote-modal__button-margin                         : 0 5px;
$request-quote-modal__button-padding                        : 6px 10px;
$request-quote-modal__button-font-size                      : $font-size-base - 1;
$request-quote-modal__button-text-color                     : $white;
$request-quote-modal__button-background-color               : #545151;
$request-quote-modal__button-border-radius                  : 2px;

// Contact text
$request-quote-modal__contact-text-font-size                : $font-size-small;
$request-quote-modal__contact-text-font-weight              : $font-weight-bold;
$request-quote-modal__contact-text-color                    : $darker-blue;
$request-quote-modal__contact-text-decoration               : underline;
$request-quote-modal__contact-text-margin                   : 0 15px 10px 10px;

// Footer button
$request-quote-modal__footer-button-background-color        : $dark-orange;
$request-quote-modal__footer-button-color                   : $white;
$request-quote-modal__footer-button-background-color--hover : $charcoal-black;
