// Edit components variables here. Primary color changed as a example.
// You can alter any variable, by giving it new value and removing !default.
// To see Alpaca Theme variables go to:
// vendor/snowdog/theme-frontend-alpaca/Snowdog_Components/components/Atoms/variables/_variables.scss

// Open Sans font family support
@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;600;700&display=swap");

// Colors
$black-pearl                : #1d1f21;
$transparent                : transparent;
$charcoal-black             : #333333;
$dark-orange                : #ef7e18;
$light-orange               : #FB9128;
$brownish-gray              : #545151;
$extra-faint-blue           : #dfe4f0;
$extra-extra-faint-blue     : #f0f1f6;
$dark-blue                  : #000099;
$darker-blue                : #000066;
$darkest-blue               : #000033;
$redish-brown               : #6c2a03;
$stone-gray                 : #333333;
$light-golden               : #d0b49a;

// Layout - breakpoints
$screen-air                 : 820px;

// Semantic color scheme
$color-primary              : $charcoal-black;

// Typography
$font-family-monospace      : 'Open Sans', Menlo, Monaco, Consolas, 'Liberation Mono', 'Courier New', monospace;
$font-family-sans-serif     : 'Open Sans', Arial, 'Helvetica Neue', Helvetica, sans-serif;
$font-weight-semi-medium    : 600;
$font-weight-bolder         : 800; 
$font-weight-bolder         : 800;
$font-size-largest          : 40px;
$font-line-height           : 1.42857143;

// Layout
$max-content-width          : 1450px;
