.footer {
    background-color: $footer__background-color;
    &__links {
        .footer-links-list {
            list-style: none;
            display: flex;
            justify-content: center;
            flex-wrap: wrap;
            padding: $footer__links-list-padding;
            margin: $footer__links-list-margin;

            @include mq($max-screen: ($screen-m - 1)) {
                flex-direction: column;
            }
            
            li {
                margin: 0;
                a {
                    color: $footer__links-color;
                    margin: $footer__links-list-item-margin;
                    padding: $footer__links-list-item-padding;
                    background: url("/media/images/bkg_pipe2.gif") 100% 60% no-repeat;
                    &:hover {
                        color: $footer__links-color--hover;
                    }

                    @include mq($max-screen: ($screen-m - 1)) {
                        width: 100%;
                        border-bottom: $border-width-base dashed $gray;
                    }
                }
            }
        }

        .dropdown-list {
            &__label {
                display: none;
            }

            &__content {
                &[aria-hidden="true"] {
                    @media all and (max-width: ($screen-m - 1)) {
                        display: flex;
                    }
                }
            }
        }
    }

    &__links-list {
        display: flex;
        justify-content: space-between;
    }

    &__newsletter {
        display: none;
    }
    &__scroll-top {
        display: none;
        position: $footer__scroll-top-position;
        right: $footer__scroll-top-right;
        bottom: $footer__scroll-top-bottom;
        top: initial;
        width: 50px;
        height: 50px;
        line-height: 50px;
        border-radius: 50%;
        background-color: $footer__scroll-top-bg;
        z-index: 100;

        &:before {
            content: "\f106";
            font-family: 'Font Awesome 6 Free';
            color: #333333;
            position: initial;
            font-size: 24px;
            transform: $footer__scroll-top-before-transform;
            background: $footer__scroll-top-before-background;
            
            
        }

        &:hover,
        &:focus,
        &.focus-visible {
            background-color: transparent;   
        }
        &:hover::before {
            color: #ffffff;
        }
        
        .icon {
            fill: $footer__scroll-top-icon-fill;
        }
    }
}
