.message {
    justify-content: left;

    &:after {
        content: $message__icon;
        position: relative;
        text-align: center;
        font-family: 'Font Awesome 6 Free';
        order: $message__icon-order;
        height: $message__icon-spacer;
        width: $message__icon-width;
        min-width: $message__icon-width;
        margin: 10px 0px 0px 0px;
        background-image: none;
        background-repeat: no-repeat;
        background-size: none;
        font-size: $message__font-size;
        font-weight: $message__font-weight;
    }

    &--success {
        background-color: $message__background--success;
        border-color: $message__border-color--success;
        color: $message__color--success;
        padding: 0;

        &:after {
            content: $message__icon--success;
        }

        .message-comparison-link {
            color: $message__success-comparison-link-color;

            &:hover {
                color: $message__success-comparison-link-color--hover;
            }
        }
    }

    &--error {
        background-color: $message__background--error;
        border-color: $message__border-color--error;
        color: $message__color--error;
        padding: 0;

        &:after {
            content: $message__icon--eror;
        }
    }
}
