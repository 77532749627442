.compare-products-container {
    overflow-x: scroll;

    .compare__table {
        @media all and (max-width: $screen-l) {
            table-layout: initial;
            min-width: $compare__table-min-width;
            width: 100%;
        }

        tbody {
            tr {
                border-bottom: $compare__table-tr-border--bottom;
            }

            th,
            td {
                padding: $compare__table-th-td-padding;
                width: auto;
                text-align: $compare__table-th-td-text-align--horizontal;
                vertical-align: $compare__table-th-td-text-align--vertical;
            }

            th {
                @media all and (max-width: $screen-l) {
                    min-width: $compare__table-th-min-width;
                }
            }

            td {
                .attribute.value {
                    .product-details {
                        overflow-x: scroll;
                    }
                }
            }

            .compare__product {
                .compare__link:not(.compare__link--image) {
                    &:hover {
                        color: $compare__table-product-link-color--hover;
                    }
                }
            }
        }
    }
}
