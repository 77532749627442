// Product grid item variables
$product-grid-item__border-width                        : 1px;
$product-grid-item__name-font-size                      : $font-size-small + 1;
$product-grid-item__image-wrapper-padding               : 5%;
$product-grid-item__details-padding                     : 5%;
$product-grid-item__details-padding\@xl                 : 5%;
$product-grid-item__details-margin                      : 0;
$product-grid-item__name-text-decoration-hover          : underline;
$product-grid-item__image-height--lazyload              : 100%;
$product-grid-item__tocompare-color                     : $black;
$product-grid-item__tocompare-color--hover              : $white;
$product-grid-item__tocompare-background-color--hover   : transparent;