.breadcrumbs {
    &__list {
        background-color: $breadcrumbs__background-color;
    }

    &__item {
        &:after {
            content: '>';
        }
    }
}