.toolbar {
    &__mode {
        order: 0;

        @include mq($max-screen: $screen-m - 1) {
            display: none;
        }
    }

    &__amount {
        order: 1;
        justify-content: flex-start;

        @include mq($screen-m) {
            justify-content: flex-start;
        }

        @include mq($max-screen: $screen-m - 1) {
            font-size: $font-size-small;
            display: flex;
        }
    }

    &__sorter {
        order: 2;
        border-top: 0;
        padding: 0;
        margin: 0;
        width: auto;

        @include mq($screen-m) {
            width: auto;
        }

        @include mq($max-screen: $screen-m - 1) {
            label {
                font-size: $font-size-small;
            }

            .select2 {
                font-size: $font-size-small;
                min-width: 100px;
            }
        }
    }

    &__limiter {
        order: 3;
        display: none;
    }

    &__sorter-link {
        margin-left: $toolbar__sorter-link-margin--left;
    }

    ~.toolbar {
        @include mq($screen-m) {
            justify-content: space-between;
        }

        .toolbar {
            &__pager {
                width: auto;
                margin: 0;

                @include mq($max-screen: $screen-m - 1) {
                    width: 100%;

                    .pager__list {
                        justify-content: flex-start;
                    }
                }
            }

            &__limiter {
                display: flex;
                margin-left: auto;

                @include mq($max-screen: $screen-m - 1) {
                    display: none;
                }
            }
        }
    }
}

.select2-container .select2-results__option {
    font-size: $font-size-small;
}
