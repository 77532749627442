// Catalog Grid
$catalog-grid__gap                          : 0;
$catalog-grid__margin                       : 0;
$catalog-grid__padding                      : $spacer--medium 0 $spacer--large 0;
$catalog-grid__template-columns\@small      : repeat(2, 1fr) ;
$catalog-grid__template-columns\@medium     : repeat(3, 1fr) ;
$catalog-grid__template-columns\@large      : repeat(4, 1fr) ;
$catalog-grid__template-columns\@extraLarge : repeat(6, 1fr) ;
$grid-screen-lg                             : 1200px;
$grid-screen-xl                             : 1440px;
$grid-screen-xxl                            : 1440px;
