.modals-wrapper {
    .modal-popup.modal-slide._inner-scroll {
        .modal-inner-wrap {
            width: $request-quote-modal__inner-wrap-width;
            border-radius: 5px;
            transform: translate3d(0, 0, 0);

            @media all and (min-width: $screen-m) and (max-width: ($screen-l + 36)) {
                width: $request-quote-modal__inner-wrap-width\@medium;
            }

            @include mq($max-screen: ($screen-m - 1)) {
                min-height: 0;
                margin: 15px auto;
                width: $request-quote-modal__inner-wrap-width\@small;
            }

            .modal-header {
                background-color: $request-quote-modal__header-footer-bg-color;
                box-shadow: $request-quote-modal__header-footer-box-shadow;

                button.button--rotate-icon.modal__close-button {
                    transition: $request-quote-modal__header-remove-transition;

                    &::before {
                        transition: $request-quote-modal__header-remove-transition;
                    }

                    &:hover {
                        .button__icon.modal__close-button-icon {
                            fill: $gray-darkest;
                        }
                    }

                    .button__icon.modal__close-button-icon {
                        fill: $gray-dark;
                        transition: $request-quote-modal__header-remove-transition;
                    }
                }
            }

            .modal-content {
                padding: $spacer--semi-medium + 2;

                .solar-mod {
                    .solar-head {
                        h3 {
                            font-size: $request-quote-modal__heading-font-size;
                            font-weight: $request-quote-modal__heading-font-weight;
                            line-height: $request-quote-modal__heading-line-height;
                            margin-top: $spacer + 2;

                            @media all and (max-width: ($screen-s - 1)) {
                                font-size: $request-quote-modal__heading-font-size--small;
                            }
                        }
                    }

                    .solar-bottom {
                        ul {
                            list-style: none;
                            margin: $request-quote-modal__buttons-margin;
                            display: flex;
                            justify-content: center;
                            padding: 0;

                            @include mq($max-screen: ($screen-m - 1)) {
                                flex-direction: column;
                            }

                            li {
                                margin: $request-quote-modal__button-margin;
                                padding: $request-quote-modal__button-padding;
                                font-size: $request-quote-modal__button-font-size;
                                background-color: $request-quote-modal__button-background-color;
                                border-radius: $request-quote-modal__button-border-radius;

                                @include mq($max-screen: ($screen-m - 1)) {
                                    text-align: center;
                                    margin-bottom: $spacer + 2;
                                }

                                a {
                                    text-decoration: none;
                                    color: $request-quote-modal__button-text-color;

                                    &:hover {
                                        color: $request-quote-modal__button-text-color;
                                    }
                                }
                            }
                        }
                    }

                    .solar-text {
                        display: flex;
                        flex-direction: row;
                        justify-content: center;
                        flex-wrap: wrap;

                        p {
                            font-size: $request-quote-modal__contact-text-font-size;
                            font-weight: $request-quote-modal__contact-text-font-weight;
                            color: $request-quote-modal__contact-text-color;
                            text-decoration: $request-quote-modal__contact-text-decoration;
                            margin: $request-quote-modal__contact-text-margin;
                        }
                    }
                }
            }

            .modal-footer {
                background-color: $request-quote-modal__header-footer-bg-color;
                box-shadow: $request-quote-modal__header-footer-box-shadow;

                @include mq($max-screen: ($screen-m - 1)) {
                    text-align: right;
                }

                button {
                    background-color: $request-quote-modal__footer-button-background-color;
                    color: $request-quote-modal__footer-button-color;
                    transition: $request-quote-modal__footer-remove-transition;

                    &::before,
                    &::after,
                    span {
                        transition: $request-quote-modal__footer-remove-transition;
                    }

                    &:hover {
                        background-color: $request-quote-modal__footer-button-background-color--hover;
                    }
                }
            }
        }
    }
}
