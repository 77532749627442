.dropdown-list {
    &--with-breakpoint {
        ul.dropdown-list__list {
            @media all and (max-width: ($screen-m - 1)) {
                border: none;
            }
        }
    }

    &__content {
        &[aria-hidden="true"] {
            .list.list__columns {
                @media all and (min-width: $screen-s) and (max-width: ($screen-m - 1)) {
                    display: flex;
                    flex-direction: row;
                }
            }

            .list:not(.list__columns) {
                @media all and (max-width: ($screen-m - 1)) {
                    width: 100%;
                }
            }
        }
    }
}