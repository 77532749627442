.pager {
    &__item {
        margin: 0;
        background-color: $gray-light;

        &:not(:last-child) {
            margin-right: 1px;
        }

        &:not(.pager__item-arrow) {
            padding: $pager__item-padding;
        }

        &:last-child {
            margin: 0;
        }
    }

    &__link {
        height: $pager__item-size;
    }
}
