.modals-wrapper {
    .modal-popup.confirm._show {
        .modal-inner-wrap {
            * {
                transition-duration: 0s;
            }

            width: $confirm-show-modal__inner-wrap-width;
            max-width: $confirm-show-modal__inner-wrap-max-width;

            .modal-header {
                padding-bottom: $confirm-show-modal__header-padding--bottom;
                padding-top: $confirm-show-modal__header-padding--top;
                box-shadow: $confirm-show-modal__header-box-shadow;
                background: $confirm-show-modal__header-background;
            }

            .modal-content {
                padding: $confirm-show-modal__content-padding;
            }

            .modal-footer {
                padding: $confirm-show-modal__footer-padding;

                button {
                    background: $confirm-show-modal__footer-button-background;
                    color: $confirm-show-modal__footer-button-color;
                    border: none;
                    padding: $confirm-show-modal__footer-button-padding;

                    &:hover {
                        background: $confirm-show-modal__footer-button-background--hover;
                    }
                }
            }
        }
    }
}
