.catalog-grid {
    display: grid;
    grid-template-columns: $catalog-grid__template-columns\@small;
    grid-gap: $catalog-grid__gap;
    margin: $catalog-grid__margin;
    padding: $catalog-grid__padding;
    list-style: none;

    @include mq($screen-m) {
        grid-template-columns: $catalog-grid__template-columns\@medium;
    }
    @include mq($grid-screen-lg, $grid-screen-xl - 1) {
        grid-template-columns: $catalog-grid__template-columns\@large;
    }
    @include mq($grid-screen-xxl) {
        grid-template-columns: $catalog-grid__template-columns\@extraLarge;
    }

    @include isIE() {
        display: flex;
        flex-wrap: wrap;
    }
}