/* Common styles for categories and custom pages of SolarElectric */

/*Shail common classes*/

.p-0 {
    padding: 0;
}

.p-1 {
    padding: $spacer + 2;
}

.p-2 {
    padding: $spacer--medium + 4;
}

.p-3 {
    padding: $spacer--large - 2;
}

.pl-0 {
    padding-left: 0px;
}

.pl-1 {
    padding-left: $spacer + 2;
}

.pl-2 {
    padding-left: $spacer--medium + 4;
}

.pl-3 {
    padding-left: $spacer--large - 2;
}

.pr-1 {
    padding-right: $spacer + 2;
}

.pr-2 {
    padding-right: $spacer--medium + 4;
}

.pr-3 {
    padding-right: $spacer--large - 2;
}

.pt-0 {
    padding-top: 0px;
}

.pt-2 {
    padding-top: $spacer--medium + 4;
}

.pt-3 {
    padding-top: $spacer--semi-medium + 6;
}

.pb-1 {
    padding-bottom: $spacer + 2;
}

.pb-2 {
    padding-bottom: $spacer--medium + 4;
}

.px-1 {
    padding-left: $spacer + 2;
    padding-right: $spacer + 2;
}

.px-2 {
    padding-left: $spacer--small + 3;
    padding-right: $spacer--small + 3;
}

.px-4 {
    padding-left: $spacer--semi-large;
    padding-right: $spacer--semi-large;
}

.py-1 {
    padding-top: $spacer + 2;
    padding-bottom: $spacer + 2;
}

.py-2 {
    padding-top: $spacer--medium + 4;
    padding-bottom: $spacer--medium + 4;

    img {
        padding: 0;
    }
}

.m-0 {
    margin: 0;
}

.m-1 {
    margin: $spacer + 2;
}

.m-2 {
    margin: $spacer--medium + 4;
}

.mt-1 {
    margin-top: $spacer + 2;
}

.mt-2 {
    margin-top: $spacer--medium + 4;
}

.mt-3 {
    margin-top: $spacer--large - 2;
}

.mt-5 {
    margin-top: $spacer--extra-large + 2;
}

.mb-0 {
    margin-bottom: 0;
}

.mb-1 {
    margin-bottom: $spacer + 2;
}

.mb-2 {
    margin-bottom: $spacer--medium + 4;
}

.mb-3 {
    margin-bottom: $spacer--large - 2;
}

.mb-4 {
    margin-bottom: $spacer--semi-large;
}

.ml-0 {
    margin-left: 0;
}

.ml-3 {
    margin-left: $spacer--large - 2;
}

.mx-2 {
    margin-left: $spacer--medium + 4;
    margin-right: $spacer--medium + 4;
}

.mx-4 {
    margin-left: $spacer--semi-large;
    margin-right: $spacer--semi-large;
}

.mx-auto {
    margin-left: auto;
    margin-right: auto;
}


.my-2 {
    margin-top: $spacer--medium + 4;
    margin-bottom: $spacer--medium + 4;
}

.mx-2 {
    margin-left: $spacer--medium + 4;
    margin-right: $spacer--medium + 4;
}

.mx-1 {
    margin-left: $spacer + 2;
    margin-right: $spacer + 2;
}

.mx-0 {
    margin-left: 0;
    margin-right: 0;
}

.flex {
    display: flex;
}

.center {
    justify-content: center;
}

.f-right {
    float: right;
}

.f-left {
    float: left;
}

strong {
    font-weight: $font-weight-bold;
}

.hide-page-title {
    display: none;
}

.shadow {
    background: #c9c5c517;
    box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.1);
}

.border {
    border-right: $border-width-base $border-style-base $dark-orange;
}

hr {
    border-top: 1px solid #d1d1d1 !important;
}

.bot_text-cont-org-wide {
    a {
        font-weight: $font-weight-bold;
        color: $black;

        &:hover {
            color: $black;
        }
    }
}

.dark-black-links {
    color: $black;
    text-decoration: none;
    font-weight: $font-weight-bold;

    &:hover {
        text-decoration: underline;
        color: $black;
    }
}

.faint-black-links {
    color: $black;
    text-decoration: none;
    font-weight: $font-weight-base;

    &:hover {
        text-decoration: underline;
        color: $black;
    }
}

.dark-orange-links {
    color: $dark-orange;
    text-decoration: none;
    font-weight: $font-weight-bold;

    &:hover {
        text-decoration: underline;
        color: $dark-orange;
    }
}

.faint-orange-links {
    color: $dark-orange;
    text-decoration: none;
    font-weight: $font-weight-base;

    &:hover {
        text-decoration: underline;
        color: $dark-orange;
    }
}

/* Example use - Residential Solar Systems - Sloped Roof Mounted */
.site-title-sidebyside-image {
    picture {
        @media all and (max-width: ($screen-l - 1)) {
            display: none;
        }

        img {
            margin-left: auto;
        }
    }
}

/* Example use - Residential Solar Systems - Ground Mounted Page */
h1.large-category-title {
    font-size: $font-size-largest;
    font-weight: $font-weight-base;
    margin-bottom: $spacer--medium + 4;

    @media all and (max-width: ($screen-m - 1)) {
        font-size: $font-size-extra-large + 4;
    }
}

h1.home-title-header-x {
    font-weight: $font-weight-bolder;
    font-size: $font-size-extra-large;
    line-height: 1;
    color: $redish-brown;
}

h2.home-title-header-x {
    font-weight: $font-weight-base;
    font-size: $font-size-super-extra-large - 2;
    margin-bottom: $spacer + 2;

    @media all and (max-width: ($screen-m - 1)) {
        font-size: $font-size-extra-large + 4;
    }
}

h2.dark-text {
    font-size: $font-size-medium + 4;
    font-weight: $font-weight-semi-medium;
    margin-bottom: $spacer--semi-medium;

    @media all and (max-width: ($screen-m - 1)) {
        font-size: $font-size-medium + 4;
    }
}

h3.home-sub-header-x {
    font-size: $font-size-medium;
    font-weight: $font-weight-bold;
    color: $darkest-blue;
    margin-bottom: $spacer--medium - 1;
}

h3.dark-text {
    font-size: $font-size-medium;
    font-weight: $font-weight-bold;
    color: $charcoal-black;
    margin-bottom: $spacer--medium - 1;
}

h3.home-title-header-x {
    font-size: $font-size-medium;
    font-weight: $font-weight-bold;
    color: $black;
    margin-bottom: $spacer--medium - 1;
}

h3.large-h3 {
    font-size: $font-size-extra-large;
    font-weight: $font-weight-base;
    line-height: 1.1;

    @media all and (max-width: ($screen-s - 1)) {
        font-size: $font-size-extra-large - 4;
    }
}

h4.dark-text {
    font-size: $font-size-base;
    font-weight: $font-weight-bold;
    color: $charcoal-black;
}

h5 {
    font-size: $font-size-medium;
    margin-bottom: $spacer + 2;
}

h5.dark-text {
    font-size: $font-size-base;
    font-weight: $font-weight-bold;
}

p.dark-text {
    font-weight: $font-weight-bold;
}

ul {
    list-style: circle;
    padding-left: $spacer--large - 2;
    margin-bottom: $spacer--semi-medium + 1;
    font-size: $font-size-small;

    li {
        margin-bottom: $spacer + 2;
        font-size: $font-size-small;
    }

    /* Example use - Residential Solar Systems - Ground Mounted Page */
    &.with-check-style {
        list-style-type: '\2713';

        li {
            padding-left: $spacer + 2;
        }
    }
}

.text-center {
    text-align: center;

    picture,
    img {
        margin-left: auto;
        margin-right: auto;
    }
}

.header-cont {
    margin: 15px 0 0;
    padding: 15px 0;

    h3 {
        font-size: $font-size-extra-large;

        strong {
            font-weight: $font-weight-bold;
        }
    }
}

img.drop-shadow-image {
    margin-bottom: $spacer--extra-small;
    margin-top: $spacer--extra-small + 2;
    border-radius: 12px;
    box-shadow: 3px 3px 3px #b1b1b2;
}

/* Example use - Residential Solar Systems - View By Mount Type Page */
p.home-text {
    color: $dark-blue;
    text-decoration: none;
    font-family: Verdana, Century Gothic, Avant Garde, Arial, sans-serif;
    text-shadow: 1px 1px 1px #d4d4d4;
    font-size: $font-size-medium + 1;
    font-weight: $font-weight-bold;

    a {
        color: $dark-blue;
        text-decoration: none;
        font-family: Verdana, Century Gothic, Avant Garde, Arial, sans-serif;
        text-shadow: 1px 1px 1px #d4d4d4;
        font-size: $font-size-medium + 1;
        font-weight: $font-weight-bold;
    }
}

/* Example use - Residential Solar Systems Page */
.cell_gray_grad {
    background-color: #f3f3f3;
    padding: $spacer + 2;
    border-radius: 10px;

    h3 {
        font-size: $font-size-medium;
        font-weight: $font-weight-bold;
        margin-bottom: $spacer--medium - 1;
    }

    h3.bottom-right-box-h3 {
        margin-right: auto;
        margin-left: auto;
    }

    p.top-box-p {
        color: $stone-gray;
        margin-bottom: $spacer + 2;
    }

    ul {
        list-style: circle;
        margin-bottom: $spacer--large - 2;
        margin: 0 0 15px 0;

        li {
            font-size: $font-size-small;
            margin-bottom: $spacer + 2;
        }
    }

    &.with-only-links {
        >p {
            >a {
                color: $dark-orange;
                text-decoration: none;
                font-weight: $font-weight-bold;

                &:hover {
                    text-decoration: underline;
                }
            }
        }
    }

    /* Example use - Residential Solar Systems - Sloped Roof Mounted */
    &.with-orange-ul-links {
        ul {
            a {
                color: $dark-orange;
                text-decoration: none;
                font-weight: $font-weight-bold;

                &:hover {
                    text-decoration: underline;
                }
            }
        }
    }

    /* Example use - Residential Solar Systems - Sloped Roof Mounted */
    &.with-three-columns-of-info {
        .featured-cat-box {
            text-align: center;

            picture {
                img {
                    margin-right: auto;
                    margin-left: auto;
                }
            }

            ol {
                padding: 0;
            }

            .row.cell_gray_grad {
                justify-content: center;

                a {
                    color: $dark-orange;
                    text-decoration: none;

                    &:hover {
                        text-decoration: underline;
                    }
                }
            }
        }
    }
}

.dimensions-column-center-title {
    h3 {
        font-size: $font-size-medium;
        font-weight: $font-weight-bold;
        margin-bottom: $spacer--medium - 1;
    }
}

.table-responsive {
    overflow-x: auto;
    min-height: .01%;
    font-size: $font-size-small;

    @media all and (max-width: ($screen-m - 1)) {
        margin-bottom: $spacer--medium - 1;
    }

    .solar-table {
        margin-bottom: $spacer--large;
        width: 100%;
        border-collapse: collapse;
        border-spacing: 0;
        max-width: 100%;

        a {
            color: $darker-blue;
            text-decoration: underline;
            font-family: inherit;
            font-size: $font-size-small;
            font-weight: $font-weight-bold;
        }

        td,
        th {
            padding: $spacer 10px;
            vertical-align: top;
            border: $border-width-base $border-style-base $gray-light;
        }
    }
}

/* Example use - Residential Solar Systems - View By Mount Type Page */
.side-box {
    padding: 12px;
    border: $border-width-base $border-style-base $light-golden;
    background-color: #f4f4f4;
    text-align: center;

    p.h3-shadow {
        margin: 1px 1px 1px 1px;
        color: $darkest-blue;
        font-size: $font-size-medium;
        font-weight: $font-weight-base;
        text-shadow: 1px 1px 1px #b1b1b2;
    }

    p.parag {
        font-size: $font-size-small;
        font-weight: $font-weight-bold;
        margin: 10px 0px;
    }
}

/* Example use - Solar components - solar panel mounts - Ironridge roof mount */
.featured-cat-box.with-ol {
    text-align: center;

    ol {
        list-style-type: none;
        margin-bottom: $spacer--semi-medium + 1;

        li {
            font-size: $font-size-small;
            margin-bottom: $spacer + 2;
        }
    }
}

.sloped-roof-bottom {
    padding: $spacer--medium;
    width: 100%;
    background-image: url(/media/product-images/commercial_solar_systems/commercial-solar-system-prices-back.png);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}

.pdf-box {
    border: $border-width-base $border-style-base $dark-orange;
    width: max-content;

    a {
        text-decoration: none;

        &:hover {
            text-decoration: underline;
        }
    }
}

.pdf-box h3 {
    background-color: $dark-orange;
    padding: $spacer + 2;
    color: $white;
    margin: 0;
    margin-bottom: $spacer + 2;
}

.pdf-box p {
    padding: 0 10px;
}

/* Example use - Residential Solar Systems Page */
ul.li-mn-bx {
    list-style: none;
    max-width: 700px;
    margin: auto;
    margin-top: $spacer--large;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 5px;

    @media all and (max-width: ($screen-m - 1)) {
        grid-template-columns: repeat(3, 1fr);
        padding: 0;
    }

    li {
        text-align: center;
        line-height: 32px;
        background: $gray-light;

        a.system-price {
            color: $dark-blue;
            text-decoration: underline;
            font-family: Segoe, "Segoe UI", "DejaVu Sans", "Trebuchet MS", Verdana, Arial, sans-serif;
            font-size: $font-size-small;
            font-weight: $font-weight-bold;
            margin-bottom: 10px;
        }
    }
}

/* Example use - Residential Solar Systems - View By Mount Type Page */
.grid-image-followed-link {
    .featured-cat-box {
        @media all and (max-width: ($screen-m - 1)) {
            display: flex;
            flex-direction: column;

            >a {
                margin-left: auto;
                margin-right: auto;
            }
        }

        .featured-desc {
            h3 {
                font-size: $font-size-small + 1;
                text-decoration: none;
                margin: 15px 0 15px 0;

                a {
                    color: $black;
                    font-weight: $font-weight-bold;
                }
            }

            ul {
                margin: 0;
                padding: 0 0 0 1.5rem;
                list-style: circle;

                li {
                    a {
                        color: $dark-blue;
                        text-decoration: underline;
                        font-size: $font-size-small;
                        font-weight: $font-weight-bold;
                        font-family: Segoe, "Segoe UI", "DejaVu Sans", "Trebuchet MS", Verdana, Arial, sans-serif;
                    }
                }
            }
        }
    }
}

/* Example use - Industrial Off-Grid Solar Systems - Large Battery Enclosures */
.comm-list1 {
    padding: $spacer--medium + 2;
    border: 1px solid #f4f4f4;
    margin-top: $spacer--medium + 2;

    ul {
        list-style: square;
    }
}

/* Example use - Residential Solar Systems - View By Mount Type Page */
.comm-projects {
    background-color: $extra-faint-blue;
    padding: $spacer--medium - 1;
    margin: 15px 0 15px 0;

    h3.home-sub-header-x {
        font-weight: $font-weight-semi-medium;
        font-size: $font-size-medium;
        line-height: 18px;
        color: $darkest-blue;
    }

    .projects-list {
        display: flex;
        flex-direction: row;
        list-style: none;
        padding: 0;
        margin-bottom: 0;

        @media all and (min-width: 540px) and (max-width: ($screen-m - 1)) {
            display: grid;
            grid-template-columns: 1fr 1fr 1fr;
        }

        @media all and (min-width: $screen-s) and (max-width: 539px) {
            display: grid;
            grid-template-columns: 1fr 1fr;
        }

        @media all and (max-width: ($screen-s - 1)) {
            flex-direction: column;
            ;
        }

        li {
            padding: 10px 0;

            a {
                margin-right: $spacer--semi-medium;
                display: flex;
                flex-direction: column;
                color: $dark-blue;
                text-decoration: underline;
                font-size: $font-size-small;
                font-weight: $font-weight-bold;

                &:hover {
                    color: $black-pearl;
                }
            }
        }
    }

    &.without-images {
        h3.home-sub-header-x {
            font-weight: $font-weight-bold;
        }

        .projects-list {
            li {
                color: $black;

                &:hover {
                    color: $black;
                }
            }
        }
    }
}

.solar-list {
    padding: $spacer--medium + 2;
    border: $border-width-base $border-style-base $gray-light;
    margin-top: $spacer--medium + 2;
    width: 100%;

    ul {
        padding-left: $spacer--medium + 4;

        li {
            font-family: Verdana, Arial, sans-serif;
            font-size: $font-size-small;
            margin-bottom: $spacer--extra-small + 1;
            list-style: square;
        }
    }
}

.solar-list-100 {
    padding: $spacer--medium + 2;
    padding-left: $spacer--large - 2;
    border: $border-width-base $border-style-base $gray-light;
    margin-top: $spacer--medium + 2;
}

/* Example use - Residential Solar Systems - Sloped Roof Mounted */
.manufacturers-column {
    .css-last-one {
        padding: 20px $spacer--medium $spacer--medium $spacer--medium;
        background-color: #F0F0F0;
        background-position: center center;
        background-repeat: no-repeat;
        background-image: url(/media/product-images/residential_solar_systems/back-sloped-roof-mount-mtn.png);
        min-height: 94px;
        background-size: cover;
        text-align: center;
        margin: 15px 0;
        border-radius: 4px;
    }
}

.bottom-container {
    margin: 0 10px;

    .image-container {
        a {
            display: flex;
            flex-direction: column;
            align-items: center;
        }
    }
}

/* Example use - Residential Solar Systems - Sloped Roof Mounted */
.todays-special-and-popular-systems-block {
    .todays-special {
        p {
            margin-bottom: $spacer--small + 3;

            a {
                font-weight: $font-weight-bold;
                text-decoration: none;

                &:hover {
                    text-decoration: underline;
                }
            }
        }
    }

    h3 {
        font-size: $font-size-medium;
        font-weight: $font-weight-bold;
    }

    .popular-systems-with-images {
        @media all and (max-width: 539px) {
            flex-direction: column;
            align-items: center;
        }

        a {
            display: flex;
            flex-direction: column;

            &:hover {
                color: $black;
            }
        }
    }
}

.grid-image-followed-para {
    p {
        font-size: $font-size-base;
        margin-bottom: $spacer + 2;

        a {
            color: $black;
            font-weight: $font-weight-bold;
            text-decoration: none;

            &:hover {
                text-decoration: underline;
            }
        }
    }

    img {
        padding-top: $spacer--medium + 4;
        padding-bottom: $spacer--medium + 4;
    }
}

.sloped-roof-gallery {
    div {
        display: inline;

        @media all and (max-width: ($screen-m - 1)) {
            display: block;
        }

        img {
            margin-right: $spacer--small;
        }
    }
}

.comm-bottom-head {
    background-color: #f4f4f4;
    padding: 15px;
    margin: 15px 0;

    h3 {
        color: $dark-blue;
        text-decoration: none;
        font-family: Verdana, Century Gothic, Avant Garde, Arial, sans-serif;
        font-size: $font-size-base;
        text-shadow: 1px 1px 1px #d4d4d4;
        margin-bottom: $spacer + 2;
    }

    p {
        font-size: $font-size-base;
        margin-bottom: $spacer + 2;
    }
}

.css-last {
    padding: 20px $spacer--medium $spacer--medium $spacer--medium;
    background-color: $extra-faint-blue;
    margin: 15px 0;

    .twelve-px-verdana {
        a {
            color: $black;

            &:hover {
                color: $black;
            }
        }
    }
}

.off-grid-bottom-contact-us {
    margin-top: $spacer--semi-medium;
    background-image: url(/media/product-images/remote-industrial-solar/remote-outdoor-solar-systems/remote-off-grid-solar-power-ground-2.png);
    background-repeat: no-repeat;
    background-position: left bottom;
    background-color: #F0F0F0;

    .off-grid-link {
        display: flex;
        flex-direction: column;
    }
}

.box-clr-ly {
    padding: $spacer--medium - 2;
    border-radius: 14px;
    border: thin $border-style-base #CFCFCF;
    background-color: #f9f9f9;
    font-size: $font-size-base + 1;

    &.row {
        margin-left: 0;
        margin-right: 0;
    }

    img {
        margin: 10px 0px;
    }

    strong,
    span {
        line-height: 30px;
    }
}

.solar-table.table-box {
    margin-bottom: $spacer--large + 3;

    >tbody {
        >tr {
            >td {
                padding: $spacer $spacer + 2;
            }
        }
    }

    td,
    th {
        border: 1px solid #ddd;
    }

    a {
        color: $darker-blue;
        text-decoration: underline;
        font-size: $font-size-small;
        font-weight: $font-weight-bold;

        &:hover {
            color: $darker-blue;
        }
    }
}
