#algolia-autocomplete-container {
    .aa-dropdown-menu {
        .autocomplete-wrapper {
            .aa-dataset-products {
                .algoliasearch-autocomplete-hit {
                    padding: 0;
                }
            }

            .aa-cursor {
                background-color: rgba(0,0,0,0.04);
                .algoliasearch-autocomplete-hit {
                    cursor: pointer;
                    background-color: initial;
                }
            }
        }
    }
    .autocomplete-wrapper {
        .aa-dataset-products {
            .aa-suggestions {
                display: grid !important;
                grid-template-columns: $algolia-search__suggestions-template-columns;
                .aa-suggestion {
                    width: 100%;
                    padding: $algolia-search__suggestion-padding;
                }
            }

            #autocomplete-products-footer {
                position: relative;
                width: 100%;
                left: unset;

                span {
                    a {
                        color: $algolia-search__products-footer-link-color;
                    }
                }
            }
        }
    }
}