$message__padding                               : $spacer--medium - 1 $spacer--medium + 4;
$message__font-size                             : $font-size-small + 1;
$message__font-weight                           : $font-weight-bold;
$message__background--success                   : #e5efe5;
$message__background--error                     : #efe5e5;
$message__color--success                        : #006400;
$message__color--error                          : #640000;
$message__border-width                          : none;
$message__border-color                          : none;
$message__border-style                          : none;
$message__border-color--success                 : none;
$message__border-color--error                   : none;
$message__border-radius                         : 0;
$message__icon                                  : '\f129';
$message__icon--success                         : '\f00c';
$message__icon--eror                            : '\f00d';
$message__success-comparison-link-color         : #aaa;
$message__success-comparison-link-color--hover  : #5bd2ec;