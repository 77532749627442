// Footer
$footer__background-color                                   : $dark-orange;
$footer__border-width                                       : 0;

// Footer handler
$footer__handler-padding                                    : 16px 0 0 0;
$footer__handler-padding\@medium                            : 16px 0 0 0;
$footer__handler-padding\@large                             : 16px 0 0 0;

// Footer links
$footer__links-dropdown-list-item-width-first-child\@medium : 100%;
$footer__links-padding                                      : 0;
$footer__links-padding\@large                               : 0;
$footer__links-list-padding                                 : 0;
$footer__links-list-margin                                  : 0;
$footer__links-list-item-margin                             : 10px 0;
$footer__links-list-item-padding                            : 0 10px;
$footer__links-color                                        : $white;
$footer__links-color--hover                                 : $black;

// Footer bottom bar
$footer__bottom-bar-background: $dark-orange;

//Footer Scroll-top
$footer__scroll-top-display                                : block;
$footer__scroll-top-position                               : fixed;
$footer__scroll-top-bottom                                 : 60px;
$footer__scroll-top-right                                  : 30px;
$footer__scroll-top-icon-fill                              : $black;
$footer__scroll-top-bg                                     : $gray-lightest;
$footer__scroll-top-bg-hover                               : $white;

// Footer copyright
$footer__copywrite-font-size                               : $font-size-small;