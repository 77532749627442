.product-list-item {
    &:hover {
        margin-left: -1%;
        margin-right: 1%;
        padding-left: 1%;
        padding-right: 1%;
        z-index: 2;
        box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
        border-color: transparent;
        transition: box-shadow 400ms ease-out;
        -moz-transition: box-shadow 400ms ease-out;
        -webkit-transition: box-shadow 400ms ease-out;
        -o-transition: box-shadow 400ms ease-out;

        .product-list-item__image-link {
            .lazyload-wrapper {
                &::after {
                    background-color: transparent;
                }
            }
        }
    }

    &__main {
        @media all and (max-width: ($screen-l - 1)) {
            flex-direction: column;
        }
    }

    &__actions {
        max-width: 250px;
    }

    &__actions-inner {
        flex-direction: column;
        gap: 5px;
        .button.btn-cart {
            max-width: 140px;
        }

        .button.button--tocompare {
            max-width: 40px;
            max-height: 40px;
            background-color: #F5F5F5;
            color: $black;
            transform: rotateX(180deg);

            &::before {
                background-color: transparent;
            }

            &:hover {
                background-color: transparent;
                &::before {
                    background-color: transparent;
                }
            }

            @media all and (max-width: ($screen-m - 1)) {
                display: none;
            }
        }

        @media all and (max-width: ($screen-l - 1)) {
            flex-direction: row;
            justify-content: flex-start;
        }
    }
    
    &__image-link {
        .product-image-photo {
            height: 100%;
        }

        @include mq($max-screen: ($screen-s - 1)) {
            width: $product-list-item__image-width\@small;
        }
    }
}
